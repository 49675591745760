// Copyright: © 2018 by dots Gesellschaft für Softwareentwicklung mbH, Germany. All rights reserved.
'use strict';

var _slicedToArray = require("D:\\JenkinsWorkspaces\\Flux Installer 9.0.2\\_build\\node_modules\\babel-preset-react-app\\node_modules\\@babel\\runtime/helpers/slicedToArray");

var kathaDebug = false;

if (typeof require === 'undefined') {
  // HEADER to make require work by using Components.utils.import internally - DON'T TOUCH THIS, PLEASE
  // eslint-disable-next-line no-undef
  Components.utils.import('resource://requireloc/dotsRequireModule.js');
  var module = {
    exports: Object.create(null)
  };
  Object.defineProperty(this, 'exports', {
    get: function get() {
      return module.exports;
    }
  });
  var EXPORTED_SYMBOLS = ['module']; // END OF HEADER
}

var trimRegex = /\[\#([^\]]+)\]/;
var numberPattersRegexWithGivenNumber = /\((\d+)\)/;
var numberPattersRegex = /\(#\)/;
var translationMap = {
  'bg': 'preferences.languageBulgarian',
  'cs': 'preferences.languageCzech',
  'da': 'preferences.languageDanish',
  'de': 'preferences.languageGerman',
  'el': 'preferences.languageGreek',
  'en-GB': 'preferences.languageEnglishGB',
  'en-US': 'preferences.languageEnglish',
  'es-ES': 'preferences.languageSpanish',
  'fr': 'preferences.languageFrench',
  'fr-CA': 'preferences.languageFrenchCanada',
  'it': 'preferences.languageItalian',
  'hu': 'preferences.languageHungarian',
  'nl': 'preferences.languageDutch',
  'nb-NO': 'preferences.languageNorwegian',
  'pl': 'preferences.languagePolish',
  'pt-PT': 'preferences.languagePortuguese',
  'ro': 'preferences.languageRomanian',
  'ru': 'preferences.languageRussian',
  'fi': 'preferences.languageFinnish',
  'sv-SE': 'preferences.languageSwedish',
  'tr': 'preferences.languageTurkish',
  'ja': 'preferences.languageJapanese',
  'ko': 'preferences.languageKorean',
  'zh-TW': 'preferences.languageChineseTraditional',
  'zh-CN': 'preferences.languageChineseSimplified',
  'th-TH': 'preferences.languageThai',
  'vi-VN': 'preferences.languageVietnamese'
};

var TranslationFunctions = function TranslationFunctions(moment) {
  var localeStrings = {};
  var currentLocale = null;
  var shortDateFormat = null;
  var longDateFormat = null; // Must not use ES6.
  // Must not use e.g. "this.translate = function (...) {...}",
  // internal usage of this.translate would be undefined because
  // react-modules bind all TranslationFunctions.function to null, to get a new functions each time in the i18n state.

  function setLocaleAndStrings(locale, newLocaleStrings) {
    if (locale) {
      currentLocale = locale;
      moment.locale(locale);
    }

    localeStrings = newLocaleStrings || {};
  }

  function setDateFormats(dateFormat, timeFormat) {
    if (dateFormat && !timeFormat) {
      shortDateFormat = dateFormat;
      longDateFormat = dateFormat + ' ' + moment().localeData().longDateFormat('LT');
    } else if (!dateFormat && timeFormat) {
      longDateFormat = moment().localeData().longDateFormat('L') + ' ' + timeFormat;
    } else if (dateFormat && timeFormat) {
      shortDateFormat = dateFormat;
      longDateFormat = dateFormat + ' ' + timeFormat;
    } else {
      shortDateFormat = null;
      longDateFormat = null; // Return empty object to server settings update
      // triggers change and update localized data on db

      return {};
    }

    if (shortDateFormat || longDateFormat) {
      return {
        shortDateFormat,
        longDateFormat
      };
    }

    return false;
  }

  this.setLocaleAndStrings = setLocaleAndStrings;
  this.setDateFormats = setDateFormats;

  this.getCurrentLocale = function () {
    return currentLocale;
  }; // ////////////////////////////////////////
  // default translation finctions
  // ////////////////////////////////////////


  function replaceAll(text, search, replace) {
    if (replace !== null && replace !== undefined) {
      var regex = new RegExp(search, 'g');
      return text.replace(regex, replace);
    }

    return text;
  }

  function translateString(stringId, values) {
    if (!stringId || !Object.prototype.hasOwnProperty.call(localeStrings, stringId)) return stringId;
    var translatedString = localeStrings[stringId];

    if (Array.isArray(values)) {
      // replace %[1-9]$S with a value, keeps correct order
      values.forEach(function (value, idx) {
        var toReplace = '\\%' + (idx + 1) + '\\$S';
        translatedString = replaceAll(translatedString, toReplace, value);
      });
    }

    return translatedString;
  }

  this.translateString = translateString; // function translate (toTranslate, ...rest) {

  function translate(toTranslate) {
    var result = toTranslate;
    var rest = [];
    rest.push.apply(rest, arguments) && rest.shift();

    if (!toTranslate) {
      if (typeof rest[0] === 'string') {
        // translate("", "stringIdB");
        result = translate.apply(null, rest);
      }
    } else if (Array.isArray(toTranslate)) {
      // translate(["stringIdA", "stringIdB"]);
      result = translate.apply(null, toTranslate);
    } else if (typeof rest[0] === 'string') {
      // translate("stringIdA", "stringIdB");
      result = translate(toTranslate) + translate.apply(null, rest);
    } else if (typeof toTranslate === 'string') {
      if (toTranslate.match(trimRegex)) {
        // For case like: "[#products.a4Color.name]"
        result = trimAndTranslateString(toTranslate);
      } else if (toTranslate.match(numberPattersRegex) && typeof rest[0] === 'number') {
        // For case like: "printer.tray.tray(#)"
        result = translateNumberPattern(toTranslate, rest[0]);
      } else if (toTranslate.match(numberPattersRegexWithGivenNumber)) {
        // For case like: "printer.tray.tray(3)"
        result = translateNumberPattern(toTranslate);
      } else {
        // translate(["stringIdA", ["replace %1$S with this", "replace %2$S with this"]]);
        result = translateString(toTranslate, rest[0]);
      }
    } // if (moment(toTranslate).isValid()) {
    // 	const format = rest[0];
    // 	return translateDate(toTranslate, format);
    // }


    if (kathaDebug) {
      if (localeStrings['global.ok'] !== undefined // check if localeStrings have been initialized
      && toTranslate && typeof toTranslate === 'string' && toTranslate.replace(/\s/g, '').length // string is only whitespaces
      && result == toTranslate) {
        if (typeof require === 'undefined') {
          loggy('translate: String Id is the same as the result of the translation! ->', toTranslate);
        } else {
          console.error('translate: String Id is the same as the result of the translation! ->', toTranslate);
        }
      }
    }

    return result;
  }

  this.translate = translate; // the workstation wants some dates localized in its locale and some in the servers locale

  function translateDate(date, format, differentLocale) {
    if (!date || !moment(date).isValid()) {
      return '[invalid date]';
    }

    if (typeof date === 'number') {
      var _date$toString = date.toString(),
          length = _date$toString.length; // The range is 1973 - 2286


      if (length >= 9 && length <= 10) {
        return differentLocale ? moment(date * 1000).locale(differentLocale).format(format) : moment(date * 1000).format(format);
      }

      if (length >= 12 && length <= 13) {
        return differentLocale ? moment(date).locale(differentLocale).format(format) : moment(date).format(format);
      }

      return '[invalid date]';
    }

    return differentLocale ? moment(date).locale(differentLocale).format(format) : moment(date).format(format);
  }

  function translateShortDate(date, customFormat, differentLocale) {
    return translateDate(date, customFormat || shortDateFormat || 'L', differentLocale);
  }

  function translateLongDate(date, customFormat, differentLocale) {
    return translateDate(date, customFormat || longDateFormat || 'L LT', differentLocale);
  }

  this.translateDate = translateLongDate;
  this.translateShortDate = translateShortDate; // ////////////////////////////////////////
  // special translation finctions
  // ////////////////////////////////////////
  // TODO get rid of this hacky function, use translateString with "%1$S" pattern in stringId

  function translateNumberPattern(stringId, number) {
    if (!stringId) return stringId;
    var localizedString = translateString(stringId);

    if (number === undefined) {
      // If the string is able to translate, then just return it.
      // For example: limitation.MINIMUM_OF_PAGES(1) "At least 1 page"
      if (localizedString !== stringId) return localizedString;

      var _stringId$match = stringId.match(numberPattersRegexWithGivenNumber),
          _stringId$match2 = _slicedToArray(_stringId$match, 2),
          match = _stringId$match2[0],
          numberFromStringId = _stringId$match2[1];

      number = numberFromStringId; // for other cases:
      // For example: limitation.MINIMUM_OF_PAGES(#) "At least # pages"

      localizedString = translateString(stringId.replace(match, '(#)'));
    } // Other cases:


    return localizedString.replace('#', number);
  }

  this.translateNumberPattern = translateNumberPattern; // Only translates stringId if the pattern [#...] is found

  function trimAndTranslateString(stringId) {
    if (!stringId) return stringId; // Product, service or option names can have the form [#id_for_translation] or might be clear text.
    // Trim and localize if pattern found.
    // Sometime with suffix like: "#[id_for_translate] *"
    // workStep email contents might be something like [#id1]\r\n[#id2] – [#id3]"

    var result = stringId;
    var curMatch = result.match(trimRegex);

    while (curMatch) {
      // tranlation of curMatch[1] might not exitst if user created own names with the pattern "[#customname]" in previous versions,
      // or some imported papercatalog comes with papertype names "[#papername]"
      result = result.replace(curMatch[0], translateString(curMatch[1]));
      curMatch = result.match(trimRegex);
    }

    return result;
  }

  this.trimAndTranslateString = trimAndTranslateString; // TODO this simple switch might not be sufficient for some locales

  function pluralForm(num, string) {
    var words = string.split(';');
    var singular = words.length > 0 ? words[0] : '';
    var plural = words.length > 1 ? words[1] : singular;
    return num === 1 ? singular : plural;
  }

  this.pluralForm = pluralForm; // ////////////////////////////////////////
  // other stuff
  // ////////////////////////////////////////
  // Utility function that take a fileSize (expressed in byte)
  // and returns a string representation indicating kb or mb measure unity

  function getStringRepresentationForFileSize(fileSize) {
    var sizeStr = '';
    var BYTE_LIMIT = 1048576; // amount of bytes after which to use MB as display unit

    if (fileSize < BYTE_LIMIT) {
      sizeStr = (fileSize / 1024).toFixed(0) + ' ' + translateString('unit.kb');
    } else {
      sizeStr = (fileSize / 1048576.0).toFixed(1) + ' ' + translateString('unit.mb');
    }

    return sizeStr;
  }

  this.getStringRepresentationForFileSize = getStringRepresentationForFileSize;

  function getLocaleList(localeFilter) {
    return Object.keys(translationMap).filter(function (code) {
      return localeFilter || localeFilter.indexOf(code) >= 0;
    }).map(function (code) {
      return {
        code: code,
        name: localeStrings[translationMap[code]]
      };
    });
  }

  this.getLocaleList = getLocaleList;

  function convertLocaleToMoment(locale) {
    switch (locale.toLowerCase()) {
      case 'pt-pt':
        return 'pt';

      case 'es-es':
        return 'es';

      case 'nb-no':
        return 'nb';

      case 'sv-se':
        return 'sv';

      case 'th-th':
        return 'th';

      case 'vi-vn':
        return 'vi';

      default:
        return locale.toLowerCase();
    }
  }

  ;
  this.convertLocaleToMoment = convertLocaleToMoment;

  function getCurrencySymbolByLocaleId(localeId) {
    switch (localeId) {
      case 'de':
      case 'el':
      case 'es-ES':
      case 'fi':
      case 'fr':
      case 'it':
      case 'nl':
      case 'pt-PT':
        return '€';

      case 'bg':
        return 'Лв';

      case 'en-GB':
        return '£';

      case 'cs':
        return 'Kč';

      case 'da':
        return 'kr';

      case 'hu':
        return 'Ft';

      case 'ja':
        return '¥';

      case 'ko':
        return '₩';

      case 'nb-NO':
        return 'kr';

      case 'pl':
        return 'zł';

      case 'ro':
        return 'L';

      case 'ru':
        return 'p.';

      case 'sv-SE':
        return 'kr';

      case 'th-TH':
        return '฿';

      case 'tr':
        return '₺';

      case 'vi-VN':
        return '₫';

      case 'zh-CN':
        return '¥';

      case 'zh-TW':
        return '¥';

      case 'en-US':
      case 'fr-CA':
      default:
        return '$';
    }
  }

  this.getCurrencySymbolByLocaleId = getCurrencySymbolByLocaleId;
};

exports.i18nUtils = {
  TranslationFunctions: TranslationFunctions
};