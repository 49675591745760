if (typeof require === 'undefined') {
  Components.utils.import('resource://requireloc/dotsRequireModule.js');
  var module = {
    exports: Object.create(null)
  };
  Object.defineProperty(this, 'exports', {
    get: function get() {
      return module.exports;
    }
  });
  var EXPORTED_SYMBOLS = ['module'];
}

module.exports = {
  NAME: 'name',
  SHORTNAME: 'shortName',
  ORGANISATION: 'organisation',
  STREET: 'street',
  POSTALCODE: 'postalCode',
  CITY: 'city',
  REGION: 'region',
  STATE: 'state',
  TELEPHONE1: 'tel1',
  TELEPHONE2: 'tel2',
  FAX: 'telfax',
  EMAIL: 'email',
  PROJECT: 'project',
  PROJECTNUMBER: 'projectNumber',
  CUSTOM1: 'custom1',
  CUSTOM2: 'custom2',
  CUSTOM3: 'custom3'
};