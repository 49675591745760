import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { Button } from '../button';
import { Modal, ModalHeader, ModalBody, ModalFooter } from '../modal';
import useI18n from '../../hooks/use-i18n';

const Dialog = ({
	cancelLabel,
	children,
	confirmDisabled,
	confirmLabel,
	extra1Label,
	id,
	isOpen,
	onCancel,
	onConfirm,
	onExtra1,
	onOpen,
	title,
	type,
}) => {
	const i18n = useI18n();
	const primaryButton = useRef();

	const onOpenDialog = () => {
		setTimeout(() => {
			onOpen && onOpen();
			primaryButton.current && primaryButton.current.focus();
		}, 0);
	};

	return (
		<Modal
			isOpen={isOpen}
			onCancel={onCancel}
			onConfirm={onConfirm}
			onExtra1={onExtra1}
			onOpen={onOpenDialog}
			type={type}
		>
			<ModalHeader>{title}</ModalHeader>
			<ModalBody>{children}</ModalBody>
			<ModalFooter>
				{onConfirm && (
					<Button
						disabled={confirmDisabled}
						id={id ? `${id}-button-submit` : 'button-submit'}
						onClick={onConfirm}
						setRef={primaryButton}
						type="primary"
					>
						{confirmLabel || i18n.translate('global.ok')}
					</Button>
				)}
				{onExtra1 && (
					<Button
						id={id ? `${id}-button-extra-1` : 'button-extra-1'}
						onClick={onExtra1}
					>
						{extra1Label || ""}
					</Button>
				)}
				{onCancel && (
					<Button
						id={id ? `${id}-button-cancel` : 'button-cancel'}
						onClick={onCancel}
					>
						{cancelLabel || i18n.translate('global.cancel')}
					</Button>
				)}
			</ModalFooter>
		</Modal>
	);
};

Dialog.propTypes = {
	/**
	 * alternate label for cancel button
	 */
	cancelLabel: PropTypes.node,
	/**
	 * content of the modal body
	 */
	children: PropTypes.node,
	/**
	 * disable the confirm button
	 */
	confirmDisabled: PropTypes.bool,
	/**
	 * alternate label for the confirm button
	 */
	confirmLabel: PropTypes.node,
	/**
	 * label for extra button
	 */
	extra1Label: PropTypes.node,
	/**
	 * unique id
	 */
	id: PropTypes.string,
	/**
	 * wether modal is open
	 */
	isOpen: PropTypes.bool,
	/**
	 * func called on cancel
	 */
	onCancel: PropTypes.func,
	/**
	 * func called on confirm
	 */
	onConfirm: PropTypes.func,
	/**
	 * func called on extra action click
	 */
	onExtra1: PropTypes.func,
	/**
	 * func called when modal opens
	 */
	onOpen: PropTypes.func,
	/**
	 * dialog title
	 */
	title: PropTypes.node,
	/**
	 * dialog type: [full, small, medium], default: small
	 */
	type: PropTypes.oneOf(['full', 'small', 'medium']),
};

Dialog.defaultProps = {
	cancelLabel: null,
	children: null,
	confirmDisabled: false,
	confirmLabel: null,
	extra1Label: null,
	id: null,
	isOpen: false,
	onCancel: null,
	onConfirm: null,
	onExtra1: null,
	onOpen: null,
	title: null,
	type: 'small',
};

export default Dialog;
