if (typeof require === 'undefined') {
  // HEADER to make require work by using Components.utils.import internally - DON'T TOUCH THIS, PLEASE
  // eslint-disable-next-line no-undef
  Components.utils.import('resource://requireloc/dotsRequireModule.js');
  var module = {
    exports: Object.create(null)
  };
  Object.defineProperty(this, 'exports', {
    get: function get() {
      return module.exports;
    }
  });
  var EXPORTED_SYMBOLS = ['module']; // END OF HEADER
}

module.exports = {
  ABOUT: 'about',
  ACCOUNT_CONNECTOR: 'accountConnector',
  ACCOUNT_SETTING: 'account-setting',
  BACKUP_RESTORE: 'backup-restore',
  CLUSTER: 'cluster',
  CONFIGURATION_OVERVIEW: 'configuration-overview',
  CUSTOMER_ACCOUNTS: 'customer-accounts',
  DASHBOARD: 'dashboard',
  DELIVERY_TYPE: 'delivery-type',
  DOWNLOAD: 'download',
  EMAIL_CONFIG: 'email-config',
  GENERAL_SETTINGS: 'general-settings',
  HELP: 'help',
  HOTFOLDER: 'hotfolder',
  INTEGRATION_SERVICES: 'integration-services',
  JOBTICKET_SETTINGS: 'jobticket-settings',
  JOB_LIST: 'job-list',
  JTPANEL_CONFIG: 'jtpanel-config',
  JTWEB_SYNC: 'jtweb-sync',
  LOGOUT: 'logout',
  PAPER_CATALOG: 'paper-catalog',
  PAPER_SIZE: 'paper-size',
  PRELIGHT: 'preflight',
  PRINTER: 'printer',
  PRINT_QUEUE: 'print-queue',
  PRINT_ROOM: 'print-room',
  PRODUCT: 'product',
  REPORT: 'report',
  ROLE: 'role',
  ROLL_SIGNATURE: 'roll-signature',
  RULE_FOR_JOB: 'rule-for-job',
  SEARCH: 'search',
  SERVICE: 'service',
  SIGNATURE: 'signature',
  USER: 'user',
  USER_NOTIFICATION: 'user-notification',
  VIRTUAL_PRINTER: 'virtual-printer',
  WORK_STEP: 'work-step'
};