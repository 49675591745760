if (typeof require === 'undefined') {
  Components.utils.import('resource://requireloc/dotsRequireModule.js');
  var module = {
    exports: Object.create(null)
  };
  Object.defineProperty(this, 'exports', {
    get: function get() {
      return module.exports;
    }
  });
  var EXPORTED_SYMBOLS = ['module'];
}

module.exports = {
  // Order item events (d)
  ORDER_ITEM_SUBMITTED: 'orderItemSubmitted',
  ORDER_ITEM_DUPLICATED: 'orderItemDuplicated',
  ORDER_ITEMS_CHANGED: 'orderItemsChanged',
  ORDER_ITEMS_TRASHED: 'orderItemsTrashed',
  ORDER_CHANGED: 'orderChanged',
  ORDER_ITEM_PAGE_SOURCES_CHANGED: 'orderItemPageSourcesChanged',
  // back-end only, will always fire an ORDER_ITEMS_CHANGED event
  ORDER_ITEMS_PASTED_INTO_ORDER_ITEM: 'orderItemsPastedIntoOrderItem',
  //back-end only
  WORK_STEP_STATES_CHANGED: 'workStepStatesChanged',
  // back-end only, will always fire an ORDER_ITEMS_CHANGED event
  // Print queue (c) [NEEDS PRINT_JOBS_CHANGED/CREATED/DELETED or similar]
  PRINT_JOB_STATUS_CHANGED: 'printJobStatusChanged',
  // params: job, orderItem
  PRINT_JOBS_REMOVED: 'printJobsRemoved',
  PRINTER_SETTINGS_CHANGED: 'printerSettingsChanged',
  PRINT_QUEUE_CHANGED: 'printQueueChanged',
  // TODO: DEPRECATED
  PRINT_QUEUE_CONTENT_CHANGED: 'printQueueContentChanged',
  // TODO: DEPRECATED
  PRINT_QUEUES_CHANGED: 'printQueuesChanged',
  // Submission, back-end only
  BEFORE_ORDER_ITEM_SUBMITTED: 'beforeOrderItemSubmitted',
  BEFORE_ORDER_ITEM_DUPLICATED: 'beforeOrderItemDuplicated',
  AFTER_ORDER_ITEM_SUBMITTED: 'afterOrderItemSubmitted',
  AFTER_ORDER_ITEM_DUPLICATED: 'afterOrderItemDuplicated',
  // Module events
  NOTIFICATIONS_CREATED: 'notificationsCreated',
  // Module events, back-end only
  CREATE_NOTIFICATIONS: 'createNotifications',
  // TODO: This is currently used as a command, not as event
  CUSTOM_PRODUCT_IMAGES_CHANGED: 'customProductImagesChanged',
  VDP_PRODUCT_CHANGED: 'vdpProductChanged',
  // Configuration events (a)
  ADDRESS_CONFIGS_CHANGED: 'addressConfigsChanged',
  CUSTOMER_ACCOUNTS_CHANGED: 'customerAccountsChanged',
  DELIVERY_TYPES_CHANGED: 'deliveryTypesChanged',
  HOTFOLDERS_CHANGED: 'hotfoldersChanged',
  LIST_SORTING_CHANGED: 'listSortingChanged',
  PAPER_CATALOGS_CHANGED: 'paperCatalogsChanged',
  PAPER_CATEGORIES_CHANGED: 'paperCategoriesChanged',
  PAPER_SIZES_CHANGED: 'paperSizesChanged',
  PAPER_TYPES_CHANGED: 'paperTypesChanged',
  PRODUCT_CATEGORIES_CHANGED: 'productCategoriesChanged',
  PRODUCTS_CHANGED: 'productsChanged',
  SERVICE_GROUPS_CHANGED: 'serviceGroupsChanged',
  SERVICES_CHANGED: 'servicesChanged',
  SIGNATURES_CHANGED: 'signaturesChanged',
  ROLL_JOB_TEMPLATES_CHANGED: 'rollJobTemplateChanged',
  VIRTUAL_PRINTERS_CHANGED: 'virtualPrintersChanged',
  WORK_STEPS_CHANGED: 'workStepsChanged',
  FOLDERS_CHANGED: 'foldersChanged',
  ORDER_ITEM_FILTERS_CHANGED: 'orderItemFiltersChanged',
  PRINT_JOB_FILTERS_CHANGED: 'printJobFiltersChanged',
  REPORTS_CHANGED: 'reportsChanged',
  VIRTUAL_PRINTERS_STATUS_CHANGED: 'virtualPrintersStatusChanged',
  // Printer events, should be moved to configuration (a)
  PRINTER_CLUSTERS_CHANGED: 'printerClustersChanged',
  PRINTERS_CHANGED: 'printersChanged',
  PRINTER_CHANGED: 'printerChanged',
  PRINTER_STATUS_CHANGED: 'printerStatusChanged',
  // Settings and module configuration events (a)
  MODULE_CONFIG_CHANGED: 'moduleConfigChanged',
  SETTINGS_CHANGED: 'settingsChanged',
  // User and roles events (b)
  USERS_ONLINE_STATUS_CHANGED: 'usersOnlineStatusChanged',
  USERS_CHANGED: 'usersChanged',
  ROLES_CHANGED: 'rolesChanged',
  PRINTER_USERS_CHANGED: 'printerUsersChanged',
  USER_SETTINGS_CHANGED: 'userSettingsChanged',
  // Back-end only events (internal workflow related)
  BEFORE_INITIALIZATION_FINISHED: 'beforeInitializationFinished',
  INITIALIZATION_FINISHED: 'initializationFinished',
  HOTFOLDERS_SYNCHRONIZED: 'hotfoldersSynchronized',
  VIRTUAL_PRINTERS_SYNCHRONIZED: 'virtualPrintersSynchronized',
  EMAIL_SENDING_REQUESTED: 'emailSendingRequested',
  // TODO: This is actually a command, not an event
  // Deprecated events - DO NOT USE WITHOUT GOOD REASON
  PRINTERMODELS_CHANGED: 'printerModelsChanged',
  // Not used atm, possibly deprecated
  PRINTTECHNOLOGIES_CHANGED: 'printTechnologiesChanged',
  // Not used atm, possibly deprecated
  PPDS_CHANGED: 'ppdsChanged',
  // Not used atm, possibly deprecated
  PUBLIC_API_WEBHOOKS_CHANGED: 'publicApiWebhooksChanged',
  //triggers a read & synch of rest-api webhooks
  VERSION_INFO_CHANGED: 'versionInfoChanged'
};