if (typeof require === 'undefined') {
  // eslint-disable-next-line no-undef
  Components.utils.import('resource://requireloc/dotsRequireModule.js');
  var module = {
    exports: Object.create(null)
  };
  Object.defineProperty(this, 'exports', {
    get: function get() {
      return module.exports;
    }
  });
  var EXPORTED_SYMBOLS = ['module'];
}

module.exports = {
  AUTOPRINT: 'autoprint',
  CALENDAR: 'calendar',
  CLUSTER: 'cluster',
  FIRST_STEPS: 'firstSteps',
  PRINTER: 'printer',
  PRINTER_AND_CLUSTER: 'printerAndCluster',
  REPORT: 'report',
  SMART_FOLDER: 'smartFolder',
  USER: 'user',
  VERSION_INFO: 'versionInfo'
};