import { combineReducers } from 'redux';
import * as actionTypes from '../enums/action-types';
import { ConfigPortalTabs, PortalEditorGroups } from '../enums/config-portal-tabs';

const commandList = (state = [], action) => {
	switch(action.type) {
	case actionTypes.SET_COMMAND_LIST:
		return action.commandList;
	case actionTypes.CLEAR_COMMAND_LIST:
		return [];
	default:
		return state;
	}
};

const currentCommand = (state = 0, action) => {
	switch(action.type) {
	case actionTypes.SET_CURRENT_COMMAND:
		return action.currentCommand;
	case actionTypes.CLEAR_COMMAND_LIST:
		return 0;
	default:
		return state;
	}
};

const editingPortal = (state = null, action) => {
	switch (action.type) {
	case actionTypes.SET_EDITING_PORTAL: {
		return action.editingPortal;
	}
	default:
		return state;
	}
};

const isChanged = (state = false, action) => {
	switch(action.type) {
		case actionTypes.SET_EDITING_PORTAL_IS_CHANGED:
			return action.isChanged;
		default:
			return state;
	}
};

const originalPortal = (state = null, action) => {
	switch (action.type) {
	case actionTypes.SET_ORIGINAL_PORTAL:
		return action.originalPortal;
	default:
		return state;
	}
};

const initialTab = ConfigPortalTabs.GENERAL;

const selectedTab = (state = initialTab, action) => {
	switch (action.type) {
	case actionTypes.SET_PORTAL_EDITOR_SELECTED_TAB:
		return action.selectedTab;
	default:
		return state;
	}
};

const initialEditorGroup = PortalEditorGroups[initialTab];
const initialOpenGroup = initialEditorGroup[Object.keys(initialEditorGroup)[0]];

const openGroup = (state = initialOpenGroup, action) => {
	switch (action.type) {
	case actionTypes.SET_PORTAL_EDITOR_OPEN_GROUP:
		if (action.openGroup === state) {
			// toggle opening
			return '';
		}
		return action.openGroup;
	case actionTypes.SET_PORTAL_EDITOR_SELECTED_TAB: {
		if (action.openGroup || !isNaN(action.openGroup)) {
			return action.openGroup;
		}
		return state;
	}
	default:
		return state;
	}
};

const openContent = (state = 0, action) => {
	switch (action.type) {
	case actionTypes.SET_PORTAL_EDITOR_SELECTED_TAB:
	case actionTypes.SET_PORTAL_EDITOR_OPEN_CONTENT:
		if (action.openContent || !isNaN(action.openContent)) {
			return action.openContent;
		}
		return null;
	default:
		return state;
	}
};

const portalCountLimit = (state = 0, action) => {
	switch (action.type) {
	case actionTypes.SET_PORTAL_COUNT_LIMIT:
		return action.portalCountLimit;
	default:
		return state;
	}
};

export default combineReducers({
	commandList,
	currentCommand,
	editingPortal,
	isChanged,
	openContent,
	openGroup,
	originalPortal,
	portalCountLimit,
	selectedTab,
});

