import * as actionTypes from '../enums/action-types';
import * as portalApis from '../apis/portal';
import * as configApis from '../apis/config';

export const setProductCategories = productCategories => ({ type: actionTypes.SET_PRODUCT_CATEGORIES, productCategories });

export const setProducts = products => ({ type: actionTypes.SET_PRODUCTS, products });

export const setStockProducts = stockProducts => ({ type: actionTypes.SET_STOCK_PRODUCTS, stockProducts });

export const setGroups = groups => ({ type: actionTypes.SET_GROUPS, groups });

export const setPortals = portals => ({ type: actionTypes.SET_PORTALS, portals });

export const getPortals = () => async(dispatch) => {
	const portals = await portalApis.getPortals();
	dispatch(setPortals(portals));
};

export const getProductCategories = () => async(dispatch) => {
	const productCategories = await configApis.getProductCategories();
	dispatch(setProductCategories(productCategories));
}

export const getProducts = () => async(dispatch) => {
	const products = await configApis.getProducts();
	dispatch(setProducts(products));
};

export const getStockProducts = () => async(dispatch) => {
	const stockProducts = await configApis.getStockProducts();
	dispatch(setStockProducts(stockProducts));
};
