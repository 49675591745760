import app from './app';
import auth from './auth';
import config from './config';
import modal from './modal';
import portalConfig from './portal-config';
import settings from './settings';

export default {
	app,
	auth,
	config,
	modal,
	portalConfig,
	settings
};
