if (typeof require === 'undefined') {
  // eslint-disable-next-line no-undef
  Components.utils.import('resource://requireloc/dotsRequireModule.js');
  var module = {
    exports: Object.create(null)
  };
  Object.defineProperty(this, 'exports', {
    get: function get() {
      return module.exports;
    }
  });
  var EXPORTED_SYMBOLS = ['module'];
}

module.exports = {
  // containers enums for server dashboard
  CONTAINER_TOP: 'container-top',
  CONTAINER_LEFT: 'container-left',
  CONTAINER_RIGHT: 'container-right'
};