import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from '../icon';
import { Portal } from '../portal'; 

const loaderRoot = typeof document !== 'undefined' ? document.getElementById('loader') : null;

const Loader = ({ block, loading, text }) => {
	if (!loading) return null;

	if (!block) {
		return (
			<Portal container={loaderRoot}>
				<div className="h-screen flex flex-col fixed items-center justify-center max-h-screen inset-0 w-full z-100">
					<Icon name="loading" color="primary" size={64} />
					{text && <p className="m-1 text-center text-primary">{text}</p>}
				</div>
			</Portal>
		);
	}

	return (
		// TODO: might need to block keyboard and mouse events.
		<Portal container={loaderRoot}>
			<div className="bg-gray-darkest h-screen flex flex-col fixed items-center justify-center max-h-screen inset-0 w-full z-100">
				<Icon name="loading" color="primary" size={64} />
				{text && <p className="m-1 text-center text-primary text-white">{text}</p>}
			</div>
		</Portal>
	);
};

Loader.propTypes = {
	/**
	 * grey out background, block app
	 */
	block: PropTypes.bool,
	/**
	 * if false, component returns null
	 */
	loading: PropTypes.bool,
	text: PropTypes.string,
};

Loader.defaultProps = {
	block: false,
	loading: false,
};

export default Loader;
