export const getBaseName = () => {
	const pathname = window.location.pathname;
	const parsedPathname = pathname.split('/');
	const isPortalUrl = parsedPathname[1] && parsedPathname[1] === 'portal';
	let baseName = '';
	if (isPortalUrl) {
		baseName = parsedPathname[1] + '/' + parsedPathname[2];
	}
	return baseName;
};

export const getApiBaseName = () => {
	const baseName = getBaseName();
	if (baseName) {
		return baseName + '/';
	}
	return '';
};

export const isPortalUrl = () => {
	const pathname = window.location.pathname;
	return pathname.split('/')[1] && pathname.split('/')[1] === 'portal';
};

export const getPortalName = () => {
	const pathname = window.location.pathname;
	const parsedPathname = pathname.split('/');
	if (parsedPathname[1] && parsedPathname[1] === 'portal' && parsedPathname[2]) {
		return parsedPathname[2];
	}
	return "";
};

export const getSsoLink = (portal = {}) => {
	const { pathname, search } = window.location;
	const decodedUriPathName = decodeURIComponent(pathname);
	const parsedPathName = decodedUriPathName[decodedUriPathName.length - 1] === '/' ? decodedUriPathName.slice(0, -1) : decodedUriPathName;
	const pathModule = portal.url ? parsedPathName.substr(portal.url.length) : pathname;
	const queryString = search.replace('action=close', '').replace(/&?loginError=[^&]*/, '').replace(/&?pass=1/, '');

	if (queryString)  {
		return 'loginSSO.web?redirect=' + pathModule.replace('/', '') + queryString;
	} else if (pathModule && pathModule !== 'login.web') {
		return 'loginSSO.web?redirect=' + pathModule.replace('/', '');
	}
	return 'loginSSO.web';
};

export const getValidLinkUrl = (url = "") => {
	let newUrl = window.decodeURIComponent(url);
	newUrl = newUrl.trim().replace(/\s/g, "");

	if(/^(:\/\/)/.test(newUrl)){
		return `http${newUrl}`;
	}
	if(!/^(f|ht)tps?:\/\//i.test(newUrl)){
		return `http://${newUrl}`;
	}

	return newUrl;
};

