if (typeof require === 'undefined') {
  Components.utils.import('resource://requireloc/dotsRequireModule.js');
  var module = {
    exports: Object.create(null)
  };
  Object.defineProperty(this, 'exports', {
    get: function get() {
      return module.exports;
    }
  });
  var EXPORTED_SYMBOLS = ['module'];
}

module.exports = {
  Hotfolder: 'JT Hotfolder',
  VIRTUAL_PRINTER: 'VIRTUAL_PRINTER',
  Man: 'JT Man',
  ManQuickImport: 'Man Quick Import',
  IntegrationServices: 'Integration Services',
  PrinterClient: 'JT Printer Client',
  PrinterImport: 'Printer Import',
  Web: 'JT Web',
  Migrate: 'JT Man Migrate'
};