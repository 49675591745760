// APP
export const SET_APP_HAS_LOADED = 'APP_HAS_LOADED';
export const SET_COOKIE_CONSENT = 'SET_COOKIE_CONSENT';
export const SET_CSRF_TOKEN = 'SET_CSRF_TOKEN';
export const SET_I18N_STRINGS = 'SET_I18N_STRINGS';
export const SET_LICENSE = 'SET_LICENSE';
export const SET_LOCALE_ID = 'SET_LOCALE_ID';
export const SET_PORTAL = 'SET_PORTAL';

// AUTH
export const SET_AUTH_STATUS = 'SET_AUTH_STATUS';
export const SET_USER_SESSION = 'SET_USER_SESSION';
export const CLEAR_USER_SESSION = 'CLEAR_USER_SESSION';

// CONFIG
export const SET_GROUPS = 'SET_GROUPS';
export const SET_PORTALS = 'SET_PORTALS';
export const SET_PRODUCT_CATEGORIES = 'SET_PRODUCT_CATEGORIES';
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const SET_STOCK_PRODUCTS = 'SET_STOCK_PRODUCTS';

// MODALS
export const CLOSE_MODALS = 'CLOSE_MODALS';
export const CLOSE_COOKIE_MANAGER_MODAL = 'CLOSE_COOKIE_MANAGER_MODAL';
export const CLOSE_SSO_ATTRIBUTE_MAPPING_MODAL = 'CLOSE_SSO_ATTRIBUTE_MAPPING_MODAL';
export const CLOSE_SSO_SETTINGS_MODAL = 'CLOSE_SSO_SETTINGS_MODAL';
export const OPEN_COOKIE_MANAGER_MODAL = 'OPEN_COOKIE_MANAGER_MODAL';
export const OPEN_SSO_ATTRIBUTE_MAPPING_MODAL = 'OPEN_SSO_ATTRIBUTE_MAPPING_MODAL';
export const OPEN_SSO_SETTINGS_MODAL = 'OPEN_SSO_SETTINGS_MODAL';

// PORTAL_EDITOR
export const CLEAR_COMMAND_LIST = 'CLEAR_COMMAND_LIST';
export const SET_COMMAND_LIST = 'SET_COMMAND_LIST';
export const SET_CURRENT_COMMAND = 'SET_CURRENT_COMMAND';
export const SET_EDITING_PORTAL = 'SET_EDITING_PORTAL';
export const SET_EDITING_PORTAL_IS_CHANGED = 'SET_EDITING_PORTAL_IS_CHANGED';
export const SET_HEADER_MODES = 'SET_HEADER_MODES';
export const SET_ORIGINAL_PORTAL = 'SET_ORIGINAL_PORTAL';
export const SET_PORTAL_EDITOR_ERROR = 'SET_PORTAL_EDITOR_ERROR';
export const SET_PORTAL_EDITOR_SELECTED_TAB = 'SET_PORTAL_EDITOR_SELECTED_TAB';
export const SET_PORTAL_EDITOR_OPEN_CONTENT = 'SET_PORTAL_EDITOR_OPEN_CONTENT';
export const SET_PORTAL_EDITOR_OPEN_GROUP = 'SET_PORTAL_EDITOR_OPEN_GROUP';
export const SET_PORTAL_COUNT_LIMIT = 'SET_PORTAL_COUNT_LIMIT';


// SETTINGS
export const SET_ALLOWED_FILE_TYPES = 'SET_ALLOWED_FILE_TYPES';
export const SET_SYSTEM_SETTINGS = 'SET_SYSTEM_SETTINGS';
export const SET_DEFAULT_SYSTEM_SETTINGS = 'SET_DEFAULT_SYSTEM_SETTINGS';
