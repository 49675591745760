if (typeof require === 'undefined') {
  Components.utils.import('resource://requireloc/dotsRequireModule.js');
  var module = {
    exports: Object.create(null)
  };
  Object.defineProperty(this, 'exports', {
    get: function get() {
      return module.exports;
    }
  });
  var EXPORTED_SYMBOLS = ['module'];
}

module.exports = {
  COLOR_MANAGEMENT: "colorManagement",
  TRAY_INFO: "trayInfo",
  PAPER_CATALOG: "paperCatalog",
  PAPER_TYPES_IN_USE: "paperTypesInUse",
  PRINTER_PAPER_FORMATS: "printerPaperFormats"
};