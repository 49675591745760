if (typeof require === 'undefined') {
  // eslint-disable-next-line no-undef
  Components.utils.import('resource://requireloc/dotsRequireModule.js');
  var module = {
    exports: Object.create(null)
  };
  Object.defineProperty(this, 'exports', {
    get: function get() {
      return module.exports;
    }
  });
  var EXPORTED_SYMBOLS = ['module'];
}

module.exports = {
  ACCOUNTING_CONNECTOR: 'accounting-connector-settings',
  ALLOWED_FILE_TYPES: 'allowed-file-types-settings',
  FIRST_STEPS: 'first-steps-settings',
  FOLDERRULE_SETTINGS: 'folderrule-settings',
  INTERNAL_SETTINGS: 'internal-settings',
  JOBTICKET_SETTINGS: 'jobticket-settings',
  PRINTER_IMPORT_SETTINGS: 'printer-import-settings',
  SERVER: 'server-settings',
  SIGNATURE_SETTINGS: 'signature-settings',
  ROLL_JOB_TEMPLATE_SETTINGS: 'roll-job-template-settings',
  TRASHFILTER_SETTINGS: 'trashfilter-settings',
  WORK_STEPS: 'work-steps-settings'
};