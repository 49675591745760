const keyboardKeys = {
	ALT: 'Alt',
	ARROW_DOWN: 'ArrowDown',
	ARROW_LEFT: 'ArrowLeft',
	ARROW_RIGHT: 'ArrowRight',
	ARROW_UP: 'ArrowUp',
	ENTER: 'Enter',
	ESC: 'Escape',
	TAB: 'Tab',
	DOT: '.', // Covers numpad dot and keyboard dot
	BACKSPACE: 'Backspace'
};

export default keyboardKeys;