import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const getSubHeadingClassName = (textSize, thin, className) => classNames(
	'leading-normal',
	{
		[`text-${textSize}`]: textSize !== 'inherit',
		'font-normal font-sans': thin,
		'font-normal font-sans-bold': !thin,
	},
	className,
);

const Subheading = ({ children, className, style, textSize, thin, type, title, testid, setRef }) => {
	switch (type) {
	case 'h2':
	default:
		return (
			<h2
				className={getSubHeadingClassName(textSize, thin, className)}
				data-testid={testid}
				ref={setRef}
				style={style}
				title={title}
			>
				{children}
			</h2>
		);
	case 'h3':
		return (
			<h3
				className={getSubHeadingClassName(textSize, thin, className)}
				data-testid={testid}
				ref={setRef}
				style={style}
				title={title}
			>
				{children}
			</h3>
		);
	case 'h4':
		return (
			<h4
				className={getSubHeadingClassName(textSize, thin, className)}
				data-testid={testid}
				style={style}
				ref={setRef}
				title={title}
			>
				{children}
			</h4>
		);
	case 'h5':
		return (
			<h5
				className={getSubHeadingClassName(textSize, thin, className)}
				data-testid={testid}
				style={style}
				ref={setRef}
				title={title}
			>
				{children}
			</h5>
		);
	case 'h6':
		return (
			<h6
				className={getSubHeadingClassName(textSize, thin, className)}
				data-testid={testid}
				style={style}
				ref={setRef}
				title={title}
			>
				{children}
			</h6>
		);
	}
}

Subheading.propTypes = {
	className: PropTypes.string,
	textSize: PropTypes.oneOf(['xs','s', 'base', 'l', 'xl', 'inherit']),
	type: PropTypes.string,
};

Subheading.defaultProps = {
	className: '',
	textSize: 'base',
	thin: '',
	type: 'h2',
};

export default Subheading;