if (typeof require === 'undefined') {
  Components.utils.import('resource://requireloc/dotsRequireModule.js');
  var module = {
    exports: Object.create(null)
  };
  Object.defineProperty(this, 'exports', {
    get: function get() {
      return module.exports;
    }
  });
  var EXPORTED_SYMBOLS = ['module'];
}

module.exports = {
  CLUSTERS: 'clusters',
  DELIVERY_TYPES: 'deliveryTypes',
  PAPER_SIZES: 'paperSizes',
  PAPER_TYPES: 'paperTypes',
  PAPER_CATEGORIES: 'paperCategories',
  SIGNATURES: 'signatures',
  ROLL_JOB_TEMPLATES: 'rollJobTemplates',
  PRODUCTS: 'products',
  PRODUCT_CATEGORIES: 'productCategories',
  PRINTERS: 'printers',
  REPORTS: 'reports',
  SERVICE_GROUPS: 'serviceGroups'
};