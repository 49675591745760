import React from 'react';
import { Button } from '../../button';
import keyboardKeys from '../../../utils/keyboard-keys';
import useI18n from '../../../hooks/use-i18n';

const ButtonBack = ({ onClick, disabled, configId }) => {
	const i18n = useI18n();

	const handleOnKeyDown = e => {
		if (e.key === keyboardKeys.ENTER) {
			e.stopPropagation();
			onClick();
		}
	}

	return (
		<Button
			className="h-32 w-32"
			disabled={disabled}
			icon="toolbar_back"
			iconSize={32}
			id={`${configId}-previous`}
			label={i18n.translate('previewTooltipPrevious')}
			onClick={onClick}
			onKeyDown={handleOnKeyDown}
			type="icon"
		/>
	);
};

export default ButtonBack;
