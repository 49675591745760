import { combineReducers } from 'redux';
import * as actionTypes from '../enums/action-types';

const authStatus = (state = null, action) => {
	switch (action.type) {
	case actionTypes.SET_AUTH_STATUS:
		return action.authStatus;
	default:
		return state;
	}
};

const userSession = (state = {}, action) => {
	switch(action.type) {
		case actionTypes.SET_USER_SESSION:
			return action.userSession;
		case actionTypes.CLEAR_USER_SESSION:
			return {};
		default:
			return state;
	};
};

export default combineReducers({
	userSession,
	authStatus,
});

