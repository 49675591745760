import { createSelector } from 'reselect';

export const groupsSelector = createSelector(
	[state => state.config.groups],
	(groups = []) => groups.map(group => {
		const authnActive = group.ldapConfig.ldapActive === "1";
		const ldapActive = authnActive && group.ldapConfig.active;
		return {
			...group,
			authnActive,
			ldapActive,
			products: group.products.split('|'),
			deliveryTypes: group.deliverytypes.split('|'),
		}
	})
);

export const portalsSelector = state => state.config.portals;

export const productCategoriesSelector = state => state.config.productCategories;

export const productsSelector = state => state.config.products;

export const stockProductsSelector = state => state.config.stockProducts;

