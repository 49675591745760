import React from 'react';
import ModalCountContext from './modal-count-context';

const withModalCount = WrapperedComponent => function WithModalCount({ ...props }) {
	return (
		<ModalCountContext.Consumer>
			{({ modalCount, addModal, removeModal }) => <WrapperedComponent modalCount={modalCount} addModal={addModal} removeModal={removeModal} {...props} />}
		</ModalCountContext.Consumer>
	);
};

export default withModalCount;
