if (typeof require === 'undefined') {
  Components.utils.import('resource://requireloc/dotsRequireModule.js');
  var module = {
    exports: Object.create(null)
  };
  Object.defineProperty(this, 'exports', {
    get: function get() {
      return module.exports;
    }
  });
  var EXPORTED_SYMBOLS = ['module'];
}

module.exports = {
  IDLE: 'printer.status.idle',
  OTHER: 'printer.status.other',
  UNKNOWN: 'printer.status.unknown',
  PRINTING: 'printer.status.printing',
  WARMUP: 'printer.status.warmup',
  NOT_AVAILABLE: 'printer.status.notAvailable',
  OFFLINE: 'printer.status.offline'
};