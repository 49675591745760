import alignment_AtCorners from './position/alignment_AtCorners.svg';
import alignment_AtGutter from './position/alignment_AtGutter.svg';
import alignment_BottomCentered from './position/alignment_BottomCentered.svg';
import alignment_BottomCentered_alternate from './position/alignment_BottomCentered_alternate.svg';
import alignment_BottomLeft from './position/alignment_BottomLeft.svg';
import alignment_BottomRight from './position/alignment_BottomRight.svg';
import alignment_Centered from './position/alignment_Centered.svg';
import alignment_Centered_alternate from './position/alignment_Centered_alternate.svg';
import alignment_Inside_bottom from './position/alignment_Inside_bottom.svg';
import alignment_Inside_centered from './position/alignment_Inside_centered.svg';
import alignment_Inside_top from './position/alignment_Inside_top.svg';
import alignment_LeftCentered from './position/alignment_LeftCentered.svg';
import alignment_Outside_bottom from './position/alignment_Outside_bottom.svg';
import alignment_Outside_centered from './position/alignment_Outside_centered.svg';
import alignment_Outside_top from './position/alignment_Outside_top.svg';
import alignment_RightCentered from './position/alignment_RightCentered.svg';
import alignment_TopCentered from './position/alignment_TopCentered.svg';
import alignment_TopCentered_alternate from './position/alignment_TopCentered_alternate.svg';
import alignment_TopLeft from './position/alignment_TopLeft.svg';
import alignment_TopRight from './position/alignment_TopRight.svg';
import alignToColumn from './alignment/alignToColumn.svg';
import alignToAllPages from './alignment/alignToAllPages.svg';
import alignToPages from './alignment/alignToPages.svg';
import alignToSheet from './alignment/alignToSheet.svg'; // {id: "SEPARATE", name: "Individually by height"}
// {id: "ALIGNED", name: "Aligned on one column"}
// {id: "GRID", name: "Based on a fixed grid"}

import arrangement_aligned from './arrangement/BasedOnFixedColumn.svg';
import arrangement_grid from './arrangement/FixedGrid.svg';
import arrangement_separate from './arrangement/IndividuallyByHeight.svg';
import black_and_white from './colorprint/black_and_white.svg';
import color from './colorprint/color.svg';
import cover_back from './cover/cover_back.svg';
import cover_front_back from './cover/cover_front_back.svg';
import cover_front from './cover/cover_front.svg';
import creep_insideFix from './printersettings/creep_insideFix.svg';
import creep_outsideFix from './printersettings/creep_outsideFix.svg';
import customService from './customService.svg';
import binding_bottom from './position/binding_bottom.svg';
import binding_center from './position/binding_center.svg';
import binding_left from './position/binding_left.svg';
import binding_off from './option_off.svg';
import binding_right from './position/binding_right.svg';
import binding_top from './position/binding_top.svg';
import binding_comb_left from './binding/binding_comb_left.svg';
import binding_spiral_left from './binding/binding_spiral_left.svg';
import duplex_longside from './duplex/duplex_longside.svg';
import duplex_off from './duplex/duplex_off.svg';
import duplex_shortside from './duplex/duplex_shortside.svg';
import option_off from './option_off.svg';
import option_on from './option_on.svg';
import option_default from './option_default.svg';
import placeholder from './option_default.svg';
import fold_doubleParallelIn from './fold/fold_doubleParallelIn.svg';
import fold_doubleParallelOut from './fold/fold_doubleParallelOut.svg';
import fold_gateFoldIn from './fold/fold_gateFoldIn.svg';
import fold_gateFoldOut from './fold/fold_gateFoldOut.svg';
import fold_halfFold from './fold/fold_halfFold.svg';
import fold_halfFoldIn from './fold/fold_halfFoldIn.svg';
import fold_halfFoldOut from './fold/fold_halfFoldOut.svg';
import fold_letterFold from './fold/fold_letterFold.svg';
import fold_letterFoldIn from './fold/fold_letterFoldIn.svg';
import fold_letterFoldOut from './fold/fold_letterFoldOut.svg';
import fold_multiHalfFold from './fold/fold_multiHalfFold.svg';
import fold_multiHalfFoldInside from './fold/fold_multiHalfFoldInside.svg';
import fold_multiHalfFoldInsideTrim from './fold/fold_multiHalfFoldInsideTrim.svg';
import fold_multiHalfFoldOutside from './fold/fold_multiHalfFoldOutside.svg';
import fold_multiHalfFoldOutsideTrim from './fold/fold_multiHalfFoldOutsideTrim.svg';
import fold_multiHalfFoldTrim from './fold/fold_multiHalfFoldTrim.svg';
import fold_multiLetterFoldIn from './fold/fold_multiLetterFoldIn.svg';
import fold_multiLetterFoldOut from './fold/fold_multiLetterFoldOut.svg';
import fold_zfold from './fold/fold_zfold.svg';
import fold_zigZagIn from './fold/fold_zigZagIn.svg';
import fold_zigZagOut from './fold/fold_zigZagOut.svg';
import orientation_landscape from './orientation/orientation_landscape.svg';
import orientation_portrait from './orientation/orientation_portrait.svg';
import pageOrientation_0 from './rotation/pageOrientation_0.svg';
import pageOrientation_90 from './rotation/pageOrientation_90.svg';
import pageOrientation_180 from './rotation/pageOrientation_180.svg';
import pageOrientation_270 from './rotation/pageOrientation_270.svg';
import paperType from './papertype/paperType.svg';
import paperType_slipsheet from './papertype/paperType_slipsheet.svg';
import paperType_tabsheet from './papertype/paperType_tabsheet.svg';
import warning_papersize from './papertype/warning-papersize.svg';
import punch_2bigholes_left from './punch/punch_2bigholes_left.svg';
import punch_2holes_left from './punch/punch_2holes_left.svg';
import punch_3bigholes_left from './punch/punch_3bigholes_left.svg';
import punch_3holes_left from './punch/punch_3holes_left.svg';
import punch_4bigholes_left from './punch/punch_4bigholes_left.svg';
import punch_4holes_grouped from './punch/punch_4holes_grouped.svg';
import punch_4holes_left from './punch/punch_4holes_left.svg';
import punch_multihole_left from './punch/punch_multihole_left.svg';
import punch_on from './punch/punch_on.svg';
import stitch_none from './option_off.svg';
import stitch_on from './staple/stitch_on.svg';
import stitch_1_center_II from './staple/stitch_1_center_II.svg';
import stitch_1_center from './staple/stitch_1_center.svg';
import stitch_4_center from './staple/stitch_4_center.svg';
import stitch_4_left from './staple/stitch_4_left.svg';
import stitch_booklet from './staple/stitch_booklet.svg';
import stitch_bottom_diagonal_II from './staple/stitch_bottom_diagonal_II.svg';
import stitch_bottom_diagonal from './staple/stitch_bottom_diagonal.svg';
import stitch_bottom_left_horizontal_II from './staple/stitch_bottom_left_horizontal_II.svg';
import stitch_bottom_left_horizontal from './staple/stitch_bottom_left_horizontal.svg';
import stitch_bottom_left_II from './staple/stitch_bottom_left_II.svg';
import stitch_bottom_left_vertical_II from './staple/stitch_bottom_left_vertical_II.svg';
import stitch_bottom_left_vertical from './staple/stitch_bottom_left_vertical.svg';
import stitch_bottom_left from './staple/stitch_bottom_left.svg';
import stitch_double_left_wide from './staple/stitch_double_left_wide.svg';
import stitch_double_left_xwide from './staple/stitch_double_left_xwide.svg';
import stitch_double_left from './staple/stitch_double_left.svg';
import stitch_middle_portrait from './staple/stitch_middle_portrait.svg';
import stitch_top_left_diagonal_II from './staple/stitch_top_left_diagonal_II.svg';
import stitch_top_left_diagonal from './staple/stitch_top_left_diagonal.svg';
import stitch_top_left_horizontal_II from './staple/stitch_top_left_horizontal_II.svg';
import stitch_top_left_horizontal from './staple/stitch_top_left_horizontal.svg';
import stitch_top_left_II from './staple/stitch_top_left_II.svg';
import stitch_top_left_vertical_II from './staple/stitch_top_left_vertical_II.svg';
import stitch_top_left_vertical from './staple/stitch_top_left_vertical.svg';
import stitch_top_left from './staple/stitch_top_left.svg';
import trim_and_stitch from './staple/trim_and_stitch.svg';
import trim3sides_and_stitch from './staple/trim3sides_and_stitch.svg';
import stop_stitch_1_center_II from './stopStaple/stop_stitch_1_center_II.svg';
import stop_stitch_1_center from './stopStaple/stop_stitch_1_center.svg';
import stop_stitch_4_center from './stopStaple/stop_stitch_4_center.svg';
import stop_stitch_4_left from './stopStaple/stop_stitch_4_left.svg';
import stop_stitch_booklet from './stopStaple/stop_stitch_booklet.svg';
import stop_stitch_bottom_diagonal_II from './stopStaple/stop_stitch_bottom_diagonal_II.svg';
import stop_stitch_bottom_diagonal from './stopStaple/stop_stitch_bottom_diagonal.svg';
import stop_stitch_bottom_left_horizontal_II from './stopStaple/stop_stitch_bottom_left_horizontal_II.svg';
import stop_stitch_bottom_left_horizontal from './stopStaple/stop_stitch_bottom_left_horizontal.svg';
import stop_stitch_bottom_left_II from './stopStaple/stop_stitch_bottom_left_II.svg';
import stop_stitch_bottom_left_vertical_II from './stopStaple/stop_stitch_bottom_left_vertical_II.svg';
import stop_stitch_bottom_left_vertical from './stopStaple/stop_stitch_bottom_left_vertical.svg';
import stop_stitch_bottom_left from './stopStaple/stop_stitch_bottom_left.svg';
import stop_stitch_double_left_wide from './stopStaple/stop_stitch_double_left_wide.svg';
import stop_stitch_double_left_xwide from './stopStaple/stop_stitch_double_left_xwide.svg';
import stop_stitch_double_left from './stopStaple/stop_stitch_double_left.svg';
import stop_stitch_middle_portrait from './stopStaple/stop_stitch_middle_portrait.svg';
import stop_stitch_on from './stopStaple/stop_stitch_on.svg';
import stop_stitch_top_left_diagonal_II from './stopStaple/stop_stitch_top_left_diagonal_II.svg';
import stop_stitch_top_left_diagonal from './stopStaple/stop_stitch_top_left_diagonal.svg';
import stop_stitch_top_left_horizontal_II from './stopStaple/stop_stitch_top_left_horizontal_II.svg';
import stop_stitch_top_left_horizontal from './stopStaple/stop_stitch_top_left_horizontal.svg';
import stop_stitch_top_left_II from './stopStaple/stop_stitch_top_left_II.svg';
import stop_stitch_top_left_vertical_II from './stopStaple/stop_stitch_top_left_vertical_II.svg';
import stop_stitch_top_left_vertical from './stopStaple/stop_stitch_top_left_vertical.svg';
import stop_stitch_top_left from './stopStaple/stop_stitch_top_left.svg';
import stop_trim_and_stitch from './stopStaple/stop_trim_and_stitch.svg';
import stop_trim3sides_and_stitch from './stopStaple/stop_trim3sides_and_stitch.svg';
import imposition_columns from './imposition/Columns.svg';
import imposition_cutandstack from './imposition/CutAndStack.svg';
import imposition_cutandstack_by_column from './imposition/CutAndStackByColumn.svg';
import imposition_cutandstack_by_row from './imposition/CutAndStackByRow.svg';
import imposition_gangup from './imposition/GangUp.svg';
import imposition_repeat_by_column from './imposition/RepeatByColumn.svg';
import imposition_repeat_by_row from './imposition/RepeatByRow.svg';
import imposition_rows from './imposition/Rows.svg';
import imposition_stepandrepeat from './imposition/StepAndRepeat.svg';
import imposition_stepandrepeat2 from './imposition/StepAndRepeat2.svg';
import layouttype_booklet from './imposition/Booklet.svg';
import layouttype_crossfold from './imposition/Crossfold.svg';
import layouttype_oneup from './imposition/One-up.svg';
import layouttype_noimposition from './imposition/NoImposition.svg';
import layouttype_multipleup from './imposition/Multiple-up.svg';
export default {
  alignment_AtCorners,
  alignment_AtGutter,
  alignment_BottomCentered,
  alignment_BottomCentered_alternate,
  alignment_BottomLeft,
  alignment_BottomRight,
  alignment_Centered,
  alignment_Centered_alternate,
  alignment_Inside_bottom,
  alignment_Inside_centered,
  alignment_Inside_top,
  alignment_LeftCentered,
  alignment_Outside_bottom,
  alignment_Outside_centered,
  alignment_Outside_top,
  alignment_RightCentered,
  alignment_TopCentered,
  alignment_TopCentered_alternate,
  alignment_TopLeft,
  alignment_TopRight,
  alignToColumn,
  alignToAllPages,
  alignToPages,
  alignToSheet,
  arrangement_aligned,
  arrangement_grid,
  arrangement_separate,
  binding_bottom,
  binding_center,
  binding_comb_left,
  binding_left,
  binding_off,
  binding_right,
  binding_spiral_left,
  binding_top,
  black_and_white,
  color,
  cover_back,
  cover_front_back,
  cover_front,
  creep_insideFix,
  creep_outsideFix,
  customService,
  duplex_longside,
  duplex_off,
  duplex_shortside,
  fold_doubleParallelIn,
  fold_doubleParallelOut,
  fold_gateFoldIn,
  fold_gateFoldOut,
  fold_halfFold,
  fold_halfFoldIn,
  fold_halfFoldOut,
  fold_letterFold,
  fold_letterFoldIn,
  fold_letterFoldOut,
  fold_multiHalfFold,
  fold_multiHalfFoldInside,
  fold_multiHalfFoldInsideTrim,
  fold_multiHalfFoldOutside,
  fold_multiHalfFoldOutsideTrim,
  fold_multiHalfFoldTrim,
  fold_multiLetterFoldIn,
  fold_multiLetterFoldOut,
  fold_zfold,
  fold_zigZagIn,
  fold_zigZagOut,
  imposition_columns,
  imposition_cutandstack,
  imposition_cutandstack_by_column,
  imposition_cutandstack_by_row,
  imposition_gangup,
  imposition_repeat_by_column,
  imposition_repeat_by_row,
  imposition_rows,
  imposition_stepandrepeat,
  imposition_stepandrepeat2,
  layouttype_booklet,
  layouttype_crossfold,
  layouttype_multipleup,
  layouttype_noimposition,
  layouttype_oneup,
  option_default,
  option_off,
  option_on,
  orientation_landscape,
  orientation_portrait,
  pageOrientation_0,
  pageOrientation_180,
  pageOrientation_270,
  pageOrientation_90,
  paperType,
  paperType_slipsheet,
  paperType_tabsheet,
  placeholder,
  punch_2bigholes_left,
  punch_2holes_left,
  punch_3bigholes_left,
  punch_3holes_left,
  punch_4bigholes_left,
  punch_4holes_grouped,
  punch_4holes_left,
  punch_multihole_left,
  punch_on,
  stitch_1_center,
  stitch_1_center_II,
  stitch_4_center,
  stitch_4_left,
  stitch_booklet,
  stitch_bottom_diagonal,
  stitch_bottom_diagonal_II,
  stitch_bottom_left,
  stitch_bottom_left_horizontal,
  stitch_bottom_left_horizontal_II,
  stitch_bottom_left_II,
  stitch_bottom_left_vertical,
  stitch_bottom_left_vertical_II,
  stitch_double_left,
  stitch_double_left_wide,
  stitch_double_left_xwide,
  stitch_middle_portrait,
  stitch_none,
  stitch_on,
  stitch_top_left,
  stitch_top_left_diagonal,
  stitch_top_left_diagonal_II,
  stitch_top_left_horizontal,
  stitch_top_left_horizontal_II,
  stitch_top_left_II,
  stitch_top_left_vertical,
  stitch_top_left_vertical_II,
  stop_stitch_1_center,
  stop_stitch_1_center_II,
  stop_stitch_4_center,
  stop_stitch_4_left,
  stop_stitch_booklet,
  stop_stitch_bottom_diagonal,
  stop_stitch_bottom_diagonal_II,
  stop_stitch_bottom_left,
  stop_stitch_bottom_left_horizontal,
  stop_stitch_bottom_left_horizontal_II,
  stop_stitch_bottom_left_II,
  stop_stitch_bottom_left_vertical,
  stop_stitch_bottom_left_vertical_II,
  stop_stitch_double_left,
  stop_stitch_double_left_wide,
  stop_stitch_double_left_xwide,
  stop_stitch_middle_portrait,
  stop_stitch_on,
  stop_stitch_top_left,
  stop_stitch_top_left_diagonal,
  stop_stitch_top_left_diagonal_II,
  stop_stitch_top_left_horizontal,
  stop_stitch_top_left_horizontal_II,
  stop_stitch_top_left_II,
  stop_stitch_top_left_vertical,
  stop_stitch_top_left_vertical_II,
  stop_trim3sides_and_stitch,
  stop_trim_and_stitch,
  trim3sides_and_stitch,
  trim_and_stitch,
  warning_papersize
};