/* eslint-disable no-console */
import guid from 'dots-guid';

import * as actionTypes from '../constants/action-types';

export const createMessage = message => ({ type: actionTypes.CREATE_MESSAGE, message: { ...message, id: guid(), type: message.type || 'success' } }); // TODO: Move 'default type' to Notification component.

export const deleteMessage = id => ({ type: actionTypes.DELETE_MESSAGE, id });

export const clearMessages = () => ({ type: actionTypes.CLEAR_MESSAGES });

export const createSuccessMessage = (message) => {
	if (typeof message === 'string') {
		return createMessage({ type: 'success', body: message });
	}

	if (typeof message === 'object') {
		return createMessage({ ...message, type: 'success' });
	}
};

export const createErrorMessage = (error) => async(dispatch) => {
	if (typeof error === 'string') {
		return dispatch(createMessage({ type: 'error', body: error }));
	}

	// NOTE: Show more details of response error.
	if (typeof error === 'object' && error.response) {
		try {
			const res = error.response.clone();
			const err = await res.json();

			if (err.details) {
				return dispatch(createMessage({
					type: 'error',
					body: error.details.stringId || error.message || 'jobTicket.errorHandling.msg.undefined',
				}));
			}

			const body = err.body || err.message || err.error || Object.keys(err)[0];
			const title = error.message !== body ? error.message : undefined;

			return dispatch(createMessage({
				type: 'error',
				title: title, // NOTE: Form error
				body: body // NOTE: Form error.response
			}));
		} catch (e) {
			console.error(e);
		}
	}

	if (typeof error === 'object') {
		const title = error.title || error.error;
		const body = error.body || error.message || error.error || Object.keys(error)[0];
		return dispatch(createMessage({ type: 'error', title, body }));
	}
};
