if (typeof require === 'undefined') {
  Components.utils.import('resource://requireloc/dotsRequireModule.js');
  var module = {
    exports: Object.create(null)
  };
  Object.defineProperty(this, 'exports', {
    get: function get() {
      return module.exports;
    }
  });
  var EXPORTED_SYMBOLS = ['module'];
}

module.exports = {
  CLUSTER: 'cluster',
  CUSTOMER_ACCOUNTS: 'customerAccounts',
  DELIVERY_TYPE: 'deliveryType',
  HOTFOLDER: 'hotfolder',
  PAPER_SIZE: 'paperSize',
  PAPER_CATALOG: 'paperCatalog',
  // Also known as Paper-Types
  PAPER_CATEGORY: 'paperCategory',
  PRINTER: 'printer',
  PRINTER_SETTING: 'PrinterSetting',
  PRODUCT: 'product',
  PRODUCT_CATEGORY: 'productCategory',
  PUBLIC_API_USER: 'publicApiUser',
  REPORT: 'report',
  ROLE: 'role',
  ROLL_JOB_TEMPLATE: 'rollJobTemplate',
  SIGNATURE: 'signature',
  SERVICE: 'service',
  SERVICE_GROUP: 'serviceGroup',
  USER: 'user',
  VIRTUAL_PRINTER: 'virtualPrinter'
};