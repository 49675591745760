import { createSelector } from 'reselect';
import { getPortalName } from '../utils/url';

export const authStatusSelector = state => state.auth.authStatus;

export const userSessionSelector = state => state.auth.userSession;

export const isAuthenticatedSelector = state => !!state.auth.userSession.user && !!state.auth.userSession.sessionId;

export const portalMatchesSessionSelector = createSelector(
	[state => state.app.portal, state => state.auth.userSession],
	(portal, userSession) => {
		const portalName = getPortalName();
		return portal && userSession && portalName === userSession.currentPortalName;
	}
);
