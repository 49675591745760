if (typeof require === 'undefined') {
  Components.utils.import('resource://requireloc/dotsRequireModule.js');
  var module = {
    exports: Object.create(null)
  };
  Object.defineProperty(this, 'exports', {
    get: function get() {
      return module.exports;
    }
  });
  var EXPORTED_SYMBOLS = ['module'];
}

module.exports = {
  MILLIMETER: 'mm',
  CENTIMETER: 'cm',
  METER: 'm',
  INCH: 'in',
  FEET: 'ft',
  POSTCRIPT_POINTS: 'pt'
};