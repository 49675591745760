if (typeof require === 'undefined') {
  Components.utils.import('resource://requireloc/dotsRequireModule.js');
  var module = {
    exports: Object.create(null)
  };
  Object.defineProperty(this, 'exports', {
    get: function get() {
      return module.exports;
    }
  });
  var EXPORTED_SYMBOLS = ['module'];
}

module.exports = {
  WORKSTATION: 'WORKSTATION',
  PRINTER: 'PRINTER',
  EDITOR: 'EDITOR',
  IMPORT: 'IMPORT',
  LABEL_EDITOR: 'LABEL_EDITOR',
  RASTER_EDITOR: 'RASTER_EDITOR'
};