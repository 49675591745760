import React from 'react';
import PropTypes from 'prop-types';

import { NavLink } from 'react-router-dom';

const Breadcrumb = ({ children, isActive, className, activeClassName }) => {

	// if children is string
	if (!React.isValidElement(children)) {
		return (
			<span className={isActive ? activeClassName : className}>
				{children}
			</span>
		);
	}

	// if children is NavLink
	if (children.type === NavLink) return React.cloneElement(children, { className, activeClassName });

	// Other Element
	return (
		isActive
			? <span className={activeClassName}>{children.props.children}</span> // Disabled link
			: React.cloneElement(children, { className })
	);
};

Breadcrumb.propTypes = {
	children: PropTypes.node,
	isActive: PropTypes.bool,
	className: PropTypes.string,
	activeClassName: PropTypes.string,
};

Breadcrumb.defaultProps = {
	children: null,
	isActive: false,
	className: 'dots-u-breadcrumblink',
	activeClassName: 'dots-u-breadcrumblink dots-u-breadcrumblink--isActive', // only works on react-router NavLink
};

export default Breadcrumb;
