import React from 'react';
import PropTypes from 'prop-types';

import ModalContainer from './modal-container';
import ModalWrapper from './modal-wrapper';
import ModalFooter from './modal-footer';

const Modal = ({
	autoFocus,
	children,
	className,
	focusTrap,
	isChanged,
	isOpen,
	disableClickBeside,
	maxHeight,
	onCancel,
	onConfirm,
	onOpen,
	type,
}) => {
	if (!isOpen) return null;
	const hasFooter = !!React.Children.toArray(children).find(child => child.type === ModalFooter);
	return (
		<ModalWrapper
			autoFocus={autoFocus}
			onCancel={onCancel}
			onConfirm={onConfirm}
			onOpen={onOpen}
			isOpen={isOpen}
			isChanged={isChanged}
		>
			<ModalContainer
				className={className}
				focusTrap={focusTrap}
				isModalOpen={isOpen}
				maxHeight={maxHeight}
				onCancel={disableClickBeside ? undefined : onCancel}
				type={type}
			>
				{ React.Children.map(children,
					(child) => React.cloneElement(child,{
						onCancel: child.props.onCancel ? child.props.onCancel : onCancel,
						onConfirm,
						type,
						hasFooter,
					})
				)}
			</ModalContainer>
		</ModalWrapper>
	);
};

Modal.propTypes = {
	autoFocus: PropTypes.bool,
	children: PropTypes.node,
	className: PropTypes.string,
	disableClickBeside: PropTypes.bool,
	focusTrap: PropTypes.bool,
	isChanged: PropTypes.bool,
	isOpen: PropTypes.bool,
	maxHeight: PropTypes.number,
	onCancel: PropTypes.func,
	onConfirm: PropTypes.func,
	// Full-screen has no space on the left and right margins
	type: PropTypes.oneOf(['dialog', 'full', 'full-screen', 'transparent', 'medium', 'small', 'large']),
};

Modal.defaultProps = {
	autoFocus: true,
	children: null,
	className: null,
	disableClickBeside: false,
	focusTrap: true,
	isChanged: false,
	isOpen: false,
	onCancel: undefined,
	onConfirm: () => {},
	type: 'dialog',
};
export default Modal;
