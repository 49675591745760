import { combineReducers } from 'redux';
import * as actionTypes from '../enums/action-types';

const isCookieManagerModalOpen = (state = false, action) => {
	switch (action.type) {
		case actionTypes.OPEN_COOKIE_MANAGER_MODAL:
			return true;
		case actionTypes.CLOSE_COOKIE_MANAGER_MODAL:
		case actionTypes.CLOSE_MODALS:
			return false;
		default:
			return state;
		}
};

const isSSOSettingsModalOpen = (state = false, action) => {
	switch (action.type) {
	case actionTypes.OPEN_SSO_SETTINGS_MODAL:
		return true;
	case actionTypes.CLOSE_SSO_SETTINGS_MODAL:
	case actionTypes.CLOSE_MODALS:
		return false;
	default:
		return state;
	}
};

const isSSOAttributeMappingModalOpen = (state = false, action) => {
	switch (action.type) {
	case actionTypes.OPEN_SSO_ATTRIBUTE_MAPPING_MODAL:
		return true;
	case actionTypes.CLOSE_SSO_ATTRIBUTE_MAPPING_MODAL:
	case actionTypes.CLOSE_MODALS:
		return false;
	default:
		return state;
	}
};

export default combineReducers({
	isCookieManagerModalOpen,
	isSSOSettingsModalOpen,
	isSSOAttributeMappingModalOpen
});

