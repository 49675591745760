import * as app from './app';
import * as auth from './auth';
import * as config from './config';
import * as portalConfig from './portal-config';
import i18n from './i18n';

export default {
	app,
	auth,
	i18n,
	config,
	portalConfig,
};
