if (typeof require === 'undefined') {
  Components.utils.import('resource://requireloc/dotsRequireModule.js');
  var module = {
    exports: Object.create(null)
  };
  Object.defineProperty(this, 'exports', {
    get: function get() {
      return module.exports;
    }
  });
  var EXPORTED_SYMBOLS = ['module'];
}

module.exports = {
  DOOR_OPEN: 'printer.error.doorOpen',
  JAMMED: 'printer.error.jammed',
  INPUT_TRAY_EMPTY: 'printer.error.inputTrayEmpty',
  LOW_PAPER: 'printer.error.lowPaper',
  LOW_TONER: 'printer.error.lowToner',
  NO_PAPER: 'printer.error.noPaper',
  OUTPUT_FULL: 'printer.error.outputFull',
  MAINTENANCE_OVERDUE: 'printer.error.maintenanceOverdue',
  SERVICE_REQUESTED: 'printer.error.serviceRequested',
  LOW_POWER: 'printer.alert.lowPower',
  CALIBRATING: 'printer.alert.calibrating'
};