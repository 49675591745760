/* eslint-disable no-console */
import React from 'react';
import ErrorStackParser from 'error-stack-parser';
import Button from 'dots-react-ui/src/components/button/button';
import Subheading from 'dots-react-ui/src/components/text/subheading';
import withI18n from 'dots-react-ui/src/hoc/with-i18n';
import * as clientErrorApis from 'dots-frontend/src/apis/client-error';

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			error: undefined,
			errorStack: [],
			info: undefined,
		};
	}

	static getDerivedStateFromError(error) {
		console.log(error);
		return {
			error,
			info: undefined,
		};
	}

	async componentDidCatch(error, info) {
		const parsedStack = ErrorStackParser.parse(error);
		try {
			const errorStack = await clientErrorApis.sendErrorStack(error.message, parsedStack);
			console.log(errorStack);
			if (errorStack && errorStack.length) {
				this.setState({ errorStack });
			}
		} catch (err) {
			console.error(err);
		}

		this.setState({
			error,
			info,
		});

		if (this.props.onError) {
			this.props.onError(error);
		}
	}

	render() {
		const { error, errorStack } = this.state;
		const { children, i18n } = this.props;

		if (error) {
			return (
				<div>
					<Subheading type="h2">{i18n.translate('callas.errorcode.1000.unknown')}</Subheading>
					<details>
						{error && error.toString()}
						<br />
						{(errorStack && !!errorStack.length) &&
							errorStack.slice(0, 5).map((err, idx) => (
								err && (<p key={idx}>at {err.name} ({err.source}:{err.line}:{err.column})</p>
						)))}
					</details>
					<Button onClick={() => window.location.reload(true)}>
						{i18n.translate('global.reload')}
					</Button>
				</div>
			);
		}

		return children;
	}

}

export default withI18n(ErrorBoundary);
