if (typeof require === 'undefined') {
  Components.utils.import('resource://requireloc/dotsRequireModule.js');
  var module = {
    exports: Object.create(null)
  };
  Object.defineProperty(this, 'exports', {
    get: function get() {
      return module.exports;
    }
  });
  var EXPORTED_SYMBOLS = ['module'];
}

var AuthnTypeOptions = {
  SAML: 'SAML',
  IWA: 'IWA'
};
var DashboardContentAreas = {
  HEADER: 'header',
  INFO_BOX: 'info-box',
  PRODUCT_CATEGORY: 'productCategory',
  FURTHER_INFORMATION: 'furtherInformation'
};
var LoginContentAreas = {
  HERO_SLIDER: 'hero-slider',
  PRODUCT_VIEW: 'product-view',
  INFO_CONTAINER: 'info-container',
  VIDEO: 'video'
};
var LoginInformationColumnTypes = {
  TEXT: 'text',
  IMAGE: 'image'
};
var SSOTypes = {
  OFF: 'OFF',
  ON: 'ON',
  ONLYSSO: 'ONLYSSO'
};
var HeaderModes = {
  BOTH: 'BOTH',
  LOGOONLY: 'LOGOONLY',
  TEXTONLY: 'TEXTONLY'
};
var BackgroundPosition = {
  BOTTOM: 'bottom',
  CENTER: 'center',
  TOP: 'top'
};
module.exports = {
  AuthnTypeOptions,
  BackgroundPosition,
  DashboardContentAreas,
  HeaderModes,
  LoginContentAreas,
  LoginInformationColumnTypes,
  SSOTypes
};