/**
 * @module guid generates a timestamp-based UUID
 */
var uuid = require('uuid');

/**
 * @description generate and return a RFC4122 v4 UUID.
 */
module.exports = function guid() {
	return '{' + uuid.v4() + '}';
}.bind(null);