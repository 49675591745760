if (typeof require === 'undefined') {
  Components.utils.import('resource://requireloc/dotsRequireModule.js');
  var module = {
    exports: Object.create(null)
  };
  Object.defineProperty(this, 'exports', {
    get: function get() {
      return module.exports;
    }
  });
  var EXPORTED_SYMBOLS = ['module'];
}

module.exports = {
  PRINTER__STOP_STAPLING_NOT_SUPPORTED: '[#printerAlert.msg.stopStaplingNotSupported]',
  PRINTER__TAB_SHEET_PUNCHING_NOT_SUPPORTED: '[#printerAlert.msg.tabSheetPunchingNotSupported]',
  PRINTER__SUBSET_FINISHING_NOT_SUPPORTED: '[#printerAlert.msg.subsetFinishingNotSupported]',
  PRINTER__PI_TRAY_SWITCH_NOT_SUPPORTED: '[#printerAlert.msg.PItraySwitchNotSupported]',
  PRINTER__TOO_MANY_SLIP_SHEETS: '[#printerAlert.msg.toManySlipSheets]',
  PRINTER__NO_SUPPORT_FOR_MIXED_COLOR_SETTINGS: '[#printerAlert.msg.noSupportForMixedColorSettings]',
  PRINTER__GRAYSCALE_AND_APPE_NOT_SUPPORTED: '[#printerAlert.msg.noSupportForGrayscaleAndAPPE]',
  CLUSTER__STAPLING_NOT_SUPPORTED: '[#printerCluster.staplingNotSupported]',
  CLUSTER__SLIP_AND_TABS_NOT_SUPPORTED: '[#printerCluster.sheetTypeNotSupported_1] [#global.slipSheet], [#global.tabSheet]',
  CLUSTER__TABS_NOT_SUPPORTED: '[#printerCluster.sheetTypeNotSupported_1] [#global.tabSheet]',
  CLUSTER__SLIP_NOT_SUPORTED: '[#printerCluster.sheetTypeNotSupported_1] [#global.slipSheet]',
  CLUSTER__COLOR_SPLIT_EMPTY_FIRST_JOB: '[#printerCluster.colorSplitEmptyFirstJob]',
  CLUSTER__COLOR_SPLIT_EMPTY_FINISHING_JOB: '[#printerClusterType.color][#global.charColon] [#printerCluster.colorSplitEmptyFinishingJob]'
};