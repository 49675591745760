if (typeof require === 'undefined') {
  Components.utils.import('resource://requireloc/dotsRequireModule.js');
  var module = {
    exports: Object.create(null)
  };
  Object.defineProperty(this, 'exports', {
    get: function get() {
      return module.exports;
    }
  });
  var EXPORTED_SYMBOLS = ['module'];
}

exports.ValidationResult = {
  FAILED: 'FAILED',
  INVALID: 'INVALID',
  VALID: 'VALID'
};
exports.ServiceNames = {
  ROOT: 'CMI:ROOT',
  PROFILE_SELECTION_RGB: 'CMI:SELECTION_RGB_PROFILE',
  PROFILE_SELECTION_CMYK: 'CMI:SELECTION_CMYK_PROFILE',
  CMYK_TARGET_PROFILE: 'CMI:CMYK_PROFILE_TARGET',
  PRINTER_PROFILE: 'CMI:PRINTER_PROFILE',
  PAPER_TYPE_LINK: 'CMI:PRINTER_PROFILE_PAPERTYPELINK',
  GROUP_SETTINGS: 'CMI:GROUP_SETTINGS',
  USE_CONTROLLER_OPTIONAL_SETTINGS: 'CMI:USE_CONTROLLER_OPTIONAL_SETTINGS',
  COLOR_CONFIGURATION: 'CMI:COLOR_CONFIGURATION'
};
exports.ServiceOptions = {
  ROOT: {
    OFF: 'OFF',
    PRINTER_DEFAULTS: 'PRINTER_DEFAULTS',
    CONTROLLER: 'CONTROLLER',
    MANUAL: 'MANUAL'
  },
  PROFILE_SELECTION: {
    OFF: 'OFF',
    DEVICE_LINK: 'DEVICE_LINK',
    PROFILE: 'PROFILE'
  }
};