import { combineReducers } from 'redux';
import * as actionTypes from '../enums/action-types';
import dialogs from 'dots-frontend/src/reducers/app/dialogs';
import errorRequestDialogs from 'dots-frontend/src/reducers/app/error-request-dialogs';
import loaders from 'dots-frontend/src/reducers/app//loaders';
import messages from 'dots-frontend/src/reducers/app//messages';

const appHasLoaded = (state = false, action) => {
	switch (action.type) {
	case actionTypes.SET_APP_HAS_LOADED:
		return true;
	default:
		return state;
	}
};

const authStatus = (state = null, action) => {
	switch (action.type) {
	case actionTypes.SET_AUTH_STATUS:
		return action.authStatus;
	default:
		return state;
	}
};

const CSRFToken = (state = null, action) => {
	switch (action.type) {
	case actionTypes.SET_CSRF_TOKEN:
		return action.CSRFToken;
	default:
		return state;
	}
};

const i18nStrings = (state = null, action) => {
	switch (action.type) {
	case actionTypes.SET_I18N_STRINGS:
		return action.i18nStrings;
	default:
		return state;
	}
};

const license = (state = {}, action) => {
	switch(action.type) {
		case actionTypes.SET_LICENSE:
			return action.license;
		default:
			return state;
	}
};

// Should be null to not flash language???
const localeId = (state = 'en-US', action) => {
	switch (action.type) {
	case actionTypes.SET_LOCALE_ID:
		return action.localeId;
	default:
		return state;
	}
};

const portal = (state = null, action) => {
	switch (action.type) {
	case actionTypes.SET_PORTAL:
		return action.portal;
	default:
		return state;
	}
};


export default combineReducers({
	CSRFToken,
	appHasLoaded,
	authStatus,
	dialogs,
	errorRequestDialogs,
	i18nStrings,
	license,
	loaders,
	localeId,
	messages,
	portal,
});

