if (typeof require === 'undefined') {
  Components.utils.import('resource://requireloc/dotsRequireModule.js');
  var module = {
    exports: Object.create(null)
  };
  Object.defineProperty(this, 'exports', {
    get: function get() {
      return module.exports;
    }
  });
  var EXPORTED_SYMBOLS = ['module'];
}

module.exports = {
  MONITORING: 'monitoring',
  HOTFOLDERS: 'hotfolders',
  AUTOPRINT: 'autoprint',
  MOVE_PRINTED_JOBS: 'move-printed-jobs',
  EMAIL_SERVER_CONFIG: 'email-server-config',
  NETWORK_PRINTER: 'network-printer',
  WEB_SYNC: 'web-sync',
  PREFLIGHT_CONFIG: 'preflight-config'
};