if (typeof require === 'undefined') {
  Components.utils.import('resource://requireloc/dotsRequireModule.js');
  var module = {
    exports: Object.create(null)
  };
  Object.defineProperty(this, 'exports', {
    get: function get() {
      return module.exports;
    }
  });
  var EXPORTED_SYMBOLS = ['module'];
}

exports.PriceType = {
  COPY: 'priceCopy',
  VAT_EXTRA: 'priceVatExtra',
  DELIVERY: 'priceDelivery',
  SUM: 'priceSum',
  SUB_TOTAL: 'priceSubTotal',
  TOTAL_NET: 'priceTotalNet',
  TOTAL: 'priceTotal'
};
exports.CurrencyFormat = {
  LEFT: 'left',
  LEFT_SPACE: 'leftSpace',
  RIGHT: 'right',
  RIGHT_SPACE: 'rightSpace'
};