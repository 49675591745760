import React from 'react';
import { CookiesProvider } from 'react-cookie';
import I18nContext from 'dots-react-ui/src/contexts/i18n/i18n-context';
import ModalCountProvider from 'dots-react-ui/src/components/modal-count/modal-count-provider';
import MessageProvider from 'dots-react-ui/src/contexts/message/message-provider';
import DialogContainer from 'dots-react-components/src/containers/dialog';
import ErrorRequestDialogContainer from 'dots-react-components/src/containers/error-request-dialog';
import MessageContainer from 'dots-react-components/src/containers/message';
import LoaderContainer from 'dots-react-components/src/containers/loader';
import ThemeProvider from './components/general-purpose/theme-components/theme-provider';
import ErrorBoundary from './components/general-purpose/error-boundary';

const FluxWebProviders = ({ i18n, children }) => {
	return (
		<I18nContext.Provider value={{ i18n }}>
			<ErrorBoundary>
				<CookiesProvider>
					<ModalCountProvider>
						<ThemeProvider>
							<MessageProvider>
								{children}
								<DialogContainer />
								<ErrorRequestDialogContainer />
								<MessageContainer />
								<LoaderContainer />
							</MessageProvider>
						</ThemeProvider>
					</ModalCountProvider>
				</CookiesProvider>
			</ErrorBoundary>
		</I18nContext.Provider>
	);
};

export default FluxWebProviders;
