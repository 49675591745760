import React from 'react';
import I18nContext from '../contexts/i18n/i18n-context';

const withI18n = WrappedComponent => {
	const ComponentWithI18n = ({ ...props }) => (
		<I18nContext.Consumer>
			{({ i18n }) => <WrappedComponent i18n={i18n} {...props} />}
		</I18nContext.Consumer>
	);
	ComponentWithI18n.displayName = `withI18n(${WrappedComponent.displayName || WrappedComponent.name})`;
	return ComponentWithI18n;
};

export default withI18n;
