import React, { Children } from 'react';
import PropTypes from 'prop-types';

const BreadCrumbs = ({ children, id }) => (
	<nav className="mb-1" role="navigation">
		<p className="u-visuallyhidden" id={id} />
		<ol aria-labelledby={id} className="list-reset">
			{Children.map(children, (child, index) => (
				<li key={`${id}_${index}`} className="inline-block">
					{React.cloneElement(child, { isActive: (index+1 >= Children.count(children)) })}
				</li>
			))}
		</ol>
	</nav>
);

BreadCrumbs.propTypes = {
	children: PropTypes.node,
	id: PropTypes.string.isRequired,
};

BreadCrumbs.defaultProps = {
	children: null,
};

export default BreadCrumbs;
