if (typeof require === 'undefined') {
  Components.utils.import('resource://requireloc/dotsRequireModule.js');
  var module = {
    exports: Object.create(null)
  };
  Object.defineProperty(this, 'exports', {
    get: function get() {
      return module.exports;
    }
  });
  var EXPORTED_SYMBOLS = ['module'];
} //print all sheets or sheet range or sheets for specific printer for color split


module.exports = {
  ALL_SHEETS: "allSheets",
  RANGE: "range",
  FINISHING_PRINTER: 'FINISHING_PRINTER',
  //same enum as in color-split-printer
  FIRST_PRINTER: 'FIRST_PRINTER' //same enum as in color-split-printer

};