if (typeof require === 'undefined') {
  Components.utils.import('resource://requireloc/dotsRequireModule.js');
  var module = {
    exports: Object.create(null)
  };
  Object.defineProperty(this, 'exports', {
    get: function get() {
      return module.exports;
    }
  });
  var EXPORTED_SYMBOLS = ['module'];
}

exports.init = function (ServiceNames) {
  exports.PageListColumnType = {
    RANGE_HEAD: 'RANGE_HEAD',
    NAME: 'NAME',
    SHEET: 'SHEET',
    SOURCE: 'SOURCE',
    SOURCE_PAGE_NUMBER: 'SOURCE_PAGE_NUMBER',
    ORIGINAL_PAGESIZE: 'ORIGINAL_PAGESIZE',
    PAGE_SIZE: 'PAGE_SIZE',
    ORIGINAL_PAGE_COLOR: 'ORIGINAL_PAGE_COLOR',
    PAGE_COLOR: 'PAGE_COLOR',
    SHEET_COLOR: 'SHEET_COLOR',
    [ServiceNames.DUPLEX]: ServiceNames.DUPLEX,
    [ServiceNames.PAPERTYPE]: ServiceNames.PAPERTYPE,
    [ServiceNames.STAPLE]: ServiceNames.STAPLE,
    [ServiceNames.PUNCH]: ServiceNames.PUNCH
  };
};

exports.ALL_SETTINGS = 'allSettings';
exports.SettingsPanelType = {
  JOB_SETTINGS: 'jobSettings',
  LAYOUT: 'layout',
  TAB_SETTINGS: 'TAB_SETTINGS',
  FINISHING: 'FINISHING',
  PRINT_SETTINGS: 'PRINT_SETTINGS',
  CROP_MOVE_ROTATE: 'cropMoveRotate',
  PAGE_LABEL_SETTINGS_EDITOR: 'PAGE_LABEL_SETTINGS_EDITOR',
  PREFLIGHT: 'PREFLIGHT',
  REGISTER_MARKS: 'REGISTER_MARKS',
  BARCODES: 'BARCODES',
  CUT_MARKS: 'CUT_MARKS',
  SHEET_INFOS: 'SHEET_INFOS',
  COLOR_LAYERS: 'COLOR_LAYERS',
  EXPORT: 'EXPORT'
};
exports.LayoutGroupOrderTypes = {
  SERVICE_GROUP: 'SERVICE_GROUP',
  CUSTOM_SERVICE_GROUPS: 'CUSTOM_SERVICE_GROUPS',
  OUTPUT_FORMAT: 'OUTPUT_FORMAT',
  LAYOUT_POSITION: 'LAYOUT_POSITION',
  TAB_SHEET_SETTINGS: 'TAB_SHEET_SETTINGS',
  BLEED_TAB_SETTINGS: 'BLEED_TAB_SETTINGS',
  PRINTER_SETTINGS: 'PRINTER_SETTINGS'
};
exports.EditorDialogType = {
  INITIAL_PREFLIGHT_SELECTION: 'INITIAL_PREFLIGHT_SELECTION',
  PREFLIGHT_RESULT: 'PREFLIGHT_RESULT',
  INSERT_DOCUMENTS: 'INSERT_DOCUMENTS',
  INSERT_TAB_SHEETS: 'INSERT_TAB_SHEETS',
  INSERT_EMPTY_PAGES: 'INSERT_EMPTY_PAGES',
  INSERT_SLIP_SHEETS: 'INSERT_SLIP_SHEETS',
  REPLACE_SOURCE_FILE: 'REPLACE_SOURCE_FILE',
  EDIT_SOURCE_FILE_EXTERNAL: 'EDIT_SOURCE_FILE_EXTERNAL',
  SELECT_PAGE_RANGE: 'SELECT_PAGE_RANGE',
  SELECT_CHAPTER_RANGE: 'SELECT_CHAPTER_RANGE',
  DUPLICATE_RANGE: 'DUPLICATE_RANGE',
  CREATE_PRODUCT: 'CREATE_PRODUCT',
  EDIT_PAPER_CATALOG: 'EDIT_PAPER_CATALOG',
  SIGNATURE_EDITOR: 'SIGNATURE_EDITOR',
  ADD_PAPER_SIZE: 'ADD_PAPER_SIZE',
  REPEAT_RANGE_PROGRAMMING: 'REPEAT_RANGE_PROGRAMMING',
  RANGE_PRINT: 'RANGE_PRINT',
  DOWNLOAD_FILES: 'DOWNLOAD_FILES',
  JOB_TICKET: 'JOB_TICKET',
  RASTER_EDITOR_OPEN: 'RASTER_EDITOR_OPEN',
  RASTER_EDITOR_NOT_ALLOWED: 'RASTER_EDITOR_NOT_ALLOWED',
  USER_NOT_AUTHENTICATED: 'USER_NOT_AUTHENTICATED',
  USER_NOT_AUTHORIZED: 'USER_NOT_AUTHORIZED',
  CREATE_CHAPTER: 'CREATE_CHAPTER',
  CONNECTION_ERROR: 'CONNECTION_ERROR',
  INSERT_BLEED_TABS: 'INSERT_BLEED_TABS',
  PREFERENCES: 'PREFERENCES',
  ADD_ARTWORKS: 'ADD_ARTWORKS',
  ROLLJOB_TEMPLATE_EDITOR: "ROLLJOB_TEMPLATE_EDITOR",
  SAVE_LAYOUT_AS_TEMLATE: "SAVE_LAYOUT_AS_TEMLATE"
};
exports.EditorErrorType = {
  UNHANDLED_ERROR: 'UNHANDLED_ERROR',
  INVALID_JOB_TITLE: 'INVALID_JOB_TITLE',
  INVALID_DELIVERY_DATE: 'INVALID_DELIVERY_DATE',
  ORDER_ITEM_NOT_FOUND: 'ORDER_ITEM_NOT_FOUND',
  DOCUMENT_ERROR: 'DOCUMENT_ERROR',
  PREFLIGHT_ERROR: 'PREFLIGHT_ERROR',
  SAVE_PRODUCT_MIXED_OUTPUT_FORMAT_SETTINGS: 'SAVE_PRODUCT_MIXED_OUTPUT_FORMAT_SETTINGS',
  CROP_TOO_WIDE: 'CROP_TOO_WIDE',
  GENERAL_ERROR: 'GENERAL_ERROR',
  SHEET_TYPES_NOT_SUPPORTED_BY_CLUSTER: 'SHEET_TYPES_NOT_SUPPORTED_BY_CLUSTER',
  SLIP_OR_TAB_SHEET_NOT_SUPPORTED_BY_IMPOSE_MODE: 'SLIP_OR_TAB_SHEET_NOT_SUPPORTED_BY_IMPOSE_MODE',
  SLIP_OR_TAB_SHEET_NOT_SUPPORTED_BY_BOOKLET_GROUPING: 'SLIP_OR_TAB_SHEET_NOT_SUPPORTED_BY_BOOKLET_GROUPING',
  COVER_CANNOT_BE_CONVERTED_TO_TAB_SHEET: 'COVER_CANNOT_BE_CONVERTED_TO_TAB_SHEET'
}; //TODO: move this to somewhere else, its not editor specific

exports.ColorLayers = {
  PRINT: 'PRINT',
  DIE_LINE: 'DIE_LINE',
  FINISHING: 'FINISHING'
};