if (typeof require === 'undefined') {
  Components.utils.import('resource://requireloc/dotsRequireModule.js');
  var module = {
    exports: Object.create(null)
  };
  Object.defineProperty(this, 'exports', {
    get: function get() {
      return module.exports;
    }
  });
  var EXPORTED_SYMBOLS = ['module'];
}

exports.PreviewType = {
  MULTI_PAGE_PREVIEW: 'MULTI_PAGE_PREVIEW',
  SINGLE_PAGE_PREVIEW: 'SINGLE_PAGE_PREVIEW',
  MULTI_SHEET_PREVIEW: 'MULTI_SHEET_PREVIEW',
  SINGLE_SHEET_PREVIEW: 'SINGLE_SHEET_PREVIEW',
  PAGE_LIST_VIEW: 'PAGE_LIST_VIEW',
  SINGLE_CROP_MOVE_ROTATE_PREVIEW: 'SINGLE_CROP_MOVE_ROTATE_PREVIEW',
  DOCUMENT_LIST_VIEW: 'DOCUMENT_LIST_VIEW',
  PAGE_THUMBNAIL_LIST: 'PAGE_THUMBNAIL_LIST',
  SHEET_THUMBNAIL_LIST: 'SHEET_THUMBNAIL_LIST',
  STRUCTURE_LIST: 'STRUCTURE_LIST',
  ARTWORK_THUMBNAIL_LIST: 'ARTWORK_THUMBNAIL_LIST',
  ROLL_JOB_SHEET_PREVIEW: 'ROLL_JOB_SHEET_PREVIEW',
  ROLL_JOB_LABEL_SETTINGS_PREVIEW: 'ROLL_JOB_LABEL_SETTINGS_PREVIEW'
};
exports.ZoomMapTypes = {
  SINGLE_PAGE_PREVIEW: 'SINGLE_PAGE_PREVIEW',
  MULTI_PAGE_PREVIEW: 'MULTI_PAGE_PREVIEW',
  MULTI_SHEET_PREVIEW: 'MULTI_SHEET_PREVIEW',
  CROP_MOVE_ROTATE: 'CROP_MOVE_ROTATE',
  PAGE_LABEL_EDITOR: 'PAGE_LABEL_EDITOR',
  ROLL_JOB_SHEET_PREVIEW: 'ROLL_JOB_SHEET_PREVIEW',
  ROLL_JOB_LABEL_SETTINGS_PREVIEW: 'ROLL_JOB_LABEL_SETTINGS_PREVIEW'
};