import * as actionTypes from '../enums/action-types';
import * as settingsApis from '../apis/settings';

export const setAllowedFileTypes = allowedFileTypes => ({ type: actionTypes.SET_ALLOWED_FILE_TYPES, allowedFileTypes });

export const setSystemSettings = systemSettings => ({ type: actionTypes.SET_SYSTEM_SETTINGS, systemSettings });

export const setDefaultSystemSettings = defaultSystemSettings => ({ type: actionTypes.SET_DEFAULT_SYSTEM_SETTINGS, defaultSystemSettings });

export const getSystemSettings = () => async(dispatch) => {
	const systemSettings = await settingsApis.getSystemSettings();
	dispatch(setSystemSettings(systemSettings));
};

export const getDefaultSystemSettings = () => async(dispatch) => {
	const defaultSystemSettings = await settingsApis.getDefaultSystemSettings();
	dispatch(setDefaultSystemSettings(defaultSystemSettings));
};

export const getAllowedFileTypes = () => async(dispatch) => {
	const defaultSystemSettings = await settingsApis.getAllowedFileTypes();
	dispatch(setAllowedFileTypes(defaultSystemSettings));
}