if (typeof require === 'undefined') {
  Components.utils.import('resource://requireloc/dotsRequireModule.js');
  var module = {
    exports: Object.create(null)
  };
  Object.defineProperty(this, 'exports', {
    get: function get() {
      return module.exports;
    }
  });
  var EXPORTED_SYMBOLS = ['module'];
}

module.exports = {
  NOT_FOUND: 'NOT_FOUND',
  IN_USE: 'IN_USE',
  CONFLICT: 'CONFLICT',
  PRINTER_NOT_FOUND: 'PRINTER_NOT_FOUND',
  LAST_ITEM: 'LAST_ITEM'
};