if (typeof require === 'undefined') {
  Components.utils.import('resource://requireloc/dotsRequireModule.js');
  var module = {
    exports: Object.create(null)
  };
  Object.defineProperty(this, 'exports', {
    get: function get() {
      return module.exports;
    }
  });
  var EXPORTED_SYMBOLS = ['module'];
} //which sheets:


module.exports = {
  //for simplex jobs   
  EVEN_AND_ODD: "evenAndOdd",
  ODD: "odd",
  EVEN: "even",
  //for duplex jobs
  FRONT_AND_BACK: "frontAndBack",
  FRONTSIDE: 'frontSide',
  BACKSIDE: 'backSide'
};