import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { icons } from 'dots-design/images';
import { COLORS } from '../../constants';

const iconClassName = (color, className, fill) => classNames(
	'dots-c-svgicon',
	{
		// 'fill-current': color === 'inherit',
		'fill-current': !fill,
		[`text-${color}`]: COLORS.includes(color),
	},
	className,
);

const Icon = ({
	className,
	color,
	fill,
	height,
	id,
	label,
	name,
	onClick,
	onContextMenu,
	onMouseDown,
	onMouseEnter,
	onMouseLeave,
	setRef,
	size,
	stroke,
	style,
	title,
	width,
}) => {
	const SvgIcon = icons[name];

	if (!SvgIcon || typeof SvgIcon !== 'function') return null;

	return (
		<SvgIcon
			name={name}
			aria-hidden="true"
			aria-label={label}
			className={iconClassName(color, className, fill)}
			fill={fill}
			id={id}
			stroke={stroke}
			onClick={onClick}
			onMouseDown={onMouseDown}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			onContextMenu={onContextMenu}
			height={height || size}
			title={title || label}
			role="img"
			width={width || size}
			preserveAspectRatio={(height || width) ? 'none' : 'xMidYMid meet'}
			shapeRendering="geometricPrecision"
			style={style}
			ref={setRef}
		/>
	);
};

Icon.propTypes = {
	className: PropTypes.string,
	color: PropTypes.string,
	fill: PropTypes.string,
	height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	label: PropTypes.string,
	name: PropTypes.string,
	onClick: PropTypes.func,
	onContextMenu: PropTypes.func,
	onMouseDown: PropTypes.func,
	onMouseEnter: PropTypes.func,
	onMouseLeave: PropTypes.func,
	setRef: PropTypes.func,
	size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	stroke: PropTypes.string,
	style: PropTypes.object,
	title: PropTypes.string,
	width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Icon.defaultProps = {
	className: '',
	color: 'gray-darkest',
	fill: undefined,
	height: undefined,
	label: undefined,
	name: undefined,
	onClick: () => {},
	onContextMenu: () => {},
	onMouseDown: () => {},
	onMouseEnter: () => {},
	onMouseLeave: () => {},
	setRef: undefined,
	size: 24,
	stroke: 'none',
	style: undefined,
	title: undefined,
	width: undefined,
};

export default Icon;
