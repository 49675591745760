if (typeof require === 'undefined') {
  Components.utils.import('resource://requireloc/dotsRequireModule.js');
  var module = {
    exports: Object.create(null)
  };
  Object.defineProperty(this, 'exports', {
    get: function get() {
      return module.exports;
    }
  });
  var EXPORTED_SYMBOLS = ['module'];
}

module.exports = {
  NO_GROUP: "noGroup",
  PAPER: "Paper",
  FINISHING: "Finishing",
  PAGEFITTING: "Pagefitting",
  COVER: "Cover",
  DUPLEX_AND_COLOR: 'DuplexAndColor',
  PRINT_SETTINGS: 'PrintSettings' // Flux printer only, equals DUPLEX_AND_COLOR + PAPER, not in DB or service configuration

};