import React from 'react';
import { useSelector } from 'react-redux';

import Loader from 'dots-react-ui/src/components/loader/loader';
import useI18n from 'dots-react-ui/src/hooks/use-i18n';
import { loadersSelector } from 'dots-frontend/src/selectors/app';

const LoaderContainer = () => {
	const loaders = useSelector(loadersSelector);
	const block = loaders.some(loader => loader.block);
	const i18n = useI18n();

	const { text } = (loaders.find(loader => loader.text) || {});
	const translatedText = text && i18n.translate(text);

	return (
		<Loader loading={loaders.length > 0} block={block} text={translatedText} />
	);
};

export default LoaderContainer;
