// APP
export const SET_APP_HAS_LOADED = 'SET_APP_HAS_LOADED';
export const SET_EDITION = 'SET_EDITION';

export const CREATE_ERROR_REQUEST_DIALOG = 'CREATE_ERROR_REQUEST_DIALOG';
export const DELETE_ERROR_REQUEST_DIALOG = 'DELETE_ERROR_REQUEST_DIALOG';
export const CREATE_DIALOG = 'CREATE_DIALOG';
export const DELETE_DIALOG = 'DELETE_DIALOG';
export const CLEAR_DIALOGS = 'CLEAR_DIALOGS';

export const CREATE_LOADER = 'CREATE_LOADER';
export const DELETE_LOADER = 'DELETE_LOADER';
export const CLEAR_LOADERS = 'CLEAR_LOADERS';

export const CREATE_MESSAGE = 'CREATE_MESSAGE';
export const DELETE_MESSAGE = 'DELETE_MESSAGE';
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';

export const CLEAR_DATA = 'CLEAR_DATA';

// Auth
export const SET_IDENTITY = 'SET_IDENTITY';
export const SET_IS_AUTHENTICATED = 'SET_IS_AUTHENTICATED';
export const SET_IS_INITIALIZED = 'SET_IS_INITIALIZED';
export const SET_IS_SESSION_ACTIVE = 'SET_IS_SESSION_ACTIVE';
export const LOGIN = 'LOGIN';

// Add-On
export const SET_ADD_ONS = 'SET_ADD_ONS';

// Address
export const SET_ADDRESS_CONFIGS = 'SET_ADDRESS_CONFIGS';

// Cluster
export const SET_CLUSTERS = 'SET_CLUSTERS';

// Customer accounts
export const SET_CUSTOMER_ACCOUNTS = 'SET_CUSTOMER_ACCOUNTS';

// Delivery Type
export const SET_DELIVERY_TYPES = 'SET_DELIVERY_TYPES';

// Job settings
export const SET_TITLE = 'SET_TITLE';
export const SET_UNDOABLE_TITLE = 'SET_UNDOABLE_TITLE';
export const SET_PRODUCTION_NOTE = 'SET_PRODUCTION_NOTE';
export const SET_UNDOABLE_PRODUCTION_NOTE = 'SET_UNDOABLE_PRODUCTION_NOTE';
export const SET_QUANTITY = 'SET_QUANTITY';
export const SET_UNDOABLE_QUANTITY = 'SET_UNDOABLE_QUANTITY';

export const SELECT_PRINTER_OR_CLUSTER = 'SELECT_PRINTER_OR_CLUSTER'; // old SET_PRINTER_OR_CLUSTER
export const SELECT_CLUSTER = 'SELECT_CLUSTER'; // old SET_CLUSTER_SELECTION
export const SET_CLUSTER_SELECTION = 'SET_CLUSTER_SELECTION'; // is new

export const SELECT_PRODUCT = 'SELECT_PRODUCT'; // old SET_PRESET
export const SET_SELECTED_PRODUCT_TO_CUSTOM = 'SET_SELECTED_PRODUCT_TO_CUSTOM';
export const SET_SELECTED_PRODUCT = 'SET_SELECTED_PRODUCT';

// Order information
export const SET_ORDER_INFORMATION = 'SET_ORDER_INFORMATION';
export const SET_UNDOABLE_ORDER_INFORMATION = 'SET_UNDOABLE_ORDER_INFORMATION';

// Editor text fields
export const SET_PAGE_LABEL_SETTINGS_LIST = 'SET_PAGE_LABEL_SETTINGS_LIST';
export const ADD_PAGE_LABEL = 'ADD_PAGE_LABEL';
export const ADD_PAGE_LABEL_AND_SHOW_PAGE_LABEL_SETTINGS_PANEL = 'ADD_PAGE_LABEL_AND_SHOW_PAGE_LABEL_SETTINGS_PANEL';
export const REMOVE_PAGE_FROM_PAGE_LABEL_RANGE = 'REMOVE_PAGE_FROM_PAGE_LABEL_RANGE';
export const REMOVE_PAGE_LABEL = 'REMOVE_PAGE_LABEL';
export const UPDATE_PAGE_LABEL = 'UPDATE_PAGE_LABEL';
export const UPDATE_UNFINISHED_PAGE_LABEL_UPDATE = 'UPDATE_UNFINISHED_PAGE_LABEL_UPDATE';
export const FINISH_PAGE_LABEL_UPDATE = 'FINISH_PAGE_LABEL_UPDATE';
export const DUPLICATE_PAGE_LABEL = 'DUPLICATE_PAGE_LABEL';
export const MOVE_PAGE_LABEL = 'MOVE_PAGE_LABEL';
export const SELECT_PAGE_LABEL = 'SELECT_PAGE_LABEL';
export const UPDATE_UNFINISHED_PAGE_LABEL_RECT = 'UPDATE_UNFINISHED_PAGE_LABEL_RECT';
export const FINISH_PAGE_LABEL_RECT_UPDATE = 'FINISH_PAGE_LABEL_RECT_UPDATE';
export const SET_SELECTED_PAGE_LABEL_SETTINGS_IDS = 'SET_SELECTED_PAGE_LABEL_SETTINGS_IDS';
export const SET_UNFINISHED_PAGE_LABEL_SETTINGS_LIST = 'SET_UNFINISHED_PAGE_LABEL_SETTINGS_LIST';
export const SET_UNFINISHED_TEXT_FIELD_UNDO_BATCH_ID = 'SET_UNFINISHED_TEXT_FIELD_UNDO_BATCH_ID';
export const SET_UNFINISHED_TEXT_FIELD_UNDO_DATA = 'SET_UNFINISHED_TEXT_FIELD_UNDO_DATA';
export const RESET_UNFINISHED_TEXT_FIELDS = 'RESET_UNFINISHED_TEXT_FIELDS';
export const EXPAND_PAGE_LABEL_SETTINGS = 'EXPAND_PAGE_LABEL_SETTINGS';
export const SET_PAGE_LABEL_TO_CURRENT_SELECTION = 'SET_PAGE_LABEL_TO_CURRENT_SELECTION';

// Editor context menu
export const SET_SHOW_CONTEXT_MENU = 'SET_SHOW_CONTEXT_MENU';
export const HIDE_CONTEXT_MENU = 'HIDE_CONTEXT_MENU';
export const SET_CONTEXT_MENU_TYPE = 'SET_CONTEXT_MENU_TYPE';

// Editor view settings
export const SET_ZOOM_MAP = 'SET_ZOOM_MAP';
export const SET_FITTING_ZOOM_MAP = 'SET_FITTING_ZOOM_MAP';
export const SET_FITTING_WIDTH_ZOOM_MAP = 'SET_FITTING_WIDTH_ZOOM_MAP';
export const SET_ZOOM_MAP_ENTRY = 'SET_ZOOM_MAP_ENTRY';

export const SET_SELECTED_PREVIEW = 'SET_SELECTED_PREVIEW';
export const SET_SELECTED_SETTINGS_PANEL = 'SET_SELECTED_SETTINGS_PANEL';
export const SET_SHOW_SLOT_PAGE_NUMBERS_AND_BORDERS = 'SET_SHOW_SLOT_PAGE_NUMBERS_AND_BORDERS';

export const SET_EXPANDED_TEXT_FIELD_NUMBERING_OPTIONS = 'SET_EXPANDED_TEXT_FIELD_NUMBERING_OPTIONS';
export const EXPAND_TEXT_FIELD_NUMBERING_OPTIONS = 'EXPAND_TEXT_FIELD_NUMBERING_OPTIONS';
export const COLLAPSE_TEXT_FIELD_NUMBERING_OPTIONS = 'COLLAPSE_TEXT_FIELD_NUMBERING_OPTIONS';

export const COLLAPSE_JOB_INFORMATION = 'COLLAPSE_JOB_INFORMATION';
export const COLLAPSE_ORDER_INFORMATION = 'COLLAPSE_ORDER_INFORMATION';
export const COLLAPSE_ADDRESSES = 'COLLAPSE_ADDRESSES';

// Editor image cache
export const ADD_IMAGES_TO_CACHE = 'ADD_IMAGES_TO_CACHE';
export const CLEAR_IMAGE_CACHE = 'CLEAR_IMAGE_CACHE';
export const SET_PAGE_SOURCE_SPOT_COLOR_IDS = 'SET_PAGE_SOURCE_SPOT_COLOR_IDS';
export const ADD_LOADED_PAGE_SOURCE_ID = 'ADD_LOADED_PAGE_SOURCE_ID';

//printer tray assignment
export const UPDATE_PRINTER_TRAY_ASSIGNMENTS = 'UPDATE_PRINTER_TRAY_ASSIGNMENTS';
export const ADD_PRINTER_MEDIA_DIMENSION_LIMITS = 'ADD_PRINTER_MEDIA_DIMENSION_LIMITS';

//Editor soft lock
export const SET_SHOULD_DELAY_NEXT_LOCK = 'SET_SHOULD_DELAY_NEXT_LOCK';
export const SET_LOCK_ID = 'SET_LOCK_ID';
export const SET_OTHER_LOCKS = 'SET_OTHER_LOCKS';

// Email Config
export const SET_EMAIL_CONFIG = 'SET_EMAIL_CONFIG';

// Global Search
export const SET_GLOBAL_SEARCH_QUERY = 'SET_GLOBAL_SEARCH_QUERY';
export const SET_GLOBAL_SEARCH_ORDER_ITEMS = 'SET_GLOBAL_SEARCH_ORDER_ITEMS';

// Hotfolder
export const SET_HOTFOLDERS = 'SET_HOTFOLDERS';

// Job List
export const CLEAR_FOLDER_INFO = 'CLEAR_FOLDER_INFO';
export const CLEAR_EDITING_ORDER_ITEMS = 'CLEAR_EDITING_ORDER_ITEMS';
export const CLEAR_FOCUSED_ORDER_ITEM_ID = 'CLEAR_FOCUSED_ORDER_ITEM_ID';
export const CLEAR_IMPORTED_ORDER_ITEM_ID = 'CLEAR_IMPORTED_ORDER_ITEM_ID';
export const CLEAR_IMPORTED_ORDER_ITEM_IDS = 'CLEAR_IMPORTED_ORDER_ITEM_IDS';
export const CLEAR_JOB_LIST_DATA = 'CLEAR_JOB_LIST_DATA';
export const CLEAR_JOB_LIST_QUERY_CHANGED = 'CLEAR_JOB_LIST_QUERY_CHANGED';
export const CLEAR_SELECTED_ORDER_ITEMS = 'CLEAR_SELECTED_ORDER_ITEMS';
export const CLEAR_SELECTED_ORDER_ITEM_IDS = 'CLEAR_SELECTED_ORDER_ITEM_IDS';
export const CLEAR_SERVER_SELECTION_INFO = 'CLEAR_SERVER_SELECTION_INFO';
export const CLEAR_SOURCE_ORDER_ITEMS = 'CLEAR_SOURCE_ORDER_ITEMS';
export const SET_COPY_ORDER_ITEMS = 'SET_COPY_ORDER_ITEMS';
export const SET_CUT_ORDER_ITEMS = 'SET_CUT_ORDER_ITEMS';
export const SET_DELETING_ORDER_ITEM_ID = 'SET_DELETING_ORDER_ITEM_ID';
export const SET_DISPLAY_ALL_JOBS = 'SET_DISPLAY_ALL_JOBS';
export const SET_EDITING_ORDER_ITEM = 'SET_EDITING_ORDER_ITEM';
export const SET_EDITING_ORDER_ITEMS = 'SET_EDITING_ORDER_ITEMS';
export const SET_FOCUSED_ORDER_ITEM_ID = 'SET_FOCUSED_ORDER_ITEM_ID';
export const SET_FOLDER_PROPERTIES = 'SET_FOLDER_PROPERTIES';
export const SET_IMPORTED_ORDER_ITEM_ID = 'SET_IMPORTED_ORDER_ITEM_ID';
export const SET_IMPORTED_ORDER_ITEM_IDS = 'SET_IMPORTED_ORDER_ITEM_IDS';
export const SET_JOB_DETAILS_TAB_TYPE = 'SET_JOB_DETAILS_TAB_TYPE';
export const SET_JOB_LIST_FILTERS = 'SET_JOB_LIST_FILTERS';
export const SET_JOB_LIST_FOLDER_MAP = 'SET_JOB_LIST_FOLDER_MAP';
export const SET_JOB_LIST_FOLDER_TREE = 'SET_JOB_LIST_FOLDER_TREE';
export const SET_JOB_LIST_PAGE_INDEX = 'SET_JOB_LIST_PAGE_INDEX';
export const SET_JOB_LIST_PREVIEW_TYPE = 'SET_JOB_LIST_PREVIEW_TYPE';
export const SET_JOB_LIST_QUERY = 'SET_JOB_LIST_QUERY';
export const SET_JOB_LIST_QUERY_CHANGED = 'SET_JOB_LIST_QUERY_CHANGED';
export const SET_JOB_LIST_TOTAL_COUNT = 'SET_JOB_LIST_TOTAL_COUNT';
export const SET_ORDER_ITEMS_IDS_BY_FILTER = 'SET_ORDER_ITEMS_IDS_BY_FILTER';
export const SET_ORDER_ITEMS_STATISTICS = 'SET_ORDER_ITEMS_STATISTICS';
export const SET_ORDER_ITEMS_STATISTICS_DATE = 'SET_ORDER_ITEMS_STATISTICS_DATE';
export const SET_ORDER_ITEM_LIST = 'SET_ORDER_ITEM_LIST';
export const SET_RASTER_EDITOR_IS_CLOSED = 'SET_RASTER_EDITOR_IS_CLOSED';
export const SET_RASTER_EDITOR_IS_OPEN = 'SET_RASTER_EDITOR_IS_OPEN';
export const SET_SELECTED_ORDER_ITEMS = 'SET_SELECTED_ORDER_ITEMS';
export const SET_SELECTED_ORDER_ITEM_IDS = 'SET_SELECTED_ORDER_ITEM_IDS';
export const SET_SERVER_SELECTION_INFO = 'SET_SERVER_SELECTION_INFO';
export const SET_SOURCE_ORDER_ITEMS = 'SET_SOURCE_ORDER_ITEMS';
export const UPDATE_ORDER_ITEM_LIST = 'UPDATE_ORDER_ITEM_LIST';

// Job List Dialogs
export const CLOSE_ASSIGN_OPERATOR_DIALOG = 'CLOSE_ASSIGN_OPERATOR_DIALOG';
export const CLOSE_DOWNLOAD_FILE_DIALOG = 'CLOSE_DOWNLOAD_FILE_DIALOG';
export const CLOSE_EMAIL_EDITOR_DIALOG = 'CLOSE_EMAIL_EDITOR_DIALOG';
export const CLOSE_INSERT_JOB_DIALOG = 'CLOSE_INSERT_JOB_DIALOG';
export const CLOSE_JOB_HISTORY_DIALOG = 'CLOSE_JOB_HISTORY_DIALOG';
export const CLOSE_JOB_INFORMATION_DIALOG = 'CLOSE_JOB_INFORMATION_DIALOG';
export const CLOSE_JOB_TICKET_DIALOG = 'CLOSE_JOB_TICKET_DIALOG';
export const OPEN_FOLDER_PROPERTIES_DIALOG = 'OPEN_FOLDER_PROPERTIES_DIALOG';
export const CLOSE_FOLDER_PROPERTIES_DIALOG = 'CLOSE_FOLDER_PROPERTIES_DIALOG';
export const OPEN_MOVE_ORDER_ITEMS_DIALOG = 'OPEN_MOVE_ORDER_ITEMS_DIALOG';
export const CLOSE_MOVE_ORDER_ITEMS_DIALOG = 'CLOSE_MOVE_ORDER_ITEMS_DIALOG';
export const CLOSE_NEW_ROLL_JOB_DIALOG = 'CLOSE_NEW_ROLL_JOB_DIALOG';
export const CLOSE_POP_UP_BLOCKED_DIALOG = 'CLOSE_POP_UP_BLOCKED_DIALOG';
export const CLOSE_PREFLIGHT_DIALOG = 'CLOSE_PREFLIGHT_DIALOG';
export const CLOSE_PRINT_DIALOG = 'CLOSE_PRINT_DIALOG';
export const CLOSE_SET_PRINTER_DIALOG = 'CLOSE_SET_PRINTER_DIALOG';
export const CLOSE_VIEW_JOB_DETAILS_DIALOG = 'CLOSE_VIEW_JOB_DETAILS_DIALOG';
export const CLOSE_WORK_STEP_DIALOG = 'CLOSE_WORK_STEP_DIALOG';
export const OPEN_ASSIGN_OPERATOR_DIALOG = 'OPEN_ASSIGN_OPERATOR_DIALOG';
export const OPEN_DOWNLOAD_FILE_DIALOG = 'OPEN_DOWNLOAD_FILE_DIALOG';
export const OPEN_EMAIL_EDITOR_DIALOG = 'OPEN_EMAIL_EDITOR_DIALOG';
export const OPEN_INSERT_JOB_DIALOG = 'OPEN_INSERT_JOB_DIALOG';
export const OPEN_JOB_HISTORY_DIALOG = 'OPEN_JOB_HISTORY_DIALOG';
export const OPEN_JOB_INFORMATION_DIALOG = 'OPEN_JOB_INFORMATION_DIALOG';
export const OPEN_JOB_TICKET_DIALOG = 'OPEN_JOB_TICKET_DIALOG';
export const OPEN_NEW_ROLL_JOB_DIALOG = 'OPEN_NEW_ROLL_JOB_DIALOG';
export const OPEN_POP_UP_BLOCKED_DIALOG = 'OPEN_POP_UP_BLOCKED_DIALOG';
export const OPEN_PREFLIGHT_DIALOG = 'OPEN_PREFLIGHT_DIALOG';
export const OPEN_PRINT_DIALOG = 'OPEN_PRINT_DIALOG';
export const OPEN_SET_PRINTER_DIALOG = 'OPEN_SET_PRINTER_DIALOG';
export const OPEN_VIEW_JOB_DETAILS_DIALOG = 'OPEN_VIEW_JOB_DETAILS_DIALOG';
export const OPEN_WORK_STEP_DIALOG = 'OPEN_WORK_STEP_DIALOG';

// JT Panel
export const SET_MFP_DATA = 'SET_MFP_DATA';
export const SET_MFP_ERROR = 'SET_MFP_ERROR';

// JT Web sync
export const SET_JT_WEB_SYNC_CONFIG = 'SET_JT_WEB_SYNC_CONFIG';

// License
export const SET_LICENSE = 'SET_LICENSE';

// Paper Catalog
export const SET_PAPER_CATALOGS = 'SET_PAPER_CATALOGS';
export const SET_EDITING_PAPER_CATALOGS = 'SET_EDITING_PAPER_CATALOGS';

// Paper Category
export const SET_PAPER_CATEGORIES = 'SET_PAPER_CATEGORIES';

// Paper Size
export const SET_PAPER_SIZES = 'SET_PAPER_SIZES';

// Paper Type
export const SET_PAPER_TYPES = 'SET_PAPER_TYPES';
export const SET_DEFAULT_PAPER_TYPE = 'SET_DEFAULT_PAPER_TYPE';
export const SET_DEFAULT_TAB_PAPER_TYPE = 'SET_DEFAULT_TAB_PAPER_TYPE';

// Preflight configuration
export const SET_PREFLIGHT_CONFIG = 'SET_PREFLIGHT_CONFIG';
export const SET_PREFLIGHT_DATA = 'SET_PREFLIGHT_DATA';

// Print Queue
export const CLEAR_PRINTER = 'CLEAR_PRINTER';
export const CLEAR_PRINT_QUEUE_QUERY = 'CLEAR_PRINT_QUEUE_QUERY';
export const CLEAR_PRINT_QUEUE_QUERY_CHANGED = 'CLEAR_PRINT_QUEUE_QUERY_CHANGED';
export const SELECT_PRINTER = 'SELECT_PRINTER';
export const SET_PRINTED_JOBS = 'SET_PRINTED_JOBS';
export const SET_PRINTED_JOBS_INDEX_BY_FILTER = 'SET_PRINTED_JOBS_INDEX_BY_FILTER';
export const SET_PRINTED_JOBS_PAGE_INDEX = 'SET_PRINTED_JOBS_PAGE_INDEX';
export const SET_PRINT_QUEUE = 'SET_PRINT_QUEUE';
export const SET_PRINT_QUEUE_INDEX_BY_FILTER = 'SET_PRINT_QUEUE_INDEX_BY_FILTER';
export const SET_PRINT_QUEUE_PAGE_INDEX = 'SET_PRINT_QUEUE_PAGE_INDEX';
export const SET_PRINT_QUEUE_PAUSED = 'SET_PRINT_QUEUE_PAUSED';
export const SET_PRINT_QUEUE_QUERY = 'SET_PRINT_QUEUE_QUERY';
export const SET_PRINT_QUEUE_QUERY_CHANGED = 'SET_PRINT_QUEUE_QUERY_CHANGED';
export const SET_PRINT_QUEUE_STATUS = 'SET_PRINT_QUEUE_STATUS';
export const SET_SELECTED_JOBS = 'SET_SELECTED_JOBS';
export const SET_SELECTED_JOB_IDS = 'SET_SELECTED_JOB_IDS';
export const SET_VALID_ORDER_ITEM_IDS = 'SET_VALID_ORDER_ITEM_IDS';

// Print queue - Printer page / printer editor
export const PRINT_QUEUE_CLOSE_PRINTER_EDITOR_DIALOG = 'PRINT_QUEUE_CLOSE_PRINTER_EDITOR_DIALOG';
export const PRINT_QUEUE_CLOSE_PRINTER_PAGE_DIALOG = 'PRINT_QUEUE_CLOSE_PRINTER_PAGE_DIALOG';
export const PRINT_QUEUE_OPEN_PRINTER_EDITOR_DIALOG = 'PRINT_QUEUE_OPEN_PRINTER_EDITOR_DIALOG';
export const PRINT_QUEUE_OPEN_PRINTER_PAGE_DIALOG = 'PRINT_QUEUE_OPEN_PRINTER_PAGE_DIALOG';

// Printer Setting
export const SET_PRINTER_SETTING_LIST = 'SET_PRINTER_SETTING_LIST';
export const SET_PRINTER_SETTING_STRUCTURE_LIST = 'SET_PRINTER_SETTING_STRUCTURE_LIST';
export const UPDATE_PRINTER_SETTING_STRUCTURE = 'UPDATE_PRINTER_SETTING_STRUCTURE';

export const SET_COLOR_MANAGEMENT_DEFAULT_MANUAL_SETTING_LIST = 'SET_COLOR_MANAGEMENT_DEFAULT_MANUAL_SETTING_LIST';
export const SET_COLOR_MANAGEMENT_SETTING_STRUCTURE_LIST = 'SET_COLOR_MANAGEMENT_SETTING_STRUCTURE_LIST';

// Printer
export const SET_PRINTERS = 'SET_PRINTERS';
export const SET_INSTALLED_PRINTERS = 'SET_INSTALLED_PRINTERS';
export const SET_FILE_PRINTER = 'SET_FILE_PRINTER';

export const SET_PRINTER_TO_SUPPORTED_SERVICE_OPTION_MAP = 'SET_PRINTER_TO_SUPPORTED_SERVICE_OPTION_MAP';
export const SET_PRINTER_IMAGE = 'SET_PRINTER_IMAGE';
export const SET_PRINTER_IMAGES = 'SET_PRINTER_IMAGES';
export const SET_PRINTER_MODELS = 'SET_PRINTER_MODELS';
export const UPDATE_MERGE_TRAY_INFO = 'UPDATE_MERGE_TRAY_INFO';
export const UPDATE_INSTALLABLE_FEATURES = 'UPDATE_INSTALLABLE_FEATURES';

export const SET_DISCOVERED_PRINTERS = 'SET_DISCOVERED_PRINTERS';
export const SET_DISCOVERY_ID = 'SET_DISCOVERY_ID';
export const SET_DISCOVERY_STATUS = 'SET_DISCOVERY_STATUS';
export const CLEAR_DISCOVERY_STATUS = 'CLEAR_DISCOVERY_STATUS';

// Product
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const SET_PRODUCT_CATEGORIES = 'SET_PRODUCT_CATEGORIES';

// Product conflict warning dialog
export const CLOSE_PRODUCT_WARNING_DIALOG = 'CLOSE_PRODUCT_WARNING_DIALOG';
export const OPEN_PRODUCT_WARNING_DIALOG = 'OPEN_PRODUCT_WARNING_DIALOG';
export const SET_PRODUCT_WARNING_CONFLICT_MAP = 'SET_PRODUCT_WARNING_CONFLICT_MAP';

// Public API
export const SET_PUBLICAPI_CONFIG = 'SET_PUBLICAPI_CONFIG';
export const SET_PUBLICAPI_USERS = 'SET_PUBLICAPI_USERS';
export const SET_PUBLICAPI_WEBHOOKS = 'SET_PUBLICAPI_WEBHOOKS';
export const SET_PUBLICAPI_FTPSCANS = 'SET_PUBLICAPI_FTPSCANS';
export const SET_PUBLICAPI_ERROR_REPORTS = 'SET_PUBLICAPI_ERROR_REPORTS';

// Report
export const SET_REPORTS = 'SET_REPORTS';

// Roles
export const SET_ROLES = 'SET_ROLES';

// Roll Editor Job List
export const CLEAR_ROLL_EDITOR_JOB_LIST_DATA = 'CLEAR_ROLL_EDITOR_JOB_LIST_DATA';
export const SET_ROLL_EDITOR_JOB_LIST_QUERY = 'SET_ROLL_EDITOR_JOB_LIST_QUERY';
export const SET_ROLL_EDITOR_JOB_LIST_ORDER_ITEMS = 'SET_ROLL_EDITOR_JOB_LIST_ORDER_ITEMS';
export const SET_ROLL_EDITOR_JOB_LIST_ORDER_ITEMS_IDS_BY_FILTER = 'SET_ROLL_EDITOR_JOB_LIST_ORDER_ITEMS_IDS_BY_FILTER';
export const SET_ROLL_EDITOR_JOB_LIST_SETTINGS = 'SET_ROLL_EDITOR_JOB_LIST_SETTINGS';
export const SET_ROLL_EDITOR_JOB_LIST_SERVER_SELECTION_INFO = 'SET_ROLL_EDITOR_JOB_LIST_SERVER_SELECTION_INFO';
export const SET_ROLL_EDITOR_JOB_LIST_SET_SERVER_SELECTION_INFO = 'SET_ROLL_EDITOR_JOB_LIST_SET_SERVER_SELECTION_INFO';
export const SET_ROLL_EDITOR_JOB_LIST_SELECTED_ORDER_ITEM_IDS = 'SET_ROLL_EDITOR_JOB_LIST_SELECTED_ORDER_ITEM_IDS';
export const SET_ROLL_EDITOR_JOB_LIST_TOTAL_COUNT = 'SET_ROLL_EDITOR_JOB_LIST_TOTAL_COUNT';
export const CLEAR_ROLL_EDITOR_JOB_LIST_SERVER_SELECTION_INFO = 'CLEAR_ROLL_EDITOR_JOB_LIST_SERVER_SELECTION_INFO';
export const CLEAR_ROLL_EDITOR_JOB_LIST_SELECTED_ORDER_ITEM_IDS = 'CLEAR_ROLL_EDITOR_JOB_LIST_SELECTED_ORDER_ITEM_IDS';

// Rule for jobs
export const SET_RULES_FOR_JOBS_CONFIG = 'SET_RULES_FOR_JOBS_CONFIG';
export const SET_FILTER_RULES = 'SET_FILTER_RULES';
export const SET_FOLDER_RULES = 'SET_FOLDER_RULES';
export const SET_PRINTED_ITEMS_TARGET_FOLDER_ID = 'SET_PRINTED_ITEMS_TARGET_FOLDER_ID';

// Server
export const SET_SERVER_URL = 'SET_SERVER_URL';

// Service
export const SET_SERVICES = 'SET_SERVICES';
export const SET_SERVICE_GROUPS = 'SET_SERVICE_GROUPS';

// Setting
export const INITIALIZE_SETTINGS = 'INITIALIZE_SETTINGS';

export const SET_ACCOUNTING_CONNECTOR_SETTINGS = 'SET_ACCOUNTING_CONNECTOR_SETTINGS';
export const SET_ALLOWED_FILE_TYPES_SETTINGS = 'SET_ALLOWED_FILE_TYPES_SETTINGS';
export const SET_AUTOPRINT = 'SET_AUTOPRINT';
export const SET_HOTFOLDER_SETTINGS = 'SET_HOTFOLDER_SETTINGS';
export const SET_JOBTICKET_SETTINGS = 'SET_JOBTICKET_SETTINGS';
export const SET_LIST_SORTING_SETTINGS = 'SET_LIST_SORTING_SETTINGS';
export const SET_SERVER_SETTINGS = 'SET_SERVER_SETTINGS';
export const SET_SIGNATURE_SETTINGS = 'SET_SIGNATURE_SETTINGS';
export const SET_ROLL_JOB_TEMPLATE_SETTINGS = 'SET_ROLL_JOB_TEMPLATE_SETTINGS';
export const SET_SYSTEM_FONTS = 'SET_SYSTEM_FONTS';
export const SET_TRASH_FILTER = 'SET_TRASH_FILTER';
export const SET_USER_SETTINGS = 'SET_USER_SETTINGS';
export const SET_WORK_STEP_SETTINGS = 'SET_WORK_STEP_SETTINGS';
export const SET_VERSION_INFO = 'SET_VERSION_INFO';
export const SET_PRINTER_IMPORT_SETTINGS = 'SET_PRINTER_IMPORT_SETTINGS';

// Signature
export const SET_SIGNATURES = 'SET_SIGNATURES';

// Roll job templates
export const SET_ROLL_JOB_TEMPLATES = 'SET_ROLL_JOB_TEMPLATES';

// User Notification
export const SET_USER_NOTIFICATIONS = 'SET_USER_NOTIFICATIONS';

// Tables
// Named Reducers
export const CLOSE_LIST_SORTING_DIALOG = 'CLOSE_LIST_SORTING_DIALOG';
export const OPEN_LIST_SORTING_DIALOG = 'OPEN_LIST_SORTING_DIALOG';
export const RESET_TABLE = 'RESET_TABLE';
export const SET_COLUMN_ORDER = 'SET_COLUMN_ORDER';
export const SET_ENABLED_COLUMN_IDS = 'SET_ENABLED_COLUMN_IDS';
export const SET_PAGINATION_DATA = 'SET_PAGINATION_DATA';
export const SET_TABLE_FOCUSED_IDS = 'SET_TABLE_FOCUSED_IDS';
export const SET_TABLE_QUERY = 'SET_TABLE_QUERY';
export const SET_TABLE_SELECTED_IDS = 'SET_TABLE_SELECTED_IDS';
export const SET_TABLE_SORT_DATA = 'SET_TABLE_SORT_DATA';

// Table / customer accounts
export const CLEAR_CUSTOMER_ACCOUNT_ERROR_ACTION_TYPE = 'CLEAR_CUSTOMER_ACCOUNT_ERROR_ACTION_TYPE';
export const CLEAR_CUSTOMER_ACCOUNT_ERROR_DIALOG_MESSAGE = 'CLEAR_CUSTOMER_ACCOUNT_REMOVE_ERROR_DIALOG_MESSAGE';
export const CLOSE_CUSTOMER_ACCOUNT_ERROR_CONFIRMATION_DIALOG = 'CLOSE_CUSTOMER_ACCOUNT_REMOVE_ERROR_CONFIRMATION_DIALOG';
export const OPEN_CUSTOMER_ACCOUNT_ERROR_CONFIRMATION_DIALOG = 'OPEN_CUSTOMER_ACCOUNT_REMOVE_ERROR_CONFIRMATION_DIALOG';
export const SET_CUSTOMER_ACCOUNT_ERROR_ACTION_TYPE = 'SET_CUSTOMER_ACCOUNT_ERROR_ACTION_TYPE';
export const SET_CUSTOMER_ACCOUNT_ERROR_DIALOG_MESSAGE = 'SET_CUSTOMER_ACCOUNT_REMOVE_ERROR_DIALOG_MESSAGE';

// Editors
// Editors Named Reducers
export const CLEAR_EDITOR_ACTION = 'CLEAR_EDITOR_ACTION';
export const CLEAR_EDITOR_EDITING_ITEM = 'CLEAR_EDITOR_EDITING_ITEM';
export const CLEAR_EDITOR_ERRORS = 'CLEAR_EDITOR_ERRORS';
export const CLEAR_EDITOR_ITEMS_SELECTION = 'CLEAR_EDITOR_ITEMS_SELECTION';
export const CLEAR_EDITOR_PROMPT_ACTION = 'CLEAR_EDITOR_PROMPT_ACTION';
export const CLOSE_EDITOR = 'CLOSE_EDITOR';
export const CLOSE_EDITOR_PROMPT = 'CLOSE_EDITOR_PROMPT';
export const OPEN_EDITOR = 'OPEN_EDITOR';
export const OPEN_EDITOR_PROMPT = 'OPEN_EDITOR_PROMPT';
export const SET_DATA_SNAPSHOT = 'SET_DATA_SNAPSHOT';
export const SET_EDITOR_ACTIVE_TAB_INDEX = 'SET_EDITOR_ACTIVE_TAB_INDEX';
export const SET_EDITOR_ACTION = 'SET_EDITOR_ACTION';
export const SET_EDITOR_DISPLAY_ERROR = 'SET_EDITOR_DISPLAY_ERROR';
export const SET_EDITOR_EDITING_ITEM = 'SET_EDITOR_EDITING_ITEM';
export const SET_EDITOR_ERRORS = 'SET_EDITOR_ERRORS';
export const SET_EDITOR_IS_CHANGED = 'SET_EDITOR_IS_CHANGED';
export const SET_EDITOR_IS_SUBMITTING = 'SET_EDITOR_IS_SUBMITTING';
export const SET_EDITOR_ITEMS_SELECTION = 'SET_EDITOR_ITEMS_SELECTION';
export const SET_EDITOR_PROMPT_ACTION = 'SET_EDITOR_PROMPT_ACTION';
export const SET_EDITOR_INITIAL_EDITING_ITEM = 'SET_EDITOR_INITIAL_EDITING_ITEM';

// Editors / address config
export const CLOSE_ADDRESS_CONFIG_EDITOR = 'OPEN_ADDRESS_CONFIG_EDITOR';
export const OPEN_ADDRESS_CONFIG_EDITOR = 'CLOSE_ADDRESS_CONFIG_EDITOR';

// Editors / name category editors
export const CLOSE_CATEGORY_EDITOR = 'CLOSE_CATEGORY_EDITOR';
export const CLOSE_CATEGORY_EDITOR_PROMPT = 'CLOSE_CATEGORY_EDITOR_PROMPT';
export const CLOSE_CATEGORY_EDITOR_WARNING = 'CLOSE_CATEGORY_EDITOR_WARNING';
export const OPEN_CATEGORY_EDITOR_PROMPT = 'OPEN_CATEGORY_EDITOR_PROMPT';
export const OPEN_CATEGORY_EDITOR = 'OPEN_CATEGORY_EDITOR';
export const OPEN_CATEGORY_EDITOR_WARNING = 'OPEN_CATEGORY_EDITOR_WARNING';
export const SET_CATEGORY_EDITOR_ITEM_LIST = 'SET_CATEGORY_EDITOR_ITEM_LIST';
export const SET_CATEGORY_EDITOR_LIST = 'SET_CATEGORY_EDITOR_LIST';
export const SET_IS_CATEGORY_EDITOR_CHANGED = 'SET_IS_CATEGORY_EDITOR_CHANGED';

// Editors / Hotfolder Product
export const SET_HOTFOLDER_EDITOR_IS_NEW_PRODUCT = 'SET_HOTFOLDER_EDITOR_IS_NEW_PRODUCT';

// Editors / Import Paper Catalog
export const OPEN_IMPORT_PAPER_CATALOG_DIALOG = 'OPEN_IMPORT_PAPER_CATALOG_DIALOG';
export const CLOSE_IMPORT_PAPER_CATALOG_DIALOG = 'CLOSE_IMPORT_PAPER_CATALOG_DIALOG';

// Editors / Printer Dialog
export const OPEN_ADD_PRINTER_DIALOG = 'OPEN_ADD_PRINTER_DIALOG';
export const CLOSE_ADD_PRINTER_DIALOG = 'CLOSE_ADD_PRINTER_DIALOG';
export const SET_PRINTER_IMAGE_DATA = 'SET_PRINTER_IMAGE_DATA';
export const CLEAR_PRINTER_IMAGE_DATA = 'CLEAR_PRINTER_IMAGE_DATA';

// Editors / Products
export const SET_PRODUCT_FORM_DATA = 'SET_PRODUCT_FORM_DATA';
export const SET_PRODUCT_IS_HOTFOLDER_ASSIGNED = 'SET_PRODUCT_IS_HOTFOLDER_ASSIGNED';
export const SET_PRODUCT_IS_VIRTUAL_PRINTER_ASSIGNED = 'SET_PRODUCT_IS_VIRTUAL_PRINTER_ASSIGNED';
export const SET_PRODUCT_PDF_PREVIEW_IMAGE = 'SET_PRODUCT_PDF_PREVIEW_IMAGE';
export const SET_PRODUCT_PREFLIGHT_PROFILE = 'SET_PRODUCT_PREFLIGHT_PROFILE';

// Editor / Report
export const CLOSE_CREATE_REPORT_DIALOG = 'CLOSE_CREATE_REPORT_DIALOG';
export const CLOSE_DUPLICATE_REPORT_DIALOG = 'CLOSE_DUPLICATE_REPORT_DIALOG';
export const OPEN_CREATE_REPORT_DIALOG = 'OPEN_CREATE_REPORT_DIALOG';
export const OPEN_DUPLICATE_REPORT_DIALOG = 'OPEN_DUPLICATE_REPORT_DIALOG';
export const SET_DUPLICATE_REPORT_ID = 'SET_DUPLICATE_REPORT_ID';

// User
export const SET_USERS = 'SET_USERS';
export const SET_PRINTER_USERS = 'SET_PRINTER_USERS';

// Virtual Printer
export const SET_VIRTUAL_PRINTERS = 'SET_VIRTUAL_PRINTERS';
export const SET_MISSING_VIRTUAL_PRINTER_IDS = 'SET_MISSING_VIRTUAL_PRINTER_IDS';

// Work Step
export const SET_WORK_STEPS = 'SET_WORK_STEPS';

// first steps
export const SET_FIRST_STEPS_SETTINGS = 'SET_FIRST_STEPS_SETTINGS';

// modal
export const HIDE_CONFIRMATION_DIALOG = 'HIDE_CONFIRMATION_DIALOG';
export const HIDE_DIALOG = 'HIDE_DIALOG';
export const HIDE_ERROR_MESSAGE = 'HIDE_ERROR_MESSAGE';
export const HIDE_INFORMATION = 'HIDE_INFORMATION';
export const SET_DIALOG_DATA = 'SET_DIALOG_DATA';
export const SHOW_CONFIRMATION_DIALOG = 'SHOW_CONFIRMATION_DIALOG';
export const SHOW_DIALOG = 'SHOW_DIALOG';
export const SHOW_ERROR_MESSAGE = 'SHOW_ERROR_MESSAGE';
export const SHOW_INFORMATION = 'SHOW_INFORMATION';

