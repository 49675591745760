import React, { useEffect, lazy, Suspense, useState } from 'react';
import { BrowserRouter, Route, Switch, Link, Redirect } from 'react-router-dom';
import { Loader } from 'dots-react-ui/src/components/loader';
import { useSelector } from 'react-redux';
import useActions from 'dots-react-components/src/hooks/use-actions';
import actions from './actions';
import selectors from './selectors';
import { getBaseName, getPortalName, isPortalUrl } from './utils/url';
import FluxWebProviders from './app-providers';

// Dynamic import used to code splitting
const Login = lazy(() => import('./components/login'));
const ConfigPortalDetails = lazy(() => import('./components/config-portal-details'));
const LoginPreview = lazy(() => import('./components/login/login-preview-window'));

export default function App({ isProduction }) {
	const initApp = useActions(actions.app.initApp);
	const appHasLoaded = useSelector(selectors.app.appHasLoadedSelector);
	const isAuthenticated = useSelector(selectors.auth.isAuthenticatedSelector);
	const i18n = useSelector(selectors.i18n);
	const [baseName, setBaseName] = useState('/');

	useEffect(() => {
		const portalName = getPortalName();
		if (isPortalUrl() && portalName) {
			setBaseName(`/${getBaseName()}`);
			// set default base url is there's portalName
		}
		initApp(portalName);
	}, [initApp]);

	useEffect(() => {
		const pathname = window.location.pathname;
		// Check if user is accessing a portal login page /portal/:portalName
		if (isPortalUrl() && !isAuthenticated && appHasLoaded) {
			const portalPage = pathname.split('/')[3] || '';
			if (portalPage !== '' && portalPage !== 'login.web') {
				window.location.href = `/${getBaseName()}${window.location.search}`;
			}
		} else if (!isAuthenticated && (pathname !== '/login.web' && !isPortalUrl()) && appHasLoaded) {
			window.location.href = `/login.web${window.location.search}`;
		}
	}, [isAuthenticated, appHasLoaded]);

	if (!appHasLoaded) {
		return null;
	}

	return (
		<FluxWebProviders i18n={i18n}>
			<BrowserRouter basename={baseName}>
				<Suspense fallback={<Loader loading />}>
					<Switch>
						{ !baseName
							&& <Route exact path="/">
								<Redirect to="/login.web" />
							</Route>
						}
						<Route exact path="/" render={() => <Login />} />
						<Route path="/login.web" render={() => <Login />} />
						<Route path="/loginPreview.web" render={() => <LoginPreview />} />
						<Route exact path="/configPortalDetailsInner.web" render={() => <ConfigPortalDetails />} />
						{/* temporary route to redirect to portal configuration on dev mode */}
						{ !isProduction && <Route render={() => <Link to="/configPortalDetailsInner.web" style={{display: 'block', margin: '4rem', textDecoration: 'underline'}}>Click to go to Config Portal Page</Link>} /> }
						{ isProduction && <Route render={() => <Login />} /> }
					</Switch>
				</Suspense>
			</BrowserRouter>
		</FluxWebProviders>
	);
};