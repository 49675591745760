import React from 'react';
import PropTypes from 'prop-types';

const Title = ({
	children,
	className,
	onMouseEnter,
	onMouseLeave,
	onFocus,
	onClick
}) => (
	<h1
		id="page-title"
		className={`font-normal font-sans-bold inline-block leading-normal mb-2 text-black text-xl ${className}`}
		onMouseEnter={onMouseEnter}
		onMouseLeave={onMouseLeave}
		onFocus={onFocus}
		onClick={onClick}
	>
		{children}
	</h1>
);

Title.propTypes = {
	className: PropTypes.string,
	icon: PropTypes.string
};

Title.defaultProps = {
	className: '',
	icon: '',
	onMouseEnter: () => {},
	onMouseLeave: () => {},
	onFocus: () => {},
	onClick: () => {}
};

export default Title;