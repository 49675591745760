if (typeof require === 'undefined') {
  // eslint-disable-next-line no-undef
  Components.utils.import('resource://requireloc/dotsRequireModule.js');
  var module = {
    exports: Object.create(null)
  };
  Object.defineProperty(this, 'exports', {
    get: function get() {
      return module.exports;
    }
  });
  var EXPORTED_SYMBOLS = ['module'];
}

module.exports = {
  ASSIGN_ME: 'assign_me',
  DETAILS: 'details',
  EDIT_DOC: 'edit_doc',
  EDIT_IMAGES: 'edit_images',
  JOBHISTORY: 'jobhistory',
  JOBTICKET: 'jobticket',
  NEW_JOB: 'new_job',
  NEW_ROLL_JOB: 'new_roll_job',
  PREVIEW: 'preview',
  PREVIEW_PAGE: 'preview_page',
  PREVIEW_SHEET: 'preview_sheet',
  PRINT: 'print',
  PRINT_MENU: 'print_menu',
  PRINT_OPTIONS: 'print_options',
  QUICK_IMPORT: 'quick_import',
  SCAN: 'scan',
  TESTPRINT: 'testprint',
  // other
  SPACER: 'toolbar_spacer',
  SEPARATOR: 'toolbar_separator'
};