if (typeof require === 'undefined') {
  Components.utils.import('resource://requireloc/dotsRequireModule.js');
  var module = {
    exports: Object.create(null)
  };
  Object.defineProperty(this, 'exports', {
    get: function get() {
      return module.exports;
    }
  });
  var EXPORTED_SYMBOLS = ['module'];
}

module.exports = {
  NEW: 'new',
  READ: 'read',
  PRINTED: 'printed',
  PRINT_PREPARING: 'printPreparing',
  PRINT_WAIT: 'printWait',
  PRINT_RUNNING: 'printRunning',
  PRINT_WARNING: 'printWarning',
  PRINT_ERROR: 'printError',
  PRINT_PENDING: 'printPending',
  PRINT_SCHEDULED: 'printScheduled',
  PRINT_CANCEL: 'printCancel',
  TEST_PRINTED: 'testPrinted',
  PRINT_FAILED: 'printFailed',
  NOT_COMPLETED: 'notCompleted',
  PRINTER_PAUSED: 'printerPaused',
  PRINTER_OFFLINE: 'offline',
  PRINT_UNKNOWN: 'printUnknown',
  HAS_PRINTER_PROBLEMS: 'printerProblems'
};