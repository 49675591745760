if (typeof require === 'undefined') {
  Components.utils.import('resource://requireloc/dotsRequireModule.js');
  var module = {
    exports: Object.create(null)
  };
  Object.defineProperty(this, 'exports', {
    get: function get() {
      return module.exports;
    }
  });
  var EXPORTED_SYMBOLS = ['module'];
}

module.exports = {
  PROOFPRINT: "PROOFPRINT",
  COLORPRINT: "COLORPRINT",
  DUPLEX: "DUPLEX",
  SCALE: "SCALE",
  ROTATE: "ROTATE",
  POSITION: "POSITION",
  COVER_TYPE: "COVER_TYPE",
  COVER_PAPERTYPE: "COVER_PAPERTYPE",
  FRONT_COVER_PAPERTYPE: "FRONT_COVER_PAPERTYPE",
  BACK_COVER_PAPERTYPE: "BACK_COVER_PAPERTYPE",
  FRONT_COVER_PRINT: "FRONT_COVER_PRINT",
  BACK_COVER_PRINT: "BACK_COVER_PRINT",
  PAPERTYPE: "PAPERTYPE",
  BINDINGPOSITION: "BINDINGPOSITION",
  PUNCH: "PUNCH",
  STAPLE: "STAPLE",
  FOLD: "FOLD",
  PAPERSIZE: 'PAPERSIZE',
  FEEDDIRECTION: 'FeedDirection'
};