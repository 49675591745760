import { get } from 'dots-frontend/src/server-fetch';
import { getApiBaseName } from '../utils/url';

export const getAllConfiguration = async() => await get(`${getApiBaseName()}config`);

export const getDefaultTabPaperType = async() => await get(`${getApiBaseName()}config/default-tab-paper-type`);

export const getDeliveryTypes = async() => await get(`${getApiBaseName()}config/delivery-types`);

export const getGroups = async() => await get(`${getApiBaseName()}config/groups`);

export const getPaperSizes = async() => await get(`${getApiBaseName()}config/paper-sizes`);

export const getProductCategories = async() => await get(`${getApiBaseName()}config/product-categories`);

export const getProducts = async() => await get(`${getApiBaseName()}config/products`);

export const getServiceGroups = async() => await get(`${getApiBaseName()}config/service-groups`);

export const getServices = async() => await get(`${getApiBaseName()}config/services`);

export const getSignatures = async() => await get(`${getApiBaseName()}config/signatures`);

export const getStockProducts = async() => await get(`${getApiBaseName()}config/stock-products`);
