if (typeof require === 'undefined') {
  // eslint-disable-next-line no-undef
  Components.utils.import('resource://requireloc/dotsRequireModule.js');
  var module = {
    exports: Object.create(null)
  };
  Object.defineProperty(this, 'exports', {
    get: function get() {
      return module.exports;
    }
  });
  var EXPORTED_SYMBOLS = ['module'];
}

module.exports = {
  PAUSE: 'pause',
  RESUME: 'resume',
  PAPER_CATALOG: 'paper_catalog',
  CONFIG: 'config',
  CONSUMABLES: 'consumables',
  // other
  SPACER: 'toolbar_spacer',
  SEPARATOR: 'toolbar_separator'
};