if (typeof require === 'undefined') {
  Components.utils.import('resource://requireloc/dotsRequireModule.js');
  var module = {
    exports: Object.create(null)
  };
  Object.defineProperty(this, 'exports', {
    get: function get() {
      return module.exports;
    }
  });
  var EXPORTED_SYMBOLS = ['module'];
} // Overall order status 


module.exports = {
  NEW: "NEW",
  DO: "DO",
  // In progress
  DONE: "DONE",
  STOPPED: "STOPPED" // Error

};