import * as actionTypes from '../../constants/action-types';

const errorRequestDialogs = (state = [], action) => {
	switch (action.type) {
	case actionTypes.CREATE_ERROR_REQUEST_DIALOG:
		return state.concat(action.dialog);
	case actionTypes.DELETE_ERROR_REQUEST_DIALOG:
		return state.filter(dialog => dialog.id !== action.id);
	case actionTypes.CLEAR_DIALOGS:
	case actionTypes.CLEAR_DATA:
		return [];
	default:
		return state;
	}
};

export default errorRequestDialogs;
