if (typeof require === 'undefined') {
  Components.utils.import('resource://requireloc/dotsRequireModule.js');
  var module = {
    exports: Object.create(null)
  };
  Object.defineProperty(this, 'exports', {
    get: function get() {
      return module.exports;
    }
  });
  var EXPORTED_SYMBOLS = ['module'];
}

module.exports = {
  LIST: 'list',
  CATEGORYLIST: 'categorylist',
  RADIOBUTTON: 'radiobutton',
  CHECKBOX: 'checkbox'
};