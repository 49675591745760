export const RESOURCE_TYPE_TO_UPLOAD_PARAMS = {
	'background-image': {
		'upl_type': 'itemPic',
		bkgUpload: 1
	},
	'logo-image': {
		'upl_type': 'logoImage'
	},
	'vdp-pic': {
		'upl_type': 'vdpPic'
	},
	'item-pic': {
		'upl_type': 'itemPic'
	},
	'upload-documents': {
		'upl_type': 'uploadDocuments'
	},
	'inset-docs': {
		'upl_type': 'insertDocs'
	}
};
