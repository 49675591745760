if (typeof require === 'undefined') {
  Components.utils.import('resource://requireloc/dotsRequireModule.js');
  var module = {
    exports: Object.create(null)
  };
  Object.defineProperty(this, 'exports', {
    get: function get() {
      return module.exports;
    }
  });
  var EXPORTED_SYMBOLS = ['module'];
}

module.exports = {
  NONE: '',
  ERROR: 'error',
  WARNING: 'warning',
  FIXUP: 'fixup',
  FIXUP_FAILED: 'fixupFailed',
  APPROVED: 'approved',
  INFOS: 'infos',
  PENDING: 'pending'
};