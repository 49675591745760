if (typeof require === 'undefined') {
  // eslint-disable-next-line no-undef
  Components.utils.import('resource://requireloc/dotsRequireModule.js');
  var module = {
    exports: Object.create(null)
  };
  Object.defineProperty(this, 'exports', {
    get: function get() {
      return module.exports;
    }
  });
  var EXPORTED_SYMBOLS = ['module'];
}

exports.GLOBAL_OFF = 'OFF';
exports.GLOBAL_ON = 'ON';
exports.MANUAL_OPTION_ID = 'MANUAL';
exports.GeneralOptions = {
  ON: 'ON',
  OFF: 'OFF'
};
exports.MergeFiles = {
  ON: 'ON',
  OFF: 'OFF',
  RECENT_CHOICE: 'RECENT_CHOICE'
};
exports.ProofPrint = {
  ON: 'ON',
  OFF: 'OFF'
};
exports.ColorPrint = {
  ON: 'ON',
  OFF: 'OFF'
};
exports.Duplex = {
  ON: 'ON',
  ONTUMBLE: 'ONTUMBLE',
  OFF: 'OFF'
};
exports.Scale = {
  OFF: 'OFF',
  AUTO: 'AUTO',
  MANUAL: 'MANUAL'
};
exports.Rotate = {
  'AUTO': 'AUTO',
  'OFF': 'OFF',
  '90': '90',
  '180': '180',
  '270': '270'
};
exports.Position = {
  LEFT_BOTTOM: 'LEFT_BOTTOM',
  LEFT_CENTERED: 'LEFT_CENTERED',
  LEFT_TOP: 'LEFT_TOP',
  CENTERED_BOTTOM: 'CENTERED_BOTTOM',
  CENTERED_CENTERED: 'CENTERED_CENTERED',
  CENTERED_TOP: 'CENTERED_TOP',
  RIGHT_BOTTOM: 'RIGHT_BOTTOM',
  RIGHT_CENTERED: 'RIGHT_CENTERED',
  RIGHT_TOP: 'RIGHT_TOP'
};
exports.Flip = {
  OFF: 'OFF',
  HORIZONTAL: 'HORIZONTAL',
  VERTICAL: 'VERTICAL',
  HORIZONTAL_AND_VERTICAL: 'HORIZONTAL_AND_VERTICAL'
};
exports.PaperType = {
  OFF: 'OFF',
  DEFAULTTAB: 'DEFAULTTAB'
};
exports.CoverType = {
  OFF: 'OFF',
  FRONT: 'FRONT',
  BACK: 'BACK',
  FRONT_AND_BACK: 'FRONT_AND_BACK'
}; // FRONT_COVER_PRINT and BACK_COVER_PRINT

exports.CoverPrint = {
  OFF: 'OFF',
  OUTSIDE: 'OUTSIDE',
  INSIDE: 'INSIDE',
  OUTSIDE_AND_INSIDE: 'OUTSIDE_AND_INSIDE'
};
exports.BindingPosition = {
  LEFT: 'LEFT',
  TOP: 'TOP',
  RIGHT: 'RIGHT',
  BOTTOM: 'BOTTOM'
};
exports.Punch = {
  'OFF': 'OFF',
  'TRUE': 'TRUE',
  '2HOLES': '2HOLES',
  '3HOLES': '3HOLES',
  '4HOLES': '4HOLES',
  '2HOLESWIDE': '2HOLESWIDE',
  '3HOLESWIDE': '3HOLESWIDE',
  '4HOLESWIDE': '4HOLESWIDE',
  '4HOLESGROUPED': '4HOLESGROUPED',
  'MULTIHOLE': 'MULTIHOLE',
  'RINGBOUND': 'RINGBOUND'
};
exports.Staple = {
  'OFF': 'OFF',
  'TRUE': 'TRUE',
  '1STAPLE': '1STAPLE',
  '1STAPLE(V)': '1STAPLE(V)',
  '1STAPLE(H)': '1STAPLE(H)',
  '1STAPLE(S)': '1STAPLE(S)',
  '1STAPLEII': '1STAPLEII',
  '1STAPLEII(V)': '1STAPLEII(V)',
  '1STAPLEII(H)': '1STAPLEII(H)',
  '1STAPLEII(S)': '1STAPLEII(S)',
  '2STAPLES': '2STAPLES',
  '2STAPLEWIDE': '2STAPLEWIDE',
  '2STAPLEXWIDE': '2STAPLEXWIDE',
  '4STAPLES': '4STAPLES',
  '4STAPLES(CENTER)': '4STAPLES(CENTER)',
  '1STAPLE(LOWER)': '1STAPLE(LOWER)',
  '1STAPLE(LOWERV)': '1STAPLE(LOWERV)',
  '1STAPLE(LOWERH)': '1STAPLE(LOWERH)',
  '1STAPLE(LOWERS)': '1STAPLE(LOWERS)',
  '1CENTER': '1CENTER',
  'CENTER': 'CENTER',
  'CENTER(FOLD)': 'CENTER(FOLD)',
  'CENTER(FOLDTRIM)': 'CENTER(FOLDTRIM)',
  'CENTER(FOLDTRIM3SIDES)': 'CENTER(FOLDTRIM3SIDES)',
  'CENTER(SQUAREFOLD)': 'CENTER(SQUAREFOLD)',
  'CENTER(SQUAREFOLDTRIM)': 'CENTER(SQUAREFOLDTRIM)',
  'CENTER(SQUAREFOLDTRIM3SIDES)': 'CENTER(SQUAREFOLDTRIM3SIDES)'
};
exports.Fold = {
  OFF: 'OFF',
  ZFOLD: 'ZFOLD',
  MULTIHALFFOLD: 'MULTIHALFFOLD',
  MULTIHALFFOLDTRIM: 'MULTIHALFFOLDTRIM',
  MULTIHALFFOLDIN: 'MULTIHALFFOLDIN',
  MULTIHALFFOLDINTRIM: 'MULTIHALFFOLDINTRIM',
  MULTIHALFFOLDOUT: 'MULTIHALFFOLDOUT',
  MULTIHALFFOLDOUTTRIM: 'MULTIHALFFOLDOUTTRIM',
  MULTILETTERFOLDIN: 'MULTILETTERFOLDIN',
  MULTILETTERFOLDOUT: 'MULTILETTERFOLDOUT',
  HALFFOLD: 'HALFFOLD',
  HALFFOLDIN: 'HALFFOLDIN',
  HALFFOLDOUT: 'HALFFOLDOUT',
  LETTERFOLD: 'LETTERFOLD',
  LETTERFOLDIN: 'LETTERFOLDIN',
  LETTERFOLDOUT: 'LETTERFOLDOUT',
  ZIGZAGIN: 'ZIGZAGIN',
  ZIGZAGOUT: 'ZIGZAGOUT',
  DOUBLEPARALLELIN: 'DOUBLEPARALLELIN',
  DOUBLEPARALLELOUT: 'DOUBLEPARALLELOUT',
  GATEFOLDIN: 'GATEFOLDIN',
  GATEFOLDOUT: 'GATEFOLDOUT'
};