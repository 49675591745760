import guid from 'dots-guid';

import * as actionTypes from '../constants/action-types';

export const createErrorRequestDialog = dialog => ({ type: actionTypes.CREATE_ERROR_REQUEST_DIALOG, dialog: { ...dialog, id: guid() } });

export const deleteErrorRequestDialog = id => ({ type: actionTypes.DELETE_ERROR_REQUEST_DIALOG, id });

export const createDialog = dialog => ({ type: actionTypes.CREATE_DIALOG, dialog: { id: guid(), ...dialog } });

export const deleteDialog = id => ({ type: actionTypes.DELETE_DIALOG, id });

export const clearDialogs = () => ({ type: actionTypes.CLEAR_DIALOGS });
