import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const bodyStyles = (hasFooter, maxHeight, minHeight, type) => {
	if (type === 'full') {
		return { height: hasFooter ? 'calc(100vh - 10rem)' : 'calc(100vh - 8rem)' };
	}
	if (type === 'small' || type === 'dialog') {
		return {
			minHeight: `min(${minHeight === null ? 14 : minHeight}rem, calc(80vh - 12rem))`,
			maxHeight: 'calc(80vh - 12rem)',
		};
	}
	if (type === 'large') {
		return { height: maxHeight ? `calc(${maxHeight}vh - 16rem)` : 'calc(100vh - 16rem)' };
	}
	return { height: maxHeight ? `calc(${maxHeight}vh - 12rem)` : 'calc(80vh - 12rem)' };
};

const getModalBodyClassName = (className, noPadding, type, overflowYAuto) => classNames(
	{
		'overflow-y-auto': overflowYAuto,
		'mt-12': type === 'full',
		'pt-2 md:pt-3 px-4': type === 'full' && !noPadding,
		'mt-2 px-2 md:px-3': (type === 'dialog' || type === 'small') && !noPadding,
		'my-2 md:mb:3 px-2 md:px-3': (type === 'large' || type === 'medium') && !noPadding,
		'rounded-t': type === 'headless',
	},
	className,
);

const ModalBody = ({ children, className, hasFooter, maxHeight, minHeight, noPadding, type, overflowYAuto = true }) => (
	<div
		className={getModalBodyClassName(className, noPadding, type, overflowYAuto)}
		style={bodyStyles(hasFooter, maxHeight, minHeight, type)}
		onScroll={() => document.dispatchEvent(new Event('scroll'))}
	>
		{children}
	</div>
);

ModalBody.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	hasFooter: PropTypes.bool,
	maxHeight: PropTypes.number,
	minHeight: PropTypes.number,
	noPadding: PropTypes.bool,
	type: PropTypes.oneOf([ 'full', 'small', 'medium', 'dialog', 'large', '' ]),
};

ModalBody.defaultProps = {
	children: null,
	className: '',
	hasFooter: true,
	minHeight: null,
	noPadding: false,
	type: '',
};

export default ModalBody;
