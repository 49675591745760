if (typeof require === 'undefined') {
  Components.utils.import('resource://requireloc/dotsRequireModule.js');
  var module = {
    exports: Object.create(null)
  };
  Object.defineProperty(this, 'exports', {
    get: function get() {
      return module.exports;
    }
  });
  var EXPORTED_SYMBOLS = ['module'];
}

exports.VACANT_PAGE_INDEX = -1;
exports.InsertPositions = {
  AT_START: 'AT_START',
  AT_END: 'AT_END',
  BEFORE_SELECTED_ITEM: 'BEFORE_SELECTED_ITEM',
  AFTER_SELECTED_ITEM: 'AFTER_SELECTED_ITEM',
  AT_CHAPTER_START: 'AT_CHAPTER_START',
  AT_CHAPTER_END: 'AT_CHAPTER_END',
  BEFORE_CHAPTER: 'BEFORE_CHAPTER',
  AFTER_CHAPTER: 'AFTER_CHAPTER'
};
exports.PageType = {
  TAB: 'TAB',
  SLIP: 'SLIP',
  PAGE: 'PAGE',
  VACANT_PAGE: 'VACANT_PAGE'
};
exports.SheetType = {
  TAB: 'TAB',
  SLIP: 'SLIP',
  PAGES: 'PAGES',
  COVER: 'COVER'
};
exports.PageSide = {
  FRONT: 'front',
  BACK: 'back'
};
exports.SheetSide = {
  FRONT: 'frontSide',
  BACK: 'backSide'
};
exports.SheetSideSelection = {
  FRONT: exports.SheetSide.FRONT,
  BACK: exports.SheetSide.BACK,
  FRONT_AND_BACK: 'FRONT_AND_BACK'
};
exports.OutputFormat = {
  FIXED: 'Fixed',
  MIXED: 'Mixed',
  IMPOSED: 'Imposed'
};
exports.CutMarksType = {
  ROMAN: 'ROMAN',
  JAPANESE: 'JAPANESE'
};
exports.PdfBoxType = {
  CUSTOM: 'custom',
  TRIM_BOX: 'trimBox',
  CROP_BOX: 'cropBox',
  MEDIA_BOX: 'mediaBox',
  DOCUMENT_FORMAT: 'documentFormat' //Trim box -> Crop box -> Media box

};
exports.FormatType = {
  TRIM_BOX: 'trimBox',
  CROP_BOX: 'cropBox',
  MEDIA_BOX: 'mediaBox',
  DOCUMENT_FORMAT: 'documentFormat',
  //Trim box -> Crop box -> Media box
  USER_CROP_BOX: 'userCropBox',
  PAPER_FORMAT: 'paperFormat'
};
exports.PageSizeType = {
  TRIM_BOX: 'trimBox',
  CROP_BOX: 'cropBox',
  MEDIA_BOX: 'mediaBox',
  DOCUMENT_FORMAT: 'documentFormat',
  //Trim box -> Crop box -> Media box
  USER_CROP_BOX: 'userCropBox',
  PAPER_FORMAT: 'paperFormat',
  SHEET_SIZE: 'sheetSize'
};
exports.PageCropping = {
  TRIM_BOX: 'trimBox',
  CROP_BOX: 'cropBox',
  MEDIA_BOX: 'mediaBox',
  DOCUMENT_FORMAT: 'documentFormat',
  //Trim box -> Crop box -> Media box
  PAPER_FORMAT: 'paperFormat',
  CUSTOM: 'custom'
}; // Needed in Imposition_settings_librabry

exports.ImposeMode = {
  NO_IMPOSITION: 'NO_IMPOSITION',
  PAGE_SIZE: 'PAGE_SIZE',
  GANG_UP: 'GANG_UP',
  STEP_AND_REPEAT: 'STEP_AND_REPEAT',
  STEP_AND_REPEAT_II: 'STEP_AND_REPEAT_II',
  BOOKLET_I: 'BOOKLET_I',
  BOOKLET_II: 'BOOKLET_II',
  CUT_AND_MARRY: 'CUT_AND_MARRY',
  CUT_AND_MARRY_DUPLEX: 'CUT_AND_MARRY_DUPLEX',
  CROSSFOLD_I: 'CROSSFOLD_I',
  CROSSFOLD_II: 'CROSSFOLD_II',
  REPEAT_BY_COLUMN: 'REPEAT_BY_COLUMN',
  REPEAT_BY_ROW: 'REPEAT_BY_ROW',
  CUT_AND_STACK_BY_COLUMN: 'CUT_AND_STACK_BY_COLUMN',
  CUT_AND_STACK_BY_ROW: 'CUT_AND_STACK_BY_ROW',
  VDP: 'VDP'
};
exports.ImpositionScheme = {
  GANG_UP: 'GANG_UP',
  STEP_AND_REPEAT: 'STEP_AND_REPEAT',
  STEP_AND_REPEAT_II: 'STEP_AND_REPEAT_II',
  BOOKLET_I: 'BOOKLET_I',
  BOOKLET_II: 'BOOKLET_II',
  CUT_AND_MARRY: 'CUT_AND_MARRY',
  CUT_AND_MARRY_DUPLEX: 'CUT_AND_MARRY_DUPLEX',
  CROSSFOLD_I: 'CROSSFOLD_I',
  CROSSFOLD_II: 'CROSSFOLD_II',
  REPEAT_BY_COLUMN: 'REPEAT_BY_COLUMN',
  REPEAT_BY_ROW: 'REPEAT_BY_ROW',
  CUT_AND_STACK_BY_COLUMN: 'CUT_AND_STACK_BY_COLUMN',
  CUT_AND_STACK_BY_ROW: 'CUT_AND_STACK_BY_ROW'
};
exports.PageNumberSelection = {
  ALL: 'ALL',
  EVEN: 'EVEN',
  ODD: 'ODD',
  RANGE: 'RANGE',
  TAB_RANGE: 'TAB_RANGE',
  ALL_TABS: 'ALL_TABS',
  ARTWORK: 'ARTWORK',
  ALL_ARTWORKS: 'ALL_ARTWORKS'
};
exports.HorizontalAlignment = {
  LEFT: 'LEFT',
  CENTERED: 'CENTERED',
  RIGHT: 'RIGHT'
};
exports.VerticalAlignment = {
  TOP: 'TOP',
  CENTERED: 'CENTERED',
  BOTTOM: 'BOTTOM'
};
exports.DEAFAULT_SIGNATURE_POSITION = exports.HorizontalAlignment.CENTERED + '_' + exports.VerticalAlignment.CENTERED;
exports.Corner = {
  TOP_LEFT: 'TOP_LEFT',
  TOP_RIGHT: 'TOP_RIGHT',
  BOTTOM_LEFT: 'BOTTOM_LEFT',
  BOTTOM_RIGHT: 'BOTTOM_RIGHT'
};
exports.TextDirection = {
  NORMAL: 'NORMAL',
  HORIZONTAL: 'HORIZONTAL',
  VERTICAL: 'VERTICAL',
  ROTATED: 'ROTATED'
};
exports.FinisherMarkSheetRange = {
  ALL_SHEETS: 'ALL_SHEETS',
  FIRST_SHEET: 'FIRST_SHEET',
  LAST_SHEET: 'LAST_SHEET'
};
exports.ServiceScope = {
  DOCUMENT: 0,
  SHEET: 1,
  PAGE: 2
};
exports.ChapterBasedOn = {
  OFF: 0,
  ON_FILES: 1,
  ON_BOOKMARKS: 2,
  ON_FILES_AND_BOOKMARKS: 3
};
exports.BarcodeRegisterMarkType = {
  ANGLE: 'ANGLE',
  RECTANGLE: 'RECTANGLE'
};
exports.SignaturePageFlow = {
  LEFT_RIGHT_TOP_BOTTOM: 'LEFT_RIGHT_TOP_BOTTOM',
  RIGHT_LEFT_TOP_BOTTOM: 'RIGHT_LEFT_TOP_BOTTOM',
  TOP_BOTTOM_LEFT_RIGHT: 'TOP_BOTTOM_LEFT_RIGHT',
  TOP_BOTTOM_RIGHT_LEFT: 'TOP_BOTTOM_RIGHT_LEFT',
  LEFT_RIGHT_BOTTOM_TOP: 'LEFT_RIGHT_BOTTOM_TOP',
  RIGHT_LEFT_BOTTOM_TOP: 'RIGHT_LEFT_BOTTOM_TOP',
  BOTTOM_TOP_LEFT_RIGHT: 'BOTTOM_TOP_LEFT_RIGHT',
  BOTTOM_TOP_RIGHT_LEFT: 'BOTTOM_TOP_RIGHT_LEFT',
  EMPTY: ""
};
exports.LayoutType = {
  NO_IMPOSITION: 'NO_IMPOSITION',
  //ONE_UP with sheetSizeType DOCUMENT_FORMAT and pageSize = sheetSize
  ONE_UP: 'ONE_UP',
  MULTI_UP: 'MULTI_UP',
  BOOKLET: 'BOOKLET',
  CROSSFOLD: 'CROSSFOLD'
};
exports.MultiUpType = {
  UNIQUE: 'UNIQUE',
  REPEAT: 'REPEAT',
  CUT_AND_STACK: 'CUT_AND_STACK',
  MIXED_REPEAT: 'MIXED_REPEAT',
  REPEAT_BY_COLUMN: 'REPEAT_BY_COLUMN',
  REPEAT_BY_ROW: 'REPEAT_BY_ROW',
  CUT_AND_STACK_BY_COLUMN: 'CUT_AND_STACK_BY_COLUMN',
  CUT_AND_STACK_BY_ROW: 'CUT_AND_STACK_BY_ROW'
};
exports.LayoutTypeLabelMap = {
  [exports.LayoutType.NO_IMPOSITION]: 'layout.layoutTypeNoImposition',
  [exports.LayoutType.ONE_UP]: 'layout.layoutTypeOneUp',
  [exports.LayoutType.MULTI_UP]: 'layout.layoutTypeMultipleUp',
  [exports.LayoutType.BOOKLET]: 'global.booklet',
  [exports.LayoutType.CROSSFOLD]: 'imposition.names.crossFold'
};
exports.MultiUpTypeLabelMap = {
  [exports.MultiUpType.UNIQUE]: 'layout.multipleUps.unique',
  [exports.MultiUpType.REPEAT]: 'layout.multipleUps.repeat',
  [exports.MultiUpType.REPEAT_BY_COLUMN]: 'layout.multipleUps.repeatByColumn',
  [exports.MultiUpType.REPEAT_BY_ROW]: 'layout.multipleUps.repeatByRow',
  [exports.MultiUpType.CUT_AND_STACK]: 'imposition.names.cutAndMarry',
  [exports.MultiUpType.CUT_AND_STACK_BY_COLUMN]: 'layout.multipleUps.cutAndStackByColumn',
  [exports.MultiUpType.CUT_AND_STACK_BY_ROW]: 'layout.multipleUps.cutAndStackByRow',
  [exports.MultiUpType.MIXED_REPEAT]: 'layout.multipleUps.mixedRepeat'
};
exports.ChapterSelectionType = {
  NONE: 'NONE',
  ALL_CHAPTERS: 'ALL_CHAPTERS',
  CHAPTER_RANGE: 'CHAPTER_RANGE'
};
exports.ChapterPagesSelectionType = {
  FIRST_PAGE: 'FIRST_PAGE',
  ODD: 'ODD',
  EVEN: 'EVEN',
  RANGE: 'RANGE',
  ALL: 'ALL',
  SLIP: 'SLIP'
};
exports.PrintBleedTabsOn = {
  FRONT_SIDES: 'FRONT_SIDES',
  BACK_SIDES: 'BACK_SIDES',
  FRONT_AND_BACK_SIDES: 'FRONT_AND_BACK_SIDES'
};
exports.BleedTabArrangement = {
  FRONT_TO_BACK: 'FRONT_TO_BACK',
  BACK_TO_FRONT: 'BACK_TO_FRONT'
};
exports.BleedTabSizeStrategy = {
  MANUAL: 'MANUAL',
  ALL_SAME_SIZE: 'ALL_SAME_SIZE',
  FIT_TO_TEXT: 'FIT_TO_TEXT',
  FIT_TO_TEXT_FILL_WHOLE_SPACE: 'FIT_TO_TEXT_FILL_WHOLE_SPACE'
};
exports.AutoBleedClippingType = {
  ON: 'ON',
  // clipping will be done 50/50 in the overlapping area (aka LI 1.6 behaviour)
  OFF: 'OFF',
  // //doesnt clip at all, the next slot will overlap the previous (aka LI 1.4 behaviour)
  AUTO: 'AUTO' //clips dynamically based on pdf boxes

};
exports.SignatureSheetInfoTypes = {
  PER_SHEET: 'PER_SHEET',
  PER_PAGE: 'PER_PAGE'
};
exports.RegisterMarkTypes = {
  ANGLE: 'ANGLE',
  RECTANGLE: 'RECTANGLE',
  CIRCLE: 'CIRCLE',
  CROSS: 'CROSS'
};
exports.SignatureRegisterMarkAlignTo = {
  SHEET: 'SHEET',
  PAGES: 'PAGES',
  ALL_PAGES: 'ALL_PAGES'
};
exports.RegisterMarkPosition = {
  TOP_LEFT: 'TOP_LEFT',
  TOP_RIGHT: 'TOP_RIGHT',
  BOTTOM_LEFT: 'BOTTOM_LEFT',
  BOTTOM_RIGHT: 'BOTTOM_RIGHT',
  TOP_CENTER: 'TOP_CENTER',
  RIGHT_CENTER: 'RIGHT_CENTER',
  BOTTOM_CENTER: 'BOTTOM_CENTER',
  LEFT_CENTER: 'LEFT_CENTER',
  AT_CORNERS: 'AT_CORNERS',
  AT_GUTTER: 'AT_GUTTER'
};
exports.PrintSheetInfoOn = {
  FRONT_SIDES: 'FRONT_SIDES',
  BACK_SIDES: 'BACK_SIDES',
  FRONT_AND_BACK_SIDES: 'FRONT_AND_BACK_SIDES',
  FIRST_SHEET_ONLY: 'FIRST_SHEET_ONLY'
};
exports.Placeholders = {
  PAGE_NUMBER: '$P',
  PAGE_COUNT: '$PC',
  DATE: '$D',
  TIME: '$T',
  TITLE: '$N',
  CHAPTER_TITLE: '$CT',
  CHAPTER_NUMBER: '$CN',
  ARTWORK_NUMBER: '$AN',
  ARTWORK_COUNT: '$AC',
  SHEET_SIDE: '$S',
  SHEET_NUMBER: '$NR',
  JOB_NUMBER: '$J',
  TITLE: '$N',
  SIGNATURE_NAME: '$SN'
};
exports.DrawingTypes = {
  LABEL: 'LABEL',
  BARCODE: 'BARCODE',
  IMAGE: 'IMAGE',
  RECT: 'RECT',
  ANGLE: 'ANGLE',
  LINE: 'LINE',
  CIRCLE: 'CIRCLE',
  CROSS: 'CROSS',
  SVG_ICON: 'SVG_ICON'
};
exports.BarcodeTypes = {
  // TODO this is almost unused and needs to be s subset of barcode PageLabelTypes
  QR_CODE: 'QR_CODE',
  BAR_CODE_39: 'BAR_CODE_39',
  BAR_CODE_128: 'BAR_CODE_128',
  USPS_IMB: 'USPS_IMB'
};
exports.DrawingSourceTypes = {
  PAGE_LABEL: 'PAGE_LABEL',
  BLEED_TABS: 'BLEED_TABS',
  TAB_SHEET: 'TAB_SHEET',
  SIGNATURE_BARCODE: 'SIGNATURE_BARCODE',
  SIGNATURE_REGISTER_MARK: 'SIGNATURE_REGISTER_MARK',
  SIGNATURE_SHEET_INFO: 'SIGNATURE_SHEET_INFO',
  ROLL_JOB_REGISTER_MARK: 'ROLL_JOB_REGISTER_MARK'
};
exports.PageLabelTypes = {
  TEXT: 'TEXT',
  QR_CODE: 'QR_CODE',
  BAR_CODE_39: 'BAR_CODE_39',
  BAR_CODE_128: 'BAR_CODE_128',
  USPS_IMB: 'USPS_IMB',
  // US Postal Service intelligent mail barcode
  IMAGES: 'IMAGES'
};
exports.MAX_SIGNATURE_ROW_COUNT = 20;
exports.MAX_SIGNATURE_COLUMN_COUNT = 20;