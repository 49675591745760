import { combineReducers } from 'redux';
import * as actionTypes from '../enums/action-types';

const groups = (state = [], action) => {
	switch (action.type) {
	case actionTypes.SET_GROUPS:
		return action.groups;
	default:
		return state;
	}
};

const portals = (state = [], action) => {
	switch (action.type) {
	case actionTypes.SET_PORTALS:
		return action.portals;
	default:
		return state;
	}
};

const products = (state = [], action) => {
	switch (action.type) {
	case actionTypes.SET_PRODUCTS:
		return action.products;
	default:
		return state;
	}
};

const productCategories = (state = [], action) => {
	switch (action.type) {
	case actionTypes.SET_PRODUCT_CATEGORIES:
		return action.productCategories;
	default:
		return state;
	}
};

const stockProducts = (state = [], action) => {
	switch (action.type) {
	case actionTypes.SET_STOCK_PRODUCTS:
		return action.stockProducts;
	default:
		return state;
	}
};

export default combineReducers({
	groups,
	portals,
	products,
	productCategories,
	stockProducts
});

