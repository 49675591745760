import React from 'react';
import { useSelector } from 'react-redux';
import { dialogsSelector } from 'dots-frontend/src/selectors/app';
import { deleteDialog as deleteDialogAction } from 'dots-frontend/src/actions/dialog';
import useActions from 'dots-react-components/src/hooks/use-actions';
import Dialog from 'dots-react-ui/src/components/dialog/dialog';
import useI18n from 'dots-react-ui/src/hooks/use-i18n';

const DialogContainer = () => {
	const dialogs = useSelector(dialogsSelector);
	const deleteDialog = useActions(deleteDialogAction);
	const i18n = useI18n();

	const handleOnConfirm = (id, onConfirm) => {
		if (typeof onConfirm === 'function') onConfirm();
		deleteDialog(id);
	}

	const handleOnCancel = (id, onCancel) => {
		if (typeof onCancel === 'function') onCancel();
		deleteDialog(id);
	}

	const handleOnExtra1 = (id, onExtra1) => {
		if (typeof onExtra1 === 'function') onExtra1();
		deleteDialog(id);
	}

	return (
		<>
			{dialogs.map(({ id, type, title, body, confirmLabel, cancelLabel, extra1Label, onConfirm, onCancel, onExtra1, confirmDisabled, confirmHidden }, index) => (
				<Dialog
					id={`dialog-${index}`}
					isOpen
					key={id}
					type={type}
					title={i18n.translate(title)}
					confirmLabel={i18n.translate(confirmLabel)}
					cancelLabel={i18n.translate(cancelLabel)}
					extra1Label={i18n.translate(extra1Label)}
					onConfirm={!confirmHidden ? () => handleOnConfirm(id, onConfirm) : undefined}
					onCancel={onCancel ? () => handleOnCancel(id, onCancel) : undefined}
					onExtra1={onExtra1 ? () => handleOnExtra1(id, onExtra1) : undefined}
					confirmDisabled={confirmDisabled}
				>
					{body}
				</Dialog>
			))}
		</>
	);
};

export default DialogContainer;
