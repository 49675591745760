module.exports = {
  arial: {
    name: 'Arial',
    fontStack: 'Arial, Helvetica Neue, Helvetica, sans-serif'
  },
  arialBlack: {
    name: 'Arial Black',
    fontStack: 'Arial Black, Arial Bold, Gadget, sans-serif'
  },
  baskerville: {
    name: 'Baskerville',
    fontStack: 'Baskerville, Baskerville Old Face, Hoefler Text, Garamond, Times New Roman, serif'
  },
  bookman: {
    name: 'Bookman',
    fontStack: 'Bookman, Book Antiqua, Palatino, Palatino Linotype, Palatino LT STD, Georgia, serif'
  },
  courier: {
    name: 'Courier',
    fontStack: 'Courier, Courier New, Lucida Sans Typewriter, Lucida Typewriter, monospace'
  },
  courierNew: {
    name: 'Courier New',
    fontStack: 'Courier New, Courier, Lucida Sans Typewriter, Lucida Typewriter, monospace'
  },
  garamond: {
    name: 'Garamond',
    fontStack: 'Garamond, Baskerville, Baskerville Old Face, Hoefler Text, Times New Roman, serif'
  },
  georgia: {
    name: 'Georgia',
    fontStack: 'Georgia, Times, Times New Roman, serif'
  },
  helvetica: {
    name: 'Helvetica',
    fontStack: 'Helvetica Neue, Helvetica, Arial, sans-serif'
  },
  impact: {
    name: 'Impact',
    fontStack: 'Impact, Haettenschweiler, Franklin Gothic Bold, Charcoal, Helvetica Inserat, Bitstream Vera Sans Bold, Arial Black, sans serif'
  },
  palatino: {
    name: 'Palatino',
    fontStack: 'Palatino, Palatino Linotype, Palatino LT STD, Book Antiqua, Georgia, serif'
  },
  tahoma: {
    name: 'Tahoma',
    fontStack: 'Tahoma, Verdana, Segoe, sans-serif'
  },
  timesNewRoman: {
    name: 'Times New Roman',
    fontStack: 'TimesNewRoman, Times New Roman, Times, Baskerville, Georgia, serif'
  },
  trebuchetMS: {
    name: 'Trebuchet MS',
    fontStack: 'Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif'
  },
  verdana: {
    name: 'Verdana',
    fontStack: 'Verdana, Geneva, sans-serif'
  }
};