if (typeof require === 'undefined') {
  Components.utils.import('resource://requireloc/dotsRequireModule.js');
  var module = {
    exports: Object.create(null)
  };
  Object.defineProperty(this, 'exports', {
    get: function get() {
      return module.exports;
    }
  });
  var EXPORTED_SYMBOLS = ['module'];
}
/**
 * @module lib/print-queue-enums.js
 * @description contains enumerations for print job states in print queue
 */


module.exports = {
  NEW: 'new',
  PAUSED: 'paused',
  FINISHED: 'finished',
  FAILED: 'failed',
  INVALID: 'invalid',
  ERROR: 'error',
  PREPARING: 'preparing',
  RUNNING: 'running',
  WAITING: 'waiting',
  TESTPRINT: 'testprint',
  CANCELLED: 'cancelled',
  PENDING_HELD: 'pending',
  // TODO: Differ held and pause
  PENDING_PAUSE: 'pending',
  UNKNOWN: 'unknown',
  // Missing data (f.e. SNMP device closed, network error, etc.)
  OFFLINE: 'offline',
  // Printer is offline,
  PROCESSING: 'processing',
  // job print file (PDF) is beeing processed
  HAS_PRINTER_PROBLEMS: 'hasPrinterProblems'
};