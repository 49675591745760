if (typeof require === 'undefined') {
  Components.utils.import('resource://requireloc/dotsRequireModule.js');
  var module = {
    exports: Object.create(null)
  };
  Object.defineProperty(this, 'exports', {
    get: function get() {
      return module.exports;
    }
  });
  var EXPORTED_SYMBOLS = ['module'];
}

exports.MINIMUM_SHEET_HEIGHT = 10;
exports.MAXIMUM_SHEET_HEIGHT = 5080;
exports.MINIMUM_SHEET_WIDTH = 10;
exports.MAXIMUM_SHEET_WIDTH = 5080;
exports.STANDARD_SHEET_WIDTH = 320;
exports.STANDARD_SHEET_HEIGHT = 480;
exports.BANNER_SHEET_HEIGHT = 1195;
exports.REGISTER_MARK_DEFAULT_WIDTH = 6;
exports.REGISTER_MARK_DEFAULT_HEIGHT = 5;
exports.COLUMN_DEFAULT_HORIZONTAL_MARGIN = 1;
exports.COLUMN_DEFAULT_VERTICAL_MARGIN = 1;
exports.MAX_COLUMN_COUNT = 50;
exports.MAX_ROW_COUNT = 50;
exports.MAX_ARTWORK_QUANTITY = 1000000;
exports.LayoutMode = {
  GRID: 'GRID',
  ALIGNED: 'ALIGNED',
  SEPARATE: 'SEPARATE',
  ROW_MODE: 'ROW_MODE'
};
exports.RollJobRegisterMarkAlignTo = {
  SHEET: 'SHEET',
  COLUMN: 'COLUMN'
};
exports.SheetHeightMode = {
  AUTO: 'AUTO',
  AUTO_BANNER: 'AUTO_BANNER',
  AUTO_STANDARD: 'AUTO_STANDARD',
  FIXED: 'FIXED'
};