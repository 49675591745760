import React from 'react';
import PropTypes from 'prop-types';
import guid from 'dots-guid';

import { Dialog } from '../../components/dialog';
import { Messages } from '../../components/messages';
import { Loader } from '../../components/loader';
import withI18n from '../../hoc/with-i18n';
import MessageContext from './message-context';

const notificationTypes = ['success', 'warning', 'error'];
const loaderTypes = ['loader'];

const LOADER_START_DELAY = 300;
const MIN_LOADER_PERIOD = 400; // NOTE: At least spinning 400ms.

class MessageProvider extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			messages: [],
			loading: false,
		};
		this.handleOnCreateMessage = this.handleOnCreateMessage.bind(this);
		this.handleOnDeleteMessage = this.handleOnDeleteMessage.bind(this);
		this.handleOnConfirm = this.handleOnConfirm.bind(this);
		this.handleOnCancel = this.handleOnCancel.bind(this);
		this.handleOnCreateLoader = this.handleOnCreateLoader.bind(this);
		this.handleOnDeleteLoader = this.handleOnDeleteLoader.bind(this);
		this.setLoading = this.setLoading.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.message) {
			const prevLoaderCnt = prevProps.messages.filter(m => m.type === 'loader').length;
			const loaderCnt = this.props.messages.filter(m => m.type === 'loader').length;
			
			if (prevLoaderCnt !== loaderCnt) {
				this.setLoading(LOADER_START_DELAY);
				this.setLoading(MIN_LOADER_PERIOD);
			}
		}
	}

	handleOnCreateMessage(message) {
		this.setState(prevState => ({
			messages: [].concat({ ...message, id: guid() }, prevState.messages),
		}));
		if (typeof this.props.onCreate === 'function') this.props.onCreate(message);
	}

	handleOnDeleteMessage(id) {
		this.setState(prevState => ({
			messages: prevState.messages.filter(message => message.id !== id),
		}), () => this.props.onDelete(id));
		// if (typeof this.props.onDelete === 'function') this.props.onDelete(id);
	}

	handleOnConfirm(id, onConfirm) {
		if (typeof onConfirm === 'function') onConfirm();
		this.handleOnDeleteMessage(id);
	}

	handleOnCancel(id, onCancel) {
		if (typeof onCancel === 'function') onCancel();
		this.handleOnDeleteMessage(id);
	}

	handleOnCreateLoader(id) {
		const message = { id, type: loaderTypes[0], time: new Date() };
		this.setState(prevState => ({
			messages: [].concat(message, prevState.messages),
		}), () => this.props.onCreate(message));
		this.setLoading(LOADER_START_DELAY);
	}

	handleOnDeleteLoader(id) {
		// console.log('handleOnDeleteLoader');
		this.handleOnDeleteMessage(id);
		this.setLoading(MIN_LOADER_PERIOD);
	}

	setLoading(delay = MIN_LOADER_PERIOD) {
		setTimeout(
			() => {
				const loading = (this.props.messages || this.state.messages).some(m => m.time && ((new Date() - m.time) > LOADER_START_DELAY))
				if (loading !== this.state.loading) this.setState({ loading });
			},
			delay,
		);
	}

	render() {
		const { children, i18n } = this.props;
		const messages = this.props.messages || this.state.messages;

		const dialogMessages = messages.filter(message => !(notificationTypes.includes(message.type) || loaderTypes.includes(message.type))); // TODO: Define types.
		const notificationMessages = messages.filter(message => notificationTypes.includes(message.type));
		const loaderMessages = messages.filter(message => loaderTypes.includes(message.type));

		// const loading = !!(loaderMessages && loaderMessages.length);
		const block = loaderMessages.find(loader => loader.block); // TODO: Review this.
		// const block = true;

		return (
			<React.Fragment>
				<MessageContext.Provider
					value={{
						createMessage: message => this.handleOnCreateMessage(message),
						createDialog: message => this.handleOnCreateMessage(message),
						createNotification: message => this.handleOnCreateMessage({ type: notificationTypes[0], ...message }),
						createLoader: id => this.handleOnCreateLoader(id),
						deleteLoader: id => this.handleOnDeleteLoader(id),
					}}
				>
					{children}
				</MessageContext.Provider>
				{dialogMessages.map(({ id, title, body, confirmLabel, cancelLabel, onConfirm, onCancel }, index) => (
					<Dialog
						id={`dialog-${index}`}
						isOpen
						key={id}
						title={i18n.translate(title)}
						confirmLabel={i18n.translate(confirmLabel)}
						cancelLabel={i18n.translate(cancelLabel)}
						onConfirm={() => this.handleOnConfirm(id, onConfirm)}
						onCancel={onCancel ? () => this.handleOnCancel(id, onCancel) : undefined}
					>
						{body}
					</Dialog>
				))}
				<Messages messages={notificationMessages} clearMessage={id => this.handleOnDeleteMessage(id)} />
				<Loader loading={this.state.loading} block={block} />
			</React.Fragment>
		);
	}
}


MessageProvider.propTypes = {
	children: PropTypes.node,
	messages: PropTypes.arrayOf(PropTypes.object),
	onCreate: PropTypes.func,
	onDelete: PropTypes.func,
	// onChange: PropTypes.func,
	i18n: PropTypes.object,
};

MessageProvider.defaultProps = {
	children: null,
	messages: null,
	onCreate: () => {},
	onDelete: () => {},
	// onChange: () => {},
	i18n: {
		language: 'en-US',
		translate: str => str,
	},
};

export default withI18n(MessageProvider);
