import * as app from './app';
import * as auth from './auth';
import * as config from './config';
import * as portalConfig from './portal-config';
import * as settings from './settings';

export default {
	app,
	auth,
	config,
	portalConfig,
	settings
};
