export const appHasLoadedSelector = state => state.app.appHasLoaded;
export const authSelector = state => state.app.auth;
export const dialogsSelector = state => state.app.dialogs;
export const errorRequestDialogsSelector = state => state.app.errorRequestDialogs;
export const editionSelector = state => state.app.edition;
export const isInitializedSelector = state => state.app.auth.isInitialized;
export const isLoggedInSelector = state => state.app.auth.identity.loggedIn;
export const identitySelector = state => state.app.auth.identity;
export const loadersSelector = state => state.app.loaders;
export const messagesSelector = state => state.app.messages;
