import { SSOTypes as SSOTypesEnums, AuthnTypeOptions } from 'dots-enums/src/flux-web-portal';

export const isUserLoginActive = (portal, defaultSystemSettings) => portal.active && defaultSystemSettings.lockWeb !== '1';

export const getActiveGroups = (portal, groups = []) => {
	const { groupIds, ssoType } = portal;
	return groups.filter(group => {
		if (!~groupIds.indexOf(group.id))
			return false;
		switch (ssoType) {
		case SSOTypesEnums.ON: return true;
		case SSOTypesEnums.ONLYSSO: return group.authnActive;
		default: return !group.authnActive || group.ldapActive;
		}
	});
};

export const isSsoActive = (portal = {}, groups = []) => {
	const { ssoType, authnType, samlLoginUrl } = portal;
	return (
		ssoType !== SSOTypesEnums.OFF && (authnType !== AuthnTypeOptions.SAML || samlLoginUrl)
			&& getActiveGroups(portal, groups).some(group => group.authnActive)
	);
};

export const isSsoOnlyLogin = (portal = {}, groups = []) => {
	return isSsoActive(portal, groups) && portal.ssoType === SSOTypesEnums.ONLYSSO;
};

export const getActiveGroupNames = (portal, groups) => {
	return getActiveGroups(portal, groups).map(group => group.name);
};

export const hasPasswordUsers = (portal, groups) => {
	const { ssoType, groupIds } = portal;
	if (ssoType === SSOTypesEnums.ONLYSSO){
		return false;
	}

	for (let groupId of groupIds) {
		const group = groups.find(g => g.id === groupId);
		if (group && !group.authnActive) {
			return true;
		}
	}
	return false;
};

export const getSelfRegGroup = (portal, groups) => {
	const { selfRegGroupId } = portal;
	var group = selfRegGroupId && groups.find(g => g.id === selfRegGroupId);
	return group && !group.authnActive ? group : null;
};

export const isSelfRegistrationActive = (portal, groups) => {
	const { groupIds, ssoType } = portal;
	const selfRegGroupId = getSelfRegGroup(portal, groups)?.id;
	return selfRegGroupId && groupIds.includes(selfRegGroupId) && ssoType !== SSOTypesEnums.ONLYSSO;
};

export const isLostPasswordActive = (portal, groups, settings) => hasPasswordUsers(portal, groups) && !!settings.email.SMTPServer;
