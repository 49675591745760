import locales from '../locale.json';
import { i18nUtils } from 'dots-shared/src/i18n-utils';
import moment from 'moment';
import 'moment/min/locales.min';

const locale = 'en-US';
const translationFunctions = new i18nUtils.TranslationFunctions(moment);
translationFunctions.setLocaleAndStrings(locale, locales[locale]);

export default {
	locale,
	locales,
	translate: translationFunctions.translate,
	getStringRepresentationForFileSize: translationFunctions.getStringRepresentationForFileSize,
};
