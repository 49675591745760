import { createSelector } from 'reselect';
import { groupsSelector } from './config';
import { isUserLoginActive, isSelfRegistrationActive, isLostPasswordActive } from '../utils/portal-settings';

// TODO: create a selector for the links base on the isPortalUrl();

export const appHasLoadedSelector = state => state.app.appHasLoaded;
export const CSRFTokenSelector = state => state.app.CSRFToken;
export const i18nStringsSelector = state => state.app.i18nStrings;
export const licenseSelector = state => state.app.license;
export const localeIdSelector = state => state.app.localeId;
export const portalSelector = state => state.app.portal;
export const dialogsSelector = state => state.app.dialogs;
export const errorRequestDialogsSelector = state => state.app.errorRequestDialogs;
export const loadersSelector = state => state.app.loaders;
export const messagesSelector = state => state.app.messages;

export const portalSettingsSelector = createSelector(
	[portalSelector, groupsSelector, state => state.settings.systemSettings, state => state.settings.defaultSystemSettings],
	(portal = {}, groups = [], systemSettings, defaultSystemSettings) => {
		const lostPasswordActive = isLostPasswordActive(portal, groups, systemSettings) && !portal?.hideForgotPassword;
		const selfRegistrationActive = isSelfRegistrationActive(portal, groups);
		const userLoginActive = isUserLoginActive(portal, defaultSystemSettings);

		return {
			lostPasswordActive,
			selfRegistrationActive,
			userLoginActive,
		};
	}
);
