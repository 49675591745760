// Copyright: © 2018 by dots Gesellschaft für Softwareentwicklung mbH, Germany. All rights reserved.
'use strict'; // HEADER to make require work by using Components.utils.import internally - DON'T TOUCH THIS, PLEASE

if (typeof require === 'undefined') {
  Components.utils.import('resource://requireloc/dotsRequireModule.js');
  var module = {
    exports: Object.create(null)
  };
  Object.defineProperty(this, 'exports', {
    get: function get() {
      return module.exports;
    }
  });
  var EXPORTED_SYMBOLS = ['module'];
} // END OF HEADER
// TODO use shared dots-utils, currently webpack is complaining about dots-utils.requireAll


function escapeRegExp(str) {
  return typeof str === 'string' ? str.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&') : '';
}

exports.nextName = function (requestedName, existingNames) {
  var noSpace = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

  var suffix = function suffix(n) {
    return noSpace ? '(' + n + ')' : ' (' + n + ')';
  };

  var baseNameRegexp = noSpace ? /(.+)\(([0-9]+)\)$/ : /(.+)\s\(([0-9]+)\)$/;
  var match = requestedName.match(baseNameRegexp);
  var baseName = match && match[1] && match[2] ? match[1] : requestedName;
  var isRelevantRegexp = noSpace ? new RegExp('^' + escapeRegExp(baseName) + '(\\([0-9]+\\))?$') : new RegExp('^' + escapeRegExp(baseName) + '(\\s\\([0-9]+\\))?$');
  var relevantNames = existingNames.filter(function (name) {
    return isRelevantRegexp.test(name);
  });
  var nextName = requestedName;
  var n = match && match[1] && match[2] ? Math.max(0, Number(match[2])) : 0;

  while (relevantNames.includes(nextName)) {
    n++;
    nextName = baseName + suffix(n);
  }

  existingNames.push(nextName);
  return nextName;
};