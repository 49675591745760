if (typeof require === 'undefined') {
  // eslint-disable-next-line no-undef
  Components.utils.import('resource://requireloc/dotsRequireModule.js');
  var module = {
    exports: Object.create(null)
  };
  Object.defineProperty(this, 'exports', {
    get: function get() {
      return module.exports;
    }
  });
  var EXPORTED_SYMBOLS = ['module'];
}

exports.DEFAULT_PRINTERSETTINGS_ID = '#defaultSettings#';
exports.CUSTOM_PRINTERSETTINGS_ID = '#customSettings#';
exports.MIXED_PRINTERSETTINGS_ID = '#mixedSettings#';
exports.ServiceIds = {
  PRINTMODE: 'DFX$PrintMode',
  REMOVE_PAGE_SPACE: 'DFX$RemovePageSpace',
  USE_EXCEPTIONS_PATTERN: 'Dfx$UseExceptionsPattern',
  NUM_SHEET_SIDES_IN_EXCEPTIONS_PATTERN: 'Dfx$NumSheetSidesInExceptionsPattern'
};
exports.OptionIds = {
  OFF: 'DFX$Off',
  ON: 'DFX$On'
};
exports.PrintMode = {
  HOLD: 'DFX$PrintMode_Hold',
  PRINT: 'DFX$PrintMode_Print'
};