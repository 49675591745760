import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// 'bg-white mx-4 md:mx-auto no-focus-outline relative rounded-lg shadow-md w-full md:w-5/6 lg:w-2/3 xl:w-1/2 xxl:max-w-lg z-20': type !== 'full-screen' && type !== 'full', // medium
const getModalClassName = (type, className) => classNames(
	'mx-auto no-focus-outline relative shadow-md',
	{
		'bg-white rounded-lg z-20': type !== 'full-screen' && type !== 'full', 
		'absolute bg-gray-lightest h-screen inset-0 z-100': type === 'full-screen' || type === 'full',
		'w-screen': type === 'full-screen',
		'u-modal-dialog-width': type === 'large',
		'u-modal-page-width': type === 'full',
		'w-11/12 md:w-5/6 lg:w-2/3 xl:w-1/2 xxl:max-w-lg': type === 'medium' || type === 'small' || type === 'dialog',
	}, 
	className,
);

const getModalStyles = (maxHeight, type) => {
	// maxWidth: 'calc(100vw - 4rem)';
	if (type === 'small') return {
		maxHeight: '80vh',
	};
	if (maxHeight) return {
		maxHeight: `${maxHeight}rem`,
	};
	// if (type === 'large') return {
	// 	maxWidth: 'calc(100vw - 8rem)', 
	// }
};

const ModalContainer = ({ children, className, onCancel, maxHeight, type }) => (
	<div
		data-test="modal-background"
		className="bg-gray-darkest flex fixed h-screen inset-0 items-center justify-center max-h-screen w-full z-100"
		onMouseDown={() => setTimeout(() => {typeof onCancel === "function" && onCancel()}, 1)}
		tabIndex="0"
	>
		<div
			data-test="modal-container"
			aria-describedby="modalDescription"
			aria-labelledby="modalTitle"
			aria-modal="true"
			className={getModalClassName(type, className)}
			style={{...getModalStyles(maxHeight, type)}}
			role="dialog"
			tabIndex="0"
		>
			<div data-test="modal-clickable-area" className="w-full" onMouseDown={e => e.stopPropagation()}>
				{children}
			</div>
		</div>
	</div>
);

ModalContainer.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	type: PropTypes.string,
	onCancel: PropTypes.func,
	maxHeight: PropTypes.number,
};

ModalContainer.defaultProps = {
	type: 'small',
	onCancel: () => {},
};

export default ModalContainer;
