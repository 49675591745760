import React from 'react';
import { useSelector } from 'react-redux';
import { deleteErrorRequestDialog as deleteErrorRequestDialogAction } from 'dots-frontend/src/actions/dialog';
import { errorRequestDialogsSelector } from 'dots-frontend/src/selectors/app';
import useActions from 'dots-react-components/src/hooks/use-actions';
import { ErrorBody } from 'dots-react-components/src/components/error-handling';
import Dialog from 'dots-react-ui/src/components/dialog/dialog';
import useI18n from 'dots-react-ui/src/hooks/use-i18n';

const ErrorRequestDialogContainer = () => {
	const dialogs = useSelector(errorRequestDialogsSelector);
	const deleteErrorRequestDialog = useActions(deleteErrorRequestDialogAction);
	const i18n = useI18n();

	const handleOnConfirm = (id, onConfirm) => {
		if (typeof onConfirm === 'function') onConfirm();
		deleteErrorRequestDialog(id);
	};

	const handleOnCancel = (id, onCancel) => {
		if (typeof onCancel === 'function') onCancel();
		deleteErrorRequestDialog(id);
	};

	const handleOnExtra1 = (id, onExtra1) => {
		if (typeof onExtra1 === 'function') onExtra1();
		deleteErrorRequestDialog(id);
	};

	return (
		<>
			{dialogs.map(({ id, type, title, error, confirmLabel, cancelLabel, extra1Label, onConfirm, onCancel, onExtra1, confirmDisabled, confirmHidden }, index) => (
				<Dialog
					id={`dialog-${index}`}
					isOpen
					key={id}
					type={type}
					title={i18n.translate(title)}
					confirmLabel={i18n.translate(confirmLabel)}
					cancelLabel={i18n.translate(cancelLabel)}
					extra1Label={i18n.translate(extra1Label)}
					onConfirm={!confirmHidden ? () => handleOnConfirm(id, onConfirm) : undefined}
					onCancel={onCancel ? () => handleOnCancel(id, onCancel) : undefined}
					onExtra1={onExtra1 ? () => handleOnExtra1(id, onExtra1) : undefined}
					confirmDisabled={confirmDisabled}
				>
					<ErrorBody error={error} />
				</Dialog>
			))}
		</>
	);
};

export default ErrorRequestDialogContainer;
