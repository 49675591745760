if (typeof require === 'undefined') {
  Components.utils.import('resource://requireloc/dotsRequireModule.js');
  var module = {
    exports: Object.create(null)
  };
  Object.defineProperty(this, 'exports', {
    get: function get() {
      return module.exports;
    }
  });
  var EXPORTED_SYMBOLS = ['module'];
}

module.exports = {
  NO_PREFLIGHT: "NO_PREFLIGHT",
  DEFAULT_PREFLIGHT: "DEFAULT_PREFLIGHT",
  DEFAULT_PROFILE_PRODUCT_ID: "DEFAULT_PROFILE_PRODUCT_ID" //used as "default profile" in preflight configuration, although it is not a preflight profile in the proper sense

};