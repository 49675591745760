let reroute = url => url;

export const setReroute = fn => reroute = fn;

export const getServerUrl = () => {
	const hostName = process.env.REACT_APP_SERVER_HOSTNAME;
	const port = process.env.REACT_APP_SERVER_PORT;

	if (!process.env.REACT_APP_SERVER_URL && (!hostName || !port)) {
		throw new Error('server-fetch not initialized properly');
	}
	const url = process.env.REACT_APP_SERVER_URL || `${hostName}:${port}`;
	return reroute(url);
};

// TODO: Customize those messages and apply in notification (Don't forget i18n) // FIXME: Don't forget i18n !
const codeMessage = {
	// 200: '!!OK',
	// 201: '!!Created',
	400: 'error_400_badRequest',
	401: 'ERROR_UNAUTHORIZED_ACTION',
	403: 'global.error.noRightsToUseThisFunction',
	404: 'error_404_notFound',
	500: 'internalServerError',
	503: 'login.serverInitializing',
};

const getErrorMessageFromResponse = res => res.clone().json().then(data => {
	if (data.error && data.error.message)
		return data.error.message;
	if (data.message)
		return data.message;
	return null;
// eslint-disable-next-line no-console
}).catch(err => console.error(err));

const getErrorDetailsFromResponse = res => res.clone().json().then(data => {
	if (data.error && data.error.details)
		return data.error.details;
	if (data.details)
		return data.details;
	return null;
// eslint-disable-next-line no-console
}).catch(err => console.error(err));

const checkStatus = async(res) => {
	if (res.status >= 200 && res.status < 300) {
		return res;
	}
	const error = new Error(codeMessage[res.status] || res.statusText);
	error.response = res;

	// Update error message if response.body provides it.
	try {
		const errorMessage = await getErrorMessageFromResponse(error.response);
		const errorDetails = await getErrorDetailsFromResponse(error.response);
		if (errorMessage)
			error.message = errorMessage;
		if (errorDetails)
			error.details = errorDetails;
	} catch (err) {
		// eslint-disable-next-line no-console
		console.error('Can not resolve request\'s response.');
	}

	throw error;
};

const checkError = (res) => {
	if (res && res.error) {
		const error = res.error;
		// const error = new Error(JSON.stringify(res.error, null, "\t"));
		error.response = res;
		// console.error(error.message);
		throw error;
	} else {
		return res;
	}
};

const errorHandler = (error) => {
	// TODO: Handle more diffent errors.
	if (!error.response)
		error.message = 'global.connectionError'; // TODO this seems useless
	throw error;
};

const fetchOnlyUrl = method => url =>
	fetch(`${getServerUrl()}/${url}`, {
		method,
		credentials: 'include',
	})
		.then(res => checkStatus(res))
		.then(res => res.json())
		.then(res => checkError(res))
		.catch(errorHandler);

const fetchWithBody = method => (url, body) =>
	fetch(`${getServerUrl()}/${url}`, {
		method,
		credentials: 'include',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(body),
	})
		.then(res => checkStatus(res))
		.then(res => res.json())
		.then(res => {
			if (method === 'PUT' && !res)
				throw new Error('error.itemDoesNotExistAnymore');
			return res;
		})
		.then(res => checkError(res))
		.catch(errorHandler);

const fetchBlobWithBody = method => (url, body) =>
	fetch(`${getServerUrl()}/${url}`, {
		method,
		credentials: 'include',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(body),
	})
		.then(res => checkStatus(res))
		.then(res => res.blob())
		.catch(errorHandler);

const fetchWithFile = method => (url, file) =>
	fetch(`${getServerUrl()}/${url}`, {
		method,
		credentials: 'include',
		body: file,
	})
		.then(res => checkStatus(res))
		.then(res => res.json())
		.then(res => checkError(res))
		.catch(errorHandler);

export const get = fetchOnlyUrl('GET');
export const del = fetchOnlyUrl('DELETE'); // 'delete' is reserved word.
export const post = fetchWithBody('POST');
export const put = fetchWithBody('PUT');
export const patch = fetchWithBody('PATCH');

export const postRpc = (method, params) => post(method, {
	id: Date.now(),
	jsonrpc: '2.0',
	method,
	params,
});

export const postBlobWithBody = fetchBlobWithBody('POST');

export const postFile = (method, file) => fetchWithFile('POST')(method, file);

let lastId = 0;
export const postRpcSync = (method, params) => {
	const body = JSON.stringify({
		id: ++lastId,
		method: method,
		params: params || {},
	});

	const request = new XMLHttpRequest();
	request.open('POST', getServerUrl() + '/jsonrpc.web', false);
	request.setRequestHeader('Content-Type', 'application/json');
	request.send(body);
};

export const postFormUrlEncoded = (url, body) =>
	fetch(`${getServerUrl()}/${url}`, {
		method: 'POST',
		headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
		body
	})
		.then(res => checkStatus(res))
		.then(res => {
			if (res.ok) {
				// Ajax methods on Flux-Web just return the statuscode
				return res;
			}
			return res.json();
		})
		.then(res => checkError(res))
		.catch(errorHandler);

export const postFormData = (url, body) =>
	fetch(`${getServerUrl()}/${url}`, {
		method: 'POST',
		body
	})
		.then(res => checkStatus(res))
		.then(res => {
			if (res.ok) {
				// Ajax methods on Flux-Web just return the statuscode
				return res;
			}
			return res.json();
		})
		.then(res => checkError(res))
		.catch(errorHandler);

export const fetchImage = (url) =>
	fetch(`${getServerUrl()}/${url}`, {
		credentials: 'include',
		headers: { 'Content-Type': 'image/png' },
		method: 'get',
	})
		.then(res => checkStatus(res))
		.then(res => res.blob())
		.catch(errorHandler);

export const fetchPdf = (url) =>
	fetch(`${getServerUrl()}/${url}`, {
		credentials: 'include',
		headers: { 'Content-Type': 'application/pdf' },
		method: 'get',
	})
		.then(res => checkStatus(res))
		.then(res => res.blob())
		.catch(errorHandler);

export const fetchPlainText = (url) => fetch(`${getServerUrl()}/${url}`, {
	credentials: 'include',
	headers: { 'Content-Type': 'plain/text' },
	method: 'get',
})
	.then(res => checkStatus(res))
	.then(res => res.text())
	.catch(errorHandler);
