if (typeof require === 'undefined') {
  Components.utils.import('resource://requireloc/dotsRequireModule.js');
  var module = {
    exports: Object.create(null)
  };
  Object.defineProperty(this, 'exports', {
    get: function get() {
      return module.exports;
    }
  });
  var EXPORTED_SYMBOLS = ['module'];
}
/**
 * @module lib/print-technologies.js
 * @description contains enumerations for printing technologies (winspool, ipp, etc.)
 */


module.exports = {
  WINSPOOL: 'winspool',
  IPP: 'ipp',
  ACCOUNTING: 'accounting'
};