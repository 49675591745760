import { FluxWebPortal as FluxWebPortalEnums } from 'dots-enums';

const {
	DashboardContentAreas,
	LoginContentAreas,
	LoginInformationColumnTypes
} = FluxWebPortalEnums;

export const bodyToServer = (portal, CSRFToken, logo, action) => {
	// add the new props here
	const {
		id,
		dashboard = [],
		footer,
		loginPage,
		theme,
		clone,
		rename,
		cookiesSettings
	} = portal;

	const body = [
		// provide create to backend
		action && `${action}=${!!action}`,
		// On clone need to give portal and clone
		clone && `&clone=${encodeURIComponent(clone.trim())}`,
		rename && `&rename=${encodeURIComponent(rename.trim())}`,
		`&id=${id}`,
		`&CSRFToken=${CSRFToken}`,
		// groups
		`&groupIds=${encodeURIComponent(JSON.stringify(portal.groupIds))}`,
		`&selfRegGroupId=${portal.selfRegGroupId}`,
		`&ssoType=${portal.ssoType}`,
		`&hideForgotPassword=${portal.hideForgotPassword}`,
		`&authnType=${portal.authnType}`,
		`&authnUserAttr=${portal.authnUserAttr}`,
		`&ssoLogoutUrl=${encodeURIComponent(portal.ssoLogoutUrl)}`,
		`&samlLoginUrl=${encodeURIComponent(portal.samlLoginUrl)}`,
		`&samlSpId=${encodeURIComponent(portal.samlSpId)}`,
		`&samlPublicCert=${encodeURIComponent(portal.samlPublicCert)}`,
		`&ssoAttributes=${encodeURIComponent(JSON.stringify(portal.ssoAttributes))}`,
		// Dashboard
		`&dashboard=${encodeURIComponent(JSON.stringify(dashboard))}`,
		// Portal
		`&portal=${encodeURIComponent(portal.name.trim())}`,
		`&portalActive=${portal.active ? 1 : 0}`,
		`&webName=${encodeURIComponent(portal.webName)}`,
		`&webNameText=${encodeURIComponent(portal.webNameText)}`,
		logo && `&logo=${logo}`,
		// Theme
		`&theme=${encodeURIComponent(JSON.stringify(theme))}`,
		// Login
		`&loginPage=${encodeURIComponent(JSON.stringify(convertLoginPageToServer(loginPage)))}`,
		`&cookiesSettings=${encodeURIComponent(JSON.stringify(cookiesSettings))}`,
		// Footer props
		`&footer=${encodeURIComponent(JSON.stringify(footer))}`,
	].filter(param => !!param).join('');
	return body;
};

export const convertLoginPageToServer = loginPage => {
	const contentAreasToServer = loginPage.contentAreas.map(contentArea => {
		if (contentArea.type === LoginContentAreas.INFO_CONTAINER) {
			return {
				...contentArea,
				data : {
					...contentArea.data,
					columns: contentArea.data.columns.map(column => {
						// User can change between text and images on information container
						// Need to keep previou selection, in case user toggle between then
						// Clean up and keep just the selected to save on server
						if (column.type === 'text') {
							return {
								type: column.type,
								text: column.text,
								visible: column.visible
							}
						} else if (column.type === 'image') {
							return {
								type: column.type,
								src: column.src,
								title: column.title,
								description: column.description,
								visible: column.visible
							}
						}
						return column;
					})
				}
			}
		}
		return contentArea;
	});
	return { ...loginPage, contentAreas: contentAreasToServer };
};


export const getContentAreaStateAndResources = contentArea => {
	const resources = [];
	let state = contentArea;

	switch (contentArea.type) {
	case LoginContentAreas.HERO_SLIDER: {
		const images = contentArea.data.images.map(imageItem => {
			if (imageItem.file) {
				resources.push({
					type: 'background-image',
					file: imageItem.file
				});
				const { urlObject, file, ...rest } = imageItem;
				// not all images goes to pic_large
				return { ...rest, src: `/data/img/backgrounds/pic_large/${imageItem.file.name}` };
			}
			return imageItem;
		});
		state = {
			...contentArea,
			data: {
				...contentArea.data,
				images
			}
		};
		break;
	}
	case LoginContentAreas.INFO_CONTAINER: {
		const columns = contentArea.data.columns.map(column => {
			if (column.type === LoginInformationColumnTypes.IMAGE) {
				if (column.file) {
					resources.push({
						type: 'background-image',
						file: column.file
					});
					const { urlObject, file, ...rest } = column;
					return { ...rest, src: `/data/img/backgrounds/pic_large/${column.file.name}` };
				}
			}
			return column;
		});
		state = {
			...contentArea,
			data: {
				...contentArea.data,
				columns
			}
		};
		break;
	}
	default: break;
	}
	return { state, resources };
};

export const convertDashboardToServer = dashboard => {
	// Header
	let userWelcomeTitleActive;
	let userWelcomeTitle;

	// InfoBox
	let userIntroActive;
	let userWelcomeActive;
	let userWelcome;
	let userInfoActive;
	let userInfo;

	// Product
	let userDashboardCategoryActive;
	let userDashboardCategory;
	let userDashboardCategoryHeading;

	// Sidebar
	let userSidebarActive;
	let userSidebar;

	// Help
	let furtherInformationActive;
	let userSidebarHelpActive;
	let userSidebarHelp;

	(dashboard.contentAreas || []).forEach(contentArea => {
		switch (contentArea.id) {
			case (DashboardContentAreas.HEADER): {
				userWelcomeTitleActive = contentArea.visible;
				userWelcomeTitle = contentArea.data.text;
				break;
			} case (DashboardContentAreas.INFO_BOX): {
				userIntroActive = contentArea.visible && (contentArea.data[0].visible || contentArea.data[1].visible);
				userWelcome = contentArea.data[0].text;
				userWelcomeActive = contentArea.data[0].visible;
				userInfo = contentArea.data[1].text;
				userInfoActive = contentArea.data[1].visible;
				break;
			} case (DashboardContentAreas.PRODUCT_CATEGORY): {
				userDashboardCategoryActive = contentArea.visible;
				userDashboardCategory = contentArea.data.productCategory;
				userDashboardCategoryHeading = contentArea.data.text;
				break;
			} case (DashboardContentAreas.FURTHER_INFORMATION): {
				furtherInformationActive = contentArea.visible && (contentArea.data[0].visible || contentArea.data[1].visible);
				userSidebarActive = contentArea.data[0].visible;
				userSidebar = contentArea.data[0].text;
				userSidebarHelpActive = contentArea.data[1].visible;
				userSidebarHelp = contentArea.data[1].text;
				break;
			}
			default: break;
		}
	});


	return {
		// Title
		userWelcomeTitle,
		userWelcomeTitleActive,

		// Info Box
		userIntroActive,
		userWelcomeActive,
		userWelcome,
		userInfoActive,
		userInfo,

		// Products
		userDashboardCategoryActive,
		userDashboardCategory,
		userDashboardCategoryHeading,

		// Further Info
		furtherInformationActive,
		userSidebarActive,
		userSidebar,
		userSidebarHelpActive,
		userSidebarHelp,
	};
}


