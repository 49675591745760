/* eslint-disable quotes */
if (typeof require === 'undefined') {
  // eslint-disable-next-line no-undef
  Components.utils.import('resource://requireloc/dotsRequireModule.js');
  var module = {
    exports: Object.create(null)
  };
  Object.defineProperty(this, 'exports', {
    get: function get() {
      return module.exports;
    }
  });
  var EXPORTED_SYMBOLS = ['module'];
} // Enums must follow this naming scheme: ./src/file-name.js -> FileName (lower-case with dashes to UpperCamelCase)


var ServiceNames = require("./src/service-names");

var Editor = require("./src/editor"); // workstation: must not use different paths to require the same module, so pass ServiceNames into Editor


Editor.init(ServiceNames);
module.exports = {
  "AccurioProFluxEditionNames": require("./src/accurio-pro-flux-edition-names"),
  "AddressFields": require("./src/address-fields"),
  "AddressTypes": require("./src/address-types"),
  "AppTypes": require("./src/app-types"),
  "AuthStatus": require("./src/auth-status"),
  "ClientNames": require("./src/client-names"),
  "ClientTypes": require("./src/client-types"),
  "Cluster": require("./src/cluster"),
  "ColorManagement": require("./src/color-management"),
  "ColorSplitPrinter": require("./src/color-split-printer"),
  "ConfigurationNames": require("./src/configuration-names"),
  "CustomRoutes": require("./src/custom-routes"),
  "DashboardContainers": require("./src/dashboard-containers"),
  "DeliveryTypeDayTypes": require("./src/delivery-type-day-types"),
  "DetailedErrorTypes": require("./src/detailed-error-types"),
  "DialogTypes": require("./src/dialog-types"),
  "Editor": Editor,
  "EventNames": require("./src/event-names"),
  "ExportFormats": require("./src/export-formats"),
  "FolderNames": require("./src/folder-names"),
  "FilePreAndAffixes": require('./src/file-pre-and-affixes'),
  "FluxWebPortal": require('./src/flux-web-portal'),
  "HarmonyContentTypes": require("./src/harmony-content-types"),
  "HotfolderTypes": require("./src/hotfolder-types"),
  "ImportTypes": require("./src/import-types"),
  "Imposition": require("./src/imposition"),
  "RollJobImposition": require("./src/roll-job-imposition"),
  "ImpositionSchemes": require("./src/imposition-schemes"),
  "JobDetailsTabTypes": require("./src/job-details-tab-types"),
  "JoblistToolbarItems": require("./src/joblist-toolbar-items"),
  "JobticketVersions": require("./src/jobticket-versions"),
  "ListSorting": require("./src/list-sorting"),
  // TODO: USE PLURAL
  "LockNamePrefixes": require('./src/lock-name-prefixes'),
  "MfpConfig": require("./src/mfp-config"),
  "ModuleNames": require("./src/module-names"),
  "NotificationTypes": require("./src/notification-types"),
  "OrderItemFilterTypes": require("./src/order-item-filter-types"),
  "OrderItemHistoryTypes": require("./src/order-item-history-types"),
  "OrderItemSaveStatus": require("./src/order-item-save-status"),
  "OrderItemStatus": require("./src/order-item-status"),
  "OrderItemTransactionTypes": require("./src/order-item-transaction-types"),
  "OrderItemTypes": require("./src/order-item-types"),
  "OrderPrice": require("./src/order-price"),
  "OutputConnectors": require("./src/output-connectors"),
  "OverallStatus": require("./src/overall-status"),
  "PageNames": require("./src/page-names"),
  "PreflightEngines": require("./src/preflight-engines"),
  "PreflightModes": require("./src/preflight-modes"),
  "PreflightProfiles": require("./src/preflight-profiles"),
  "PreflightStatus": require("./src/preflight-status"),
  "Preview": require("./src/preview"),
  "PrintJobFilterTypes": require("./src/print-job-filter-types"),
  "PrintJobFormats": require("./src/print-job-formats"),
  "PrintJobPrinterProblems": require("./src/print-job-printer-problems"),
  "PrintJobStatus": require("./src/print-job-status"),
  "PrintRangeFilter": require('./src/print-range-filter'),
  "PrintRanges": require('./src/print-ranges'),
  "PrinterClusterBannerPageModes": require("./src/printer-cluster-banner-page-modes"),
  "PrinterClusterTypes": require("./src/printer-cluster-types"),
  "PrinterContexts": require("./src/printer-contexts"),
  "PrinterErrors": require("./src/printer-errors"),
  "PrinterModels": require("./src/printer-models"),
  "PrinterResourceTypes": require("./src/printer-resource-types"),
  "PrinterServiceIds": require("./src/printer-service-ids"),
  "PrinterSettings": require("./src/printer-settings"),
  "PrinterStatus": require("./src/printer-status"),
  "Printers": require("./src/printers"),
  "PrintqueueToolbarItems": require('./src/printqueue-toolbar-items'),
  "PrintOptions": require("./src/print-options"),
  "ProductionTimePeriods": require("./src/production-time-periods"),
  "RangeTypes": require("./src/range-types"),
  "ServiceConflicts": require("./src/service-conflicts"),
  "ServiceDisplayTypes": require("./src/service-display-types"),
  "ServiceGroups": require("./src/service-groups"),
  "ServiceNames": ServiceNames,
  "ServiceOptions": require("./src/service-options"),
  "SettingNames": require("./src/setting-names"),
  "SharedFrontendUtils": require('./src/shared-frontend-utils'),
  "SortOrder": require('./src/sort-order'),
  "Units": require('./src/units'),
  "UploadStatus": require("./src/upload-status"),
  "UserRights": require("./src/user-rights"),
  "UserRoleIds": require("./src/user-role-ids"),
  "WebPreflightResults": require("./src/web-preflight-results"),
  "WidgetTypes": require("./src/widget-types"),
  "WorkStepMeanings": require("./src/work-step-meanings"),
  "WorkSteps": require("./src/work-steps"),
  "BarcodeFontNames": require('./src/barcode-font-names')
};