if (typeof require === 'undefined') {
  // eslint-disable-next-line no-undef
  Components.utils.import('resource://requireloc/dotsRequireModule.js');
  var module = {
    exports: Object.create(null)
  };
  Object.defineProperty(this, 'exports', {
    get: function get() {
      return module.exports;
    }
  });
  var EXPORTED_SYMBOLS = ['module'];
}

module.exports = {
  //Keys in options object
  RANGE: 'rangeInfo',
  IS_TEST_PRINT: 'isTestPrint',
  IS_AUTO_PRINT: 'isAutoprint',
  SAVE_TO_PRINT_FILE: 'saveToPrintFiles',
  FILE_NAME: 'fileName',
  COPIES: 'copies',
  COLOR_SPLIT_PRINTER: 'colorSplitPrinter',
  //for roll robs
  PRINT_TO_HOLD_QUEUE: 'printToHoldQueue',
  COLOR_LAYER_SETTINGS: 'colorLayerSettings',
  PRINT_DIECUT: 'printDiecut',
  //for (roll job) export
  SELECTED_REGISTER_MARK_ELEMENT_INDICES: 'selectedRegisterMarkElementIndices',
  //array with indices
  SELECTED_SLOT_INDICES: 'selectedSlotIndices',
  //array with indices
  REMOVE_PAGE_SPACE: 'removePageSpace',
  //boolean
  FILE_FORMAT: 'fileFormat',
  //ExportFormats
  //PRINT_DIECUT member
  PRINT_DIECUT_BEFORE_AFTER_LENGTH: 'printDiecutLength',
  PRINT_DIECUT_BEFORE_AFTER_TYPE: 'printDiecutBeforeAfterType',
  //END PRINT_DIECUT
  //PRINT_DIECUT_BEFORE_AFTER_TYPE
  PRINT_DIECUT_BEFORE: 'printDiecutBefore',
  PRINT_DIECUT_AFTER: 'printDiecutAfter',
  PRINT_DIECUT_BEFORE_AND_AFTER: 'printDiecutBeforeAndAfter',
  //END PRINT_DIECUT_BEFORE_AFTER_TYPE
  //COLOR_LAYER_SETTINGS member
  BASE_COLOR_ENABLED: 'baseColorEnabled',
  //boolean
  FINISHING_ENABLED: 'finishingEnabled',
  //boolean
  DIE_LINE_ENABLED: 'dieLineEnabled',
  //boolean
  REGISTER_MARKS_ENABLED: 'registerMarksEnabled',
  //boolean
  DISABLED_SPOTCOLORS: 'disabledSpotColors' //array with disabled spot color names

};