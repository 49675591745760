import { createSelector } from 'reselect';
import { groupsSelector } from './config';
import { isUserLoginActive, isSelfRegistrationActive, isLostPasswordActive } from '../utils/portal-settings';

export const commandListSelector = state => state.portalConfig.commandList;
export const currentCommandSelector = state => state.portalConfig.currentCommand;
export const editingPortalSelector = state => state.portalConfig.editingPortal;
export const isChangedSelector = state => state.portalConfig.isChanged;
export const portalCountLimitSelector = state => state.portalConfig.portalCountLimit;
export const isPortalLimitReachedSelector =  createSelector(
	[state => state.config.portals, editingPortalSelector, portalCountLimitSelector],
	(portals, editingPortal, portalCountLimit) => {
		const currentPortalActive = editingPortal && editingPortal.active;
		const activeCount = portals.filter(portal => {
			if (!editingPortal.clone) {
				return portal.active && editingPortal.name !== portal.name;
			}
			return portal.active;
		}).length + currentPortalActive;
		return activeCount >= portalCountLimit;
	}
);

export const openContentSelector = state => state.portalConfig.openContent;
export const openGroupSelector = state => state.portalConfig.openGroup;
export const originalPortalSelector = state => state.portalConfig.originalPortal;
export const selectedTabSelector = state => state.portalConfig.selectedTab;

export const editingPortalSettingsSelector = createSelector(
	[editingPortalSelector, groupsSelector, state => state.settings.systemSettings, state => state.settings.defaultSystemSettings],
	(portal, groups, systemSettings, defaultSystemSettings) => {
		if (!portal)
			return {};

		const userLoginActive = isUserLoginActive(portal, defaultSystemSettings);
		const displayLostPasswordWarning = !portal.hideForgotPassword && !isLostPasswordActive(portal, groups, systemSettings);
		const lostPasswordActive = !portal.hideForgotPassword && isLostPasswordActive(portal, groups, systemSettings);
		const selfRegistrationActive = isSelfRegistrationActive(portal, groups);

		return {
			displayLostPasswordWarning, // forgot password is assigned, but don't display because not having email setup or sso group assigned
			lostPasswordActive,
			selfRegistrationActive,
			userLoginActive,
		};
	}
);