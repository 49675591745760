import { get, post, del, postFormUrlEncoded } from 'dots-frontend/src/server-fetch';
import { getApiBaseName } from '../utils/url';

export const getCurrentPortal = async() => await get(`${getApiBaseName()}portal-data`);

export const getPortalByName = async(portal) => await get(`${getApiBaseName()}portal-data/?portal=${encodeURIComponent(portal)}`);

export const getPortals = async() => await get(`${getApiBaseName()}portal-data/index`);

export const getPortalEditorDefaultData = async() => await get(`${getApiBaseName()}portal-data/default`);

export const getPortalEditorData = async(portal) => await get(`${getApiBaseName()}portal-data/editor${portal ? `?portal=${encodeURIComponent(portal)}` : ''}`);

export const updatePortal = async(body) => await postFormUrlEncoded(`${getApiBaseName()}configPortalDetails.web`, body);

export const getCssContentByPortalId = async(id, type) => await get(`${getApiBaseName()}portal-data/css-content/${encodeURIComponent(id)}${type ? '/' + type : ''}`);

export const upsertPortalCssById = async(id, cssContent, loginCssContent) => await post(`${getApiBaseName()}portal-data/css-content`, { id, cssContent, loginCssContent });

export const clonePortalCss = async(portalId, originalPortalId) => await post(`${getApiBaseName()}portal-data/css-content/clone`, { portalId, originalPortalId });

export const deletePortalCssById = async(id, type) => await del(`${getApiBaseName()}portal-data/css-content/${id}${type ? '/' + type : ''}`);

export const getSocialMediaIcons = async() => await get(`${getApiBaseName()}portal-data/social-media-list`);

