import * as actionTypes from '../enums/action-types';
import * as authApis from '../apis/auth';

const setAuthStatus = authStatus => ({ type: actionTypes.SET_AUTH_STATUS, authStatus });

export const setUserSession = userSession => ({ type: actionTypes.SET_USER_SESSION, userSession });

export const clearUserSession = () => ({ type: actionTypes.CLEAR_USER_SESSION });

export const login = ({ username, password }) => async(dispatch) => {
	const { authStatus } = await authApis.login(username, password);
	dispatch(setAuthStatus(authStatus));
};