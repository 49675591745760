if (typeof require === 'undefined') {
  Components.utils.import('resource://requireloc/dotsRequireModule.js');
  var module = {
    exports: Object.create(null)
  };
  Object.defineProperty(this, 'exports', {
    get: function get() {
      return module.exports;
    }
  });
  var EXPORTED_SYMBOLS = ['module'];
}

module.exports = {
  PAPERTYPE_CONFLICT: 'PAPERTYPE_CONFLICT',
  OPTION_CONSTRAINT: 'OPTION_CONSTRAINT',
  PRINTER_CONSTRAINTS: 'PRINTER_CONSTRAINTS',
  SERVICE_NOT_AVAILABLE: 'SERVICE_NOT_AVAILABLE',
  OPTION_NOT_AVAILABLE: 'OPTION_NOT_AVAILABLE',
  SERVICE_NOT_SUPPORTED: 'SERVICE_NOT_SUPPORTED',
  OPTION_NOT_SUPPORTED: 'OPTION_NOT_SUPPORTED',
  OPTION_NOT_SUPPORTED_BY_SELECTED_PRINTER: 'OPTION_NOT_SUPPORTED_BY_SELECTED_PRINTER',
  OPTION_NOT_SUPPORTED_BY_SELECTED_CLUSTER: 'OPTION_NOT_SUPPORTED_BY_SELECTED_CLUSTER'
};