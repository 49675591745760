import * as actionTypes from '../../constants/action-types';

const dialogs = (state = [], action) => {
	switch(action.type) {
		case actionTypes.CREATE_DIALOG:
			return state.concat(action.dialog);
		case actionTypes.DELETE_DIALOG:
			return state.filter(dialog => dialog.id !== action.id);
		case actionTypes.CLEAR_DIALOGS:
		case actionTypes.CLEAR_DATA:
			return [];
		default:
			return state;
	}
};

export default dialogs;
