if (typeof require === 'undefined') {
  // eslint-disable-next-line no-undef
  Components.utils.import('resource://requireloc/dotsRequireModule.js');
  var module = {
    exports: Object.create(null)
  };
  Object.defineProperty(this, 'exports', {
    get: function get() {
      return module.exports;
    }
  });
  var EXPORTED_SYMBOLS = ['module'];
}

module.exports = {
  // Operative rights
  MANAGE_FOLDERS: 'manage folders',
  EDIT_JOBS: 'edit jobs',
  DELETE_JOBS: 'delete jobs',
  ASSIGN_JOBS: 'assign jobs',
  // SEE_UNASSIGNED_JOBS: 'see unassigned jobs',
  // SEE_FOREIGN_JOBS: 'see foreign jobs',
  // Administrative rights
  EDIT_CONFIGURATION: 'edit configuration',
  EDIT_USERS: 'edit users',
  EDIT_SYSTEM: 'edit system'
};