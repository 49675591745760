import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

const Portal = ({ children, container }) => {
	if (typeof document === 'undefined') return children;
	// if modal has the focus trap, should append the portal into the modal div

	let modalNode = null;
	const modals = document.querySelectorAll("[id='modal-wrapper']");
	if (modals) {
		modalNode = modals[modals.length - 1];
	}
	const node = (container instanceof HTMLElement) ? container : modalNode || document.body; // NOTE: Prevent crash.
	return ReactDOM.createPortal(children, node);
};

Portal.defaultProps = {
	children: null,
	container: null,
};

Portal.propTypes = {
	children: PropTypes.node,
	container: typeof HTMLElement !== 'undefined' ? PropTypes.instanceOf(HTMLElement) : undefined,
};

export default Portal;
