import React from 'react';
import PropTypes from 'prop-types';
import { withModalCount } from '../modal-count';
import FocusTrap from 'focus-trap-react';
// import ReactDOM from 'react-dom';
import { Portal } from '../portal';
import autoFocus from '../../utils/auto-focus';

const modalRoot = typeof document !== 'undefined' ? (document.getElementById('modal') || document.body) : null;

class ModalWrapper extends React.Component {
	constructor(props) {
		super(props);
		this.handleKeyDown = this.handleKeyDown.bind(this);
		this.el = typeof document !== 'undefined' ? document.createElement('div') : null;
	}

	componentDidMount() {
		// // Remove scroll from the document. Scroll just work inside the modal.
		// document.body.style.overflow = 'hidden';
		modalRoot.appendChild(this.el);
		setTimeout(() => {
			if (this.props.autoFocus) {
				autoFocus(modalRoot);
			} else {
				this.el.focus();
			}
		}, 0);

		const { onOpen } = this.props;
		onOpen && onOpen();
		this.props.addModal && this.props.addModal();
	}

	componentWillUnmount() {
		// // Returns scroll to the document only if is the last modal.
		// const index = Array.prototype.indexOf.call(modalRoot.childNodes, this.el);
		// if (index === 0) {
		// 	document.body.style.overflow = 'auto';
		// }
		modalRoot.removeChild(this.el);
		this.props.removeModal && this.props.removeModal();
	}

	handleKeyDown(e) {
		e.stopPropagation();
		const index = Array.prototype.indexOf.call(modalRoot.childNodes, this.el);
		if (modalRoot.childNodes.length === index + 1 && (document.activeElement.tagName !== 'INPUT' || document.activeElement.tagName !== 'TEXTAREA')) {
			if ((e.key === 'Esc' || e.key === 'Escape') && !!this.props.onCancel) this.props.onCancel();
			// Prevents enter key fires when the key target it's a button;
			if (e.target.nodeName === 'BUTTON') return;
			if (e.key === 'Enter' && !!this.props.onConfirm) this.props.onConfirm();
			if (e.key === 'Enter' || e.key === 'Esc' || e.key === 'Escape') e.preventDefault();
		}
	}

	render() {
		const { children, modalCount } = this.props;
		const index = modalRoot ? Array.prototype.indexOf.call(modalRoot.childNodes, this.el) : 0;
		const isActive = index + 1 === modalCount;

		return (
			<Portal container={this.el}>
				<FocusTrap active={isActive}>
					<div
						id="modal-wrapper"
						data-test="modal-wrapper"
						className="font-normal font-sans leading-normal select-none text-base text-black"
						onKeyDown={this.handleKeyDown}
					>
						{children}
					</div>
				</FocusTrap>
			</Portal>
		);
	}
}

ModalWrapper.propTypes = {
	autoFocus: PropTypes.bool,
	children: PropTypes.node,
	onCancel: PropTypes.func,
	onConfirm: PropTypes.func,
	onOpen: PropTypes.func
};

ModalWrapper.defaultProps = {
	autoFocus: true,
	children: null,
	onCancel: () => {},
	onConfirm: () => {},
	onOpen: () => {}
};

const ModalWrapperWithCounter = withModalCount(ModalWrapper);

export default ModalWrapperWithCounter;
