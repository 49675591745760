if (typeof require === 'undefined') {
  // eslint-disable-next-line no-undef
  Components.utils.import('resource://requireloc/dotsRequireModule.js');
  var module = {
    exports: Object.create(null)
  };
  Object.defineProperty(this, 'exports', {
    get: function get() {
      return module.exports;
    }
  });
  var EXPORTED_SYMBOLS = ['module'];
}

module.exports = {
  CLUSTER: 'cluster',
  CUSTOM: 'custom',
  DATE: 'date',
  FOLDER: 'folder',
  OWNER: 'owner',
  PRINTER: 'printer',
  STATUS: 'status',
  DEFAULT: ''
};