import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const getModalFooterClassName = (noPadding, type) => classNames(
	'flex justify-end text-right w-full',
	{
		'items-center py-2': type !== 'full' && !noPadding,
		'px-3 py-2': type !== 'full' && !noPadding,
		'border-t': type === 'full',
		'px-4 py-2': type === 'full' && !noPadding,
	}
);

const ModalFooter = ({ children, noPadding, type }) => (
	<div className={getModalFooterClassName(noPadding, type)}>
		{children}
	</div>
);

ModalFooter.propTypes = {
	children: PropTypes.node,
};

ModalFooter.defaultProps = {
	children: null,
};

export default ModalFooter;
