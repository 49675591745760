import { createSelector } from 'reselect';
import moment from 'moment';
import { i18nUtils } from 'dots-shared/src/i18n-utils';
// import locales from '../../../../server/public/locale/locale';

const i18nSelector = createSelector(
	[state => state.app.localeId, state => state.app.i18nStrings],
	(localeId, i18nStrings) => {
		const translationFunctions = new i18nUtils.TranslationFunctions(moment);
		translationFunctions.setLocaleAndStrings(localeId, i18nStrings);
		const { shortDateFormat, longDateFormat } = translationFunctions.setDateFormats() || {};


		function lookupId(id) {
			let translated = translationFunctions.translate(id);
			if (translated === id) {
				return '[#' + id + ']';
			}
			return translated;
		}

		function localize(text) {
			return (text + '').replace(/\[#([^\][ ]+)\]/g, function(_m, p1) {
				return lookupId(p1).replace(/'/g, '&#39;');
			});
		}

		return {
			convertLocaleToMoment: translationFunctions.convertLocaleToMoment,
			localeId,
			serverLocale: localeId, // Used as fallback option when dateFormat / timeFormat are not given
			pluralForm: translationFunctions.pluralForm,
			localize, //TODO: we need a translation that preserve placeholders for inputs. This function is used on old flux web. Should move to i18n-utils on future releases
			translate: translationFunctions.translate,
			translateString: translationFunctions.translateString,
			trimAndTranslateString: translationFunctions.trimAndTranslateString,
			timeFormat: null,
			shortDateFormat,
			longDateFormat,
			translateDate: translationFunctions.translateDate,
			translateShortDate: translationFunctions.translateShortDate,
			getStringRepresentationForFileSize: translationFunctions.getStringRepresentationForFileSize,
		};
	}
);

export default i18nSelector;
