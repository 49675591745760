export const hexToRgb = (hex) => {
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex.trim());
	return result ? {
		r: parseInt(result[1], 16),
		g: parseInt(result[2], 16),
		b: parseInt(result[3], 16),
	} : null;
};

export const setContrast = rgb => {
	if ((rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000 > 125) {
		return 'black';
	}
	return 'white';
};

export const getContrastColor = rgbColor => setContrast(hexToRgb(rgbColor));
