if (typeof require === 'undefined') {
  Components.utils.import('resource://requireloc/dotsRequireModule.js');
  var module = {
    exports: Object.create(null)
  };
  Object.defineProperty(this, 'exports', {
    get: function get() {
      return module.exports;
    }
  });
  var EXPORTED_SYMBOLS = ['module'];
}

module.exports = {
  INITIAL: 'INITIAL',
  APPROVAL: 'APPROVAL',
  PAYMENT: 'PAYMENT',
  CHECKORDER: 'CHECKORDER',
  PROOFPRINT: 'PROOFPRINT',
  PRODUCTION: 'PRODUCTION',
  FINISHING: 'FINISHING',
  BILLING: 'BILLING',
  DELIVERY: 'DELIVERY'
};