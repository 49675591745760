if (typeof require === 'undefined') {
  Components.utils.import('resource://requireloc/dotsRequireModule.js');
  var module = {
    exports: Object.create(null)
  };
  Object.defineProperty(this, 'exports', {
    get: function get() {
      return module.exports;
    }
  });
  var EXPORTED_SYMBOLS = ['module'];
}

module.exports = {
  BOTH_PRINTERS: 'BOTH_PRINTERS',
  FINISHING_PRINTER: 'FINISHING_PRINTER',
  FIRST_PRINTER: 'FIRST_PRINTER'
};