import React from 'react';
import PropTypes from 'prop-types';

import ModalCountContext from './modal-count-context';

export default class ModalCountProvider extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			modalCount: 0,
		};
		this.addModal = this.addModal.bind(this);
		this.removeModal = this.removeModal.bind(this);
	}

	addModal() {
		this.setState({
			modalCount: this.state.modalCount + 1
		});
	}

	removeModal() {
		this.setState({
			modalCount: this.state.modalCount - 1
		});
	}

	render() {
		const { children } = this.props;
		return (
			<ModalCountContext.Provider
				value={{ 
					modalCount: this.state.modalCount,
					addModal: this.addModal,
					removeModal: this.removeModal
				}}
			>
				{children}
			</ModalCountContext.Provider>
		);
	}
}

ModalCountProvider.propTypes = {
	children: PropTypes.node,
};

ModalCountProvider.defaultProps = {
	children: null,
};
