import React, { useRef, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icon } from '../icon';
import { COLORS, FONT_SIZES } from '../../constants';

const getButtonClassName = ({ type, iconColor, border, menuToggle, selected, buttonGroupStyle, color, size, className }) => classNames(
	{
		'dots-c-btn group': type !== 'icon',
		'dots-c-btn--danger': type === 'danger',
		'dots-c-btn--primary': type === 'primary' || type ==='submit',
		'dots-c-btn--toolbar': type === 'toolbar',
		'dots-c-btn--menuToggle': menuToggle,
		'dots-c-btn--isSelected': selected && type !== 'icon',
		'dots-c-icon': type === 'icon',
		'dots-c-icon dots-c-btn--toolbar rounded-none': type === 'toolbar_icon',
		'dots-c-icon--inverted': type === 'icon' && iconColor === 'white',
		'dots-c-icon--isSelected': selected && type === 'icon',
		[`bg-${color}`]: COLORS.includes(color) && type !== 'icon',
		[`border-${border || color}`]: COLORS.includes(border || color) && type !== 'icon',
		[`text-${size}`]: size,
	},
	buttonGroupStyle,
	className,
);

const getContainerClassName = ({ menuToggle, type }) => classNames(
	'h-full inline-flex items-center leading-normal min-w-0',
	{
		'justify-center': !menuToggle,
		'justify-between w-full': menuToggle,
		'flex-col text-s': type ==='toolbar',
	}
);

const getLabelClassName = ({ type }) => classNames(
	'truncate',
	{ 'leading-tight -mb-05 w-full': type === 'toolbar'}
);


const Button = ({
	a11yLabel,
	border,
	buttonGroupStyle,
	children,
	className,
	color,
	disabled,
	draggable,
	icon,
	iconColor,
	fillColor,
	iconSize,
	id,
	label,
	menuToggle,
	menuToggleOpen,
	onClick,
	onDragEnd,
	onDragStart,
	onMouseDown,
	onMouseEnter,
	onMouseLeave,
	onMouseUp,
	testid,
	selected,
	setRef,
	size,
	strokeColor,
	style,
	tabIndex,
	title,
	type,
}) => {
	const labelRef = useRef();
	const isClicked = useRef(false);
	const buttonClassName = getButtonClassName({ type, iconColor, menuToggle, selected, buttonGroupStyle, color, size, className, border });
	const containerClassName = getContainerClassName({ menuToggle, type });
	const labelClassName = getLabelClassName({ type });

	const [autoTitle, setAutoTitle] = useState(title);

	useEffect(() => setAutoTitle(title), [title]);

	const handleMouseEnter = useCallback(event => {
		if (labelRef.current.clientWidth < labelRef.current.scrollWidth // overflow
			&& typeof title !== 'string' // no title given
			&& typeof children === 'string'
		){
			setAutoTitle(children);
		} else if (autoTitle !== title){
			setAutoTitle(title)
		}
		onMouseEnter(event);
	}, [autoTitle, title, children, onMouseEnter]);

	const handleOnDoubleClick = e => {
		e.stopPropagation();
		e.preventDefault();
	};

	const handleOnClick = e => {
		if (!isClicked.current) {
			onClick(e);
			isClicked.current = true;
			setTimeout(() => {
				isClicked.current = false;
			}, 300);
		} else {
			e.preventDefault();
		}
	};

	return (
		<button
			aria-label={label || a11yLabel || id}
			className={buttonClassName}
			disabled={disabled}
			draggable={draggable}
			id={id}
			onClick={handleOnClick}
			onDoubleClick={handleOnDoubleClick}
			onDragEnd={onDragEnd}
			onDragStart={onDragStart}
			onMouseDown={onMouseDown}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={onMouseLeave}
			onMouseUp={onMouseUp}
			ref={setRef}
			data-testid={testid}
			style={style}
			tabIndex={tabIndex}
			title={autoTitle || a11yLabel}
			type={(type === 'submit' || type === 'reset' || type === 'icon') ? type : 'button'}
		>
			<span className={containerClassName}>
				{ icon &&
					<Icon
						name={icon}
						size={iconSize}
						color={iconColor}
						fill={fillColor}
						stroke={strokeColor}
						className="pointer-events-none"
					/>
				}
				<span className={labelClassName} ref={labelRef}>{children}</span>
				{menuToggle &&
					<Icon
						className="dropdownangle"
						name={!menuToggleOpen ? 'angle_down' : 'angle_up'}
						size={16}
					/>
				}
			</span>
		</button>
	);
};

Button.propTypes = {
	a11yLabel: PropTypes.string,
	buttonGroupStyle: PropTypes.string,
	children: PropTypes.node,
	className: PropTypes.string,
	color: PropTypes.oneOf(COLORS),
	disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	icon: PropTypes.string,
	iconColor: PropTypes.oneOf(COLORS),
	iconSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	id: PropTypes.string,
	label: PropTypes.string,
	menuToggle: PropTypes.bool,
	menuToggleOpen: PropTypes.bool,
	onClick: PropTypes.func,
	onMouseEnter: PropTypes.func,
	onMouseLeave: PropTypes.func,
	selected: PropTypes.bool,
	setRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
	size: PropTypes.oneOf(FONT_SIZES),
	tabIndex: PropTypes.number,
	title: PropTypes.string,
	type: PropTypes.oneOf(['', 'submit', 'danger', 'default', 'icon', 'primary', 'toolbar', 'dropdown', 'toolbar_icon', 'submit', 'reset', 'button']),
	border: PropTypes.oneOf(COLORS),
};

Button.defaultProps = {
	children: null,
	disabled: false,
	iconColor: 'gray-darkest',
	iconSize: 20,
	id: undefined,
	menuToggle: false,
	onClick: () => {},
	onMouseDown: () => {},
	onMouseEnter: () => {},
	onMouseLeave: () => {},
	selected: false,
	setRef: React.createRef(),
	tabIndex: undefined,
	title: null,
};

export default Button;