import React from 'react';
import cx from 'classnames';
import ButtonBack from './button-back';
import ButtonForward from './button-forward';
import ButtonClose from './button-close';

const divClasses = (type, headless) => cx(
	'flex items-center',
	{
		'flex flex-none items-stretch pt-1': type === 'full',
		'-mb-6 pt-1 z-50': headless,
	}
);

const shouldRenderButton = (action) => {
	return typeof action === 'function' || typeof action === 'boolean';
};

const NavigationButtonGroup = ({ configId, type, onCancel, goBack, goForward, headless }) => (
	<div className={divClasses(type, headless)}>
		{shouldRenderButton(goBack) && (
			<ButtonBack
				configId={configId}
				disabled={typeof goBack !== 'function'}
				onClick={typeof goBack === 'function' ? goBack : null}
			/>
		)}
		{shouldRenderButton(goForward) && (
			<ButtonForward
				configId={configId}
				disabled={typeof goForward !== 'function'}
				onClick={typeof goForward === 'function' ? goForward : null}
			/>
		)}
		{shouldRenderButton(onCancel) && (
			<ButtonClose
				configId={configId}
				onClick={typeof onCancel === 'function' ? onCancel : null}
				className={type === 'full' ? 'mt-05' : ''}
			/>
		)}
	</div>
);

export default NavigationButtonGroup;
