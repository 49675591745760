import { postFormData, postFormUrlEncoded } from 'dots-frontend/src/server-fetch';
import { RESOURCE_TYPE_TO_UPLOAD_PARAMS } from '../enums/resource-upload';
import guid from 'dots-guid';
import { getApiBaseName } from '../utils/url';

export const uploadResource = async(resource) => {
	const uid = guid();
	const body = new FormData();
	body.append('upl_uid', uid);

	const params = RESOURCE_TYPE_TO_UPLOAD_PARAMS[resource.type] || {};
	Object.keys(params).forEach(key => {
		body.append(key, params[key]);
	});

	body.append('pictureFile', resource.file, resource.file.name);
	await postFormData(`${getApiBaseName()}uploadProgress.web`, body);
	return await postFormUrlEncoded(`${getApiBaseName()}ajaxBroker.web`, `target=progress&upl_uid=${uid}&showUpload=1`);
};
