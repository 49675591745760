if (typeof require === 'undefined') {
  // eslint-disable-next-line no-undef
  Components.utils.import('resource://requireloc/dotsRequireModule.js');
  var module = {
    exports: Object.create(null)
  };
  Object.defineProperty(this, 'exports', {
    get: function get() {
      return module.exports;
    }
  });
  var EXPORTED_SYMBOLS = ['module'];
}

module.exports = {
  AddressChanged: 'addressChanged',
  DocumentCopiesChanged: 'documentCopiesChanged',
  DocumentInfoChanged: 'documentInfoChanged',
  FolderChanged: 'folderChanged',
  ItemCreated: 'itemCreated',
  ItemDuplicated: 'itemDuplicated',
  ItemMigrated: 'itemMigrated',
  ItemPrinted: 'itemPrinted',
  OrderInformationChanged: 'orderInformationChanged',
  OwnerChanged: 'ownerChanged',
  OwnerDeleted: 'ownerDeleted',
  PrintCancel: 'printCancel',
  PrintError: 'printError',
  PrinterChanged: 'printerChanged',
  PrinterSettingsChanged: 'printerSettingsChanged',
  // TODO: implement? This is never used, but should be.
  ProductionNoteChanged: 'productionNoteChanged',
  StatusChanged: 'statusChanged',
  TestPrinted: 'testPrinted',
  TitleChanged: 'titleChanged',
  WorkStepChanged: 'workStepChanged'
};