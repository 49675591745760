import DashboardContentAreas from './config-dashboard-page-content-type';

export const ConfigPortalTabs = {
	GENERAL: 'general',
	GROUPS: 'groups',
	THEME: 'theme',
	LOGIN: 'login',
	DASHBOARD: 'dashboard',
	LEGAL: 'legal',
};

export const PortalEditorGroups = {
	[ConfigPortalTabs.THEME]: {
		COLOR_CONFIG: 'colorConfig',
		FONT_CONFIG: 'fontConfig'
	},
	[ConfigPortalTabs.GENERAL]: {
		GENERAL_CONFIG: 'generalConfig',
		CONTACT_BAR_CONFIG: 'contactBar',
		COOKIE_CONFIG: 'cookieConfig',
	},
	[ConfigPortalTabs.GROUPS]: {
		GROUP_ASSIGNMENT_CONFIG: 'groupAssignmentConfig',
		SSO_CONFIG: 'SSOConfig',
		REGISTRATION_CONFIG: 'registrationConfig',
	},
	[ConfigPortalTabs.DASHBOARD]: DashboardContentAreas,
	[ConfigPortalTabs.LEGAL]: {
		TERMS_CONDITIONS_CONFIG: 'termsConditionsConfig',
		PRIVACY_NOTE_CONFIG: 'privacyNoteConfig',
		LEGAL_NOTE_CONFIG: 'legalNoteConfig',
		SOCIAL_MEDIA_CONFIG: 'socialMediaConfig',
		COPYRIGHT_CONFIG: 'copyrightConfig'
	}
};