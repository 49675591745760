if (typeof require === 'undefined') {
  Components.utils.import('resource://requireloc/dotsRequireModule.js');
  var module = {
    exports: Object.create(null)
  };
  Object.defineProperty(this, 'exports', {
    get: function get() {
      return module.exports;
    }
  });
  var EXPORTED_SYMBOLS = ['module'];
}

exports.ColorSeparation = {
  NON_BASE_COLOR: "non_basecolor",
  CURRENT_STATE_PDF: "current_state",
  SOURCE: "source" //only used for maps outside of the colorseparation to simplify them (see f.e. pushPageSources ff.)

};