if (typeof require === 'undefined') {
  Components.utils.import('resource://requireloc/dotsRequireModule.js');
  var module = {
    exports: Object.create(null)
  };
  Object.defineProperty(this, 'exports', {
    get: function get() {
      return module.exports;
    }
  });
  var EXPORTED_SYMBOLS = ['module'];
}

module.exports = {
  Bitmap: 'bitmap',
  Print: 'print',
  Storage: 'storage',
  Copy: 'copy',
  Stock: 'stock',
  Transaction: 'transaction',
  Roll: 'roll'
};