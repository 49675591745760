import React from 'react';

const MessageContext = React.createContext({
	createMessage: () => {},
	createDialog: () => {},
	createNotification: () => {},
	createLoader: () => {},
	deleteLoader: () => {},
});

export default MessageContext;
