import React from 'react';
import { useI18n } from 'dots-react-ui';
import { DetailedErrorTypes } from 'dots-enums';

// TODO: Remove this shit. Replace with something else.
const localizationKeys = {
	clusters: 'global.printerClusters',
	hotfolders: 'hotfolderTab.label',
	paperTypes: 'global.paperTypes',
	products: 'global.printProducts',
	signatures: 'global.signatures',
	users: 'ALL_USERS',
	virtualPrinters: 'global.virtualPrinters',
};

const getErrorMessage = (type, item, i18n) => {
	if (type === DetailedErrorTypes.IN_USE) {
		return i18n.translate('global.cannotDeleteItem.becauseInUse', [i18n.translate(item.name)]);
	} else if (type === DetailedErrorTypes.CONFLICT) {
		return i18n.translate('global.isConstrainedBy', [i18n.translate(item.name)]);
	}
	return i18n.translate(item.name || '');
};

const ErrorBody = ({ error }) => {
	const i18n = useI18n();
	const details = error.details || {};
	const { stringId, type, items, hideDefaultErrorMessage } = details;
	let message = i18n.translate(stringId || error.message || 'jobTicket.errorHandling.msg.undefined'); // error.message is probably not localizeable

	if (!Array.isArray(items) || !items.length || !type) {
		return (
			<label>{message}</label>
		);
	}

	return (
		<div>
			{stringId && <label>{i18n.translate(stringId)}</label>}
			{items.map((item, index) => (
				<div key={index}>
					{!hideDefaultErrorMessage && <label>{getErrorMessage(type, item, i18n)}</label>}
					{item.occurrence &&
						<ul className="list-reset ml-2">
							{Object.entries(item.occurrence).map(([key, occursIn]) => (
								<li key={key} className="my-05">
									<strong>{`${i18n.translate(localizationKeys[key])}${i18n.translate('global.charColon')} `}</strong>
									{occursIn.map(item => i18n.translate(item.name || item.userName)).join(`${i18n.translate('global.charComma')} `)}
								</li>
							))}
						</ul>}
				</div>
			))}
		</div>
	)
};

export default ErrorBody
