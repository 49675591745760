import { get } from 'dots-frontend/src/server-fetch';
import { getApiBaseName } from '../utils/url';

export const getInitialData = async(portal) => {
	if (portal) {
		return await get(`${getApiBaseName()}api/init?portal=${portal}`);
	}
	return await get(`${getApiBaseName()}api/init`);
};

