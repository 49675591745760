import { combineReducers } from 'redux';
import * as actionTypes from '../enums/action-types';

const defaultAllowedFileTypes =['.pdf', '.ps']

const allowedFileTypes = (state = defaultAllowedFileTypes, action) => {
	switch (action.type) {
	case actionTypes.SET_ALLOWED_FILE_TYPES:
		return action.allowedFileTypes.extensions || defaultAllowedFileTypes;
	default:
		return state;
	}
};

const defaultSystemSettings = (state = null, action) => {
	switch (action.type) {
	case actionTypes.SET_DEFAULT_SYSTEM_SETTINGS:
		return action.defaultSystemSettings;
	default:
		return state;
	}
};

const systemSettings = (state = null, action) => {
	switch (action.type) {
	case actionTypes.SET_SYSTEM_SETTINGS:
		return action.systemSettings;
	default:
		return state;
	}
};

export default combineReducers({
	allowedFileTypes,
	defaultSystemSettings,
	systemSettings,
});
