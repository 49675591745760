import { batch } from 'react-redux';
import * as actionTypes from '../enums/action-types';
import * as portalApis from '../apis/portal';
import * as apis from '../apis/api';
import { setUserSession } from './auth';
import { setAllowedFileTypes, setSystemSettings, setDefaultSystemSettings } from './settings';
import { setGroups } from './config';

export const setLicense = license => ({ type: actionTypes.SET_LICENSE, license });
export const setCSRFToken = CSRFToken => ({ type: actionTypes.SET_CSRF_TOKEN, CSRFToken });
export const setLocaleId = localeId => ({ type: actionTypes.SET_LOCALE_ID, localeId });
export const setI18nStrings = i18nStrings => ({ type: actionTypes.SET_I18N_STRINGS, i18nStrings });
export const setAppHasLoaded = () => ({ type: actionTypes.SET_APP_HAS_LOADED });
export const setPortal = portal => ({ type: actionTypes.SET_PORTAL, portal });

export const getCurrentPortal = () => async(dispatch) => {
	const currentPortal = await portalApis.getCurrentPortal();
	dispatch(setPortal(currentPortal));
};

export const getPortalByName = name => async(dispatch) => {
	const portal = await portalApis.getPortalByName(name);
	dispatch(setPortal(portal));
};

export const initApp = (portalName) => async(dispatch) => {
	const {
		allowedFileTypes,
		defaultSystemSettings,
		license,
		localeId,
		groups,
		portal,
		systemSettings,
		session
	} = await apis.getInitialData(portalName ? encodeURIComponent(portalName) : '');

	// init the localization
	const { default: i18nStrings } = await import(`../../../../data/react-locale/${localeId}.locale.json`);
	batch(() => {
		dispatch(setAppHasLoaded());
		dispatch(setAllowedFileTypes(allowedFileTypes));
		dispatch(setDefaultSystemSettings(defaultSystemSettings));
		dispatch(setGroups(groups));
		dispatch(setI18nStrings(i18nStrings));
		dispatch(setLicense(license));
		dispatch(setUserSession(session));
		dispatch(setLocaleId(localeId));
		dispatch(setPortal(portal));
		dispatch(setSystemSettings(systemSettings));
	});
};
