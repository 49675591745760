import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';

const getDefaultStyle = (duration) => {
	return {
		position: 'fixed',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		transition: `all ${duration}ms ease-in-out`,
		opacity: 0,
		zIndex: 999999,
	};
};

const getTransitionStyle = (index) => {
	const top = index * 8;
	return {
		entering: { opacity: 0, top: `${top + 37}%` },
		entered: { opacity: 1, top: `${top + 40}%` },
		exiting: { opacity: 1, top: `${top + 40}%` },
		exited: { opacity: 0, top: `${top + 43}%` },
	};
};

const fadeDuration = 300;

const Message = ({ title, body, type, duration, index, onClear }) => {
	const [visible, setVisible] = useState(false);
	
	const color = type === 'success' ? 'success' : 'danger';

	const handleTransitionOnExit = () => {
		setTimeout(() => onClear(), fadeDuration);
	};

	const handleMessageOnClick = () => {
		setVisible(false);
	};

	useEffect(() => {
		setVisible(true);

		const invisiblizeMessage = setTimeout(() => {
			setVisible(false);
		}, duration);

		return () => clearTimeout(invisiblizeMessage);
	}, []);

	return (
		<Transition in={visible} timeout={fadeDuration} onExited={handleTransitionOnExit}>
			{state => (
				<div
					className={`bg-${color} rounded font-sans p-1 text-base text-white`}
					style={{
						...getDefaultStyle(fadeDuration),
						...getTransitionStyle(index)[state],
					}}
				>
					<div className="whitespace-pre-line" onClick={handleMessageOnClick}>
						{title && <p><strong>{title}:</strong></p>}
						{body}
					</div>
				</div>
			)}
		</Transition>
	);
};

Message.propTypes = {
	id: PropTypes.string.isRequired,
	title: PropTypes.string,
	body: PropTypes.string,
	type: PropTypes.string,
	duration: PropTypes.number,
	index: PropTypes.number,
	onClear: PropTypes.func,
};

Message.defaultProps = {
	title: '',
	body: '',
	type: 'success',
	duration: 2000,
	index: 0,
	onClear: () => {},
}

export default Message;
