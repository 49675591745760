import guid from 'dots-guid';
import { batch } from 'react-redux';
import { nextName } from 'dots-shared/src/next-name';
import { createErrorMessage, createSuccessMessage } from 'dots-frontend/src/actions/message';
import { setGroups, setProducts, setStockProducts } from './config';
import * as actionTypes from '../enums/action-types';
import * as portalApis from '../apis/portal';
import * as uploadApis from '../apis/upload';
import { setCSRFToken, setLicense } from './app';
import { setPortals } from './config';
import { bodyToServer, getContentAreaStateAndResources } from '../utils/portal-data-converter';
import i18nSelector from '../selectors/i18n';
import PortalActions from '../enums/portal-actions';

export const setIsChanged = (isChanged, callback) => async(dispatch) => {
	await dispatch({ type: actionTypes.SET_EDITING_PORTAL_IS_CHANGED, isChanged })
	if (callback && typeof callback === 'function') {
		callback();
	}
};

export const clearCommandList = () => ({ type: actionTypes.CLEAR_COMMAND_LIST });

export const setCommandList = commandList => ({ type: actionTypes.SET_COMMAND_LIST, commandList });

export const setCurrentCommand = currentCommand => ({ type: actionTypes.SET_CURRENT_COMMAND, currentCommand });

export const setPortalCountLimit = portalCountLimit => ({ type: actionTypes.SET_PORTAL_COUNT_LIMIT, portalCountLimit });

export const setEditingPortal = editingPortal => dispatch => {
	if (editingPortal && Object.keys(editingPortal).length && !editingPortal.id) {
		editingPortal.id = guid();
	}
	dispatch({ type: actionTypes.SET_EDITING_PORTAL, editingPortal });
};

export const setOriginalPortal = originalPortal => ({ type: actionTypes.SET_ORIGINAL_PORTAL, originalPortal })

export const getPortals = () => async(dispatch) => {
	const portals = await portalApis.getPortals();
	dispatch(setPortals(portals));
};

export const commitPortalChanges = (portal, commandList, currentCommand) => dispatch => {
	batch(() => {
		dispatch(setIsChanged(true));
		dispatch(setEditingPortal(portal));
		dispatch(setCommandList([...commandList.slice(0, currentCommand + 1), portal]));
		dispatch(setCurrentCommand(currentCommand + 1));
	});
};

export const undoPortalChanges = (commandList, currentCommand) => dispatch => {
	if (currentCommand <= 0)
		return;

	batch(() => {
		if (currentCommand - 1 === 0) {
			dispatch(setIsChanged(false));
		}
		dispatch(setCurrentCommand(currentCommand - 1));
		dispatch(setEditingPortal(commandList[currentCommand - 1]));
	});
};

export const redoPortalChanges = (commandList, currentCommand) => dispatch => {
	if (currentCommand >= commandList.length - 1) { return; }
	batch(() => {
		if (currentCommand + 1 > 0) {
			dispatch(setIsChanged(true));
		}
		dispatch(setCurrentCommand(currentCommand + 1));
		dispatch(setEditingPortal(commandList[currentCommand + 1]));
	});
};

const isPortalActive = (portals, portalCountLimit) => {
	const activeCount = portals.filter(portal => portal.active).length;
	return activeCount < portalCountLimit;
};

export const getClonePortalData = (portalName, cloneName) => async(dispatch) => {
	const {
		CSRFToken,
		license,
		portal = {},
		portalCountLimit,
		stockProducts,
		portals,
		products,
		groups
	} = await portalApis.getPortalEditorData(portalName);

	batch(() => {
		dispatch(setIsChanged(true));
		dispatch(setCSRFToken(CSRFToken));
		dispatch(setGroups(groups));
		dispatch(setEditingPortal({ ...portal, name: portalName, clone: cloneName, id: guid(), active: isPortalActive(portals, portalCountLimit) && portal.active }));
		dispatch(setLicense(license));
		dispatch(setOriginalPortal(portal));
		dispatch(setPortalCountLimit(portalCountLimit));
		dispatch(setPortals(portals));
		dispatch(setStockProducts(stockProducts));
		dispatch(setProducts(products));
		dispatch(setCommandList([{ ...portal, name: portalName, clone: cloneName, active: isPortalActive(portals, portalCountLimit) && portal.active }]));
	});
}

export const getPortalDefaultData = isCreatingNew => async(dispatch, getState) => {
	const i18n = i18nSelector(getState());
	const {
		CSRFToken,
		license,
		portal = {},
		portalCountLimit,
		stockProducts,
		portals,
		products,
		groups
	} = await portalApis.getPortalEditorData();
	const portalNames = portals.map(portal => i18n.translate(portal.name));
	const name = nextName(i18n.translate('configPortals.newPortal'), portalNames);
	const editingPortal = isCreatingNew ?
		{ ...portal, name, id: guid(), groupIds: [], active: isPortalActive(portals, portalCountLimit) && portal.active } :
		{ ...portal, name };
	batch(() => {
		dispatch(setIsChanged(isCreatingNew ? true :  false));
		dispatch(setCSRFToken(CSRFToken));
		dispatch(setGroups(groups));
		dispatch(setEditingPortal(editingPortal));
		dispatch(setLicense(license));
		dispatch(setOriginalPortal(editingPortal));
		dispatch(setPortalCountLimit(portalCountLimit));
		dispatch(setPortals(portals));
		dispatch(setStockProducts(stockProducts));
		dispatch(setProducts(products));
		dispatch(setCommandList([editingPortal]));
	});
}

export const getPortalEditorData = portalName => async(dispatch) => {
	try{
		const {
			CSRFToken,
			license,
			portal = {},
			portalCountLimit,
			stockProducts,
			portals,
			products,
			groups
		} = await portalApis.getPortalEditorData(portalName);
		batch(() => {
			dispatch(setIsChanged(false));
			dispatch(setCSRFToken(CSRFToken));
			dispatch(setGroups(groups));
			dispatch(setEditingPortal(portal));
			dispatch(setLicense(license));
			dispatch(setOriginalPortal(portal));
			dispatch(setPortalCountLimit(portalCountLimit));
			dispatch(setPortals(portals));
			dispatch(setStockProducts(stockProducts));
			dispatch(setProducts(products));
			dispatch(setCommandList([portal]));
		});
	} catch(err) {
		console.log(err);
	}
};

const translateObject = (obj, i18n) => {
	for (const key in obj) {
		if (Object.hasOwnProperty.call(obj, key)) {
			const element = obj[key];
			if (typeof element === 'string') {
				obj[key] = i18n.localize(obj[key]);
			} else if (typeof element === 'object') {
				translateObject(obj[key], i18n)
			}
		}
	}
	return obj;
};

export const submitPortal = (portalData, originalPortal, CSRFToken, action, callback) => async(dispatch, getState) => {
	const i18n = i18nSelector(getState());
	let renameParent = false;
	let portal = { ...translateObject(portalData, i18n) };
	if (action === PortalActions.EDIT
		&& originalPortal.name // not the default
		&& portal.name !== originalPortal.name
	) {
		// when renames a portal, need to assign the name into rename, and keep name original to backend identify the portal
		renameParent = true;
		portal = {
			...portalData,
			name: originalPortal.name,
			rename: portal.name
		};
	}

	if (action === PortalActions.CLONE) {
		await portalApis.clonePortalCss(portalData.id, originalPortal.id);
	}

	const resources = [];
	const loginPage = portal && portal.loginPage ? {
		...portal.loginPage,
		contentAreas: portal.loginPage.contentAreas.map(contentArea => {
			const { state: updatedState, resources: rs } = getContentAreaStateAndResources(contentArea);
			resources.push(...rs);
			return updatedState;
		})
	} : { contentAreas: [] };

	let logo = portal.logoImage;
	if (portal.logoFile) {
		logo = ':' + portal.logoFile.name;
		resources.push({ type: 'logo-image', file: portal.logoFile });
	}

	const body = bodyToServer({ ...portal, loginPage }, CSRFToken, logo, action);
	return await Promise.all(resources.map(async(resource) => await uploadApis.uploadResource(resource)))
		.then(async() => await portalApis.updatePortal(body))
		.then(async() => {
			let portalName = portal.name;
			if (portal.clone) {
				portalName = portal.clone;
				renameParent = true;
				delete portal.clone;
			} else if (portal.rename) {
				portalName = portal.rename;
				renameParent = true;
				delete portal.rename;
			} else if (action === PortalActions.CREATE) {
				renameParent = true;
			}
			// Load the new portal, without the properties clone/rename
			await dispatch(getPortalEditorData(portalName));
			await batch(() => {
				dispatch(setIsChanged(false));
				dispatch(createSuccessMessage(i18n.translate('global.changesSaved')));
			});

			if (action !== PortalActions.EDIT) {
				// Remove the search queries from URL to allow the portal-editor saving portal changes as editing
				const baseUrl = window.top.location.href.split('?')
				window.top.history.replaceState({}, document.title, `${baseUrl[0]}?portal=${encodeURIComponent(portalName)}`);
			}

			if (renameParent) {
				// changes the portal name on the iframe parent
				window.top.postMessage({ type: 'renameManagePortals', value: portalName },  '*');
			}

			if (callback && typeof callback === 'function') {
				// Timeout Callback is used to close the prompt after displaying the save message on the react app
				callback();
			}
			return { portal };
		}).catch(err => {
			dispatch(createErrorMessage(i18n.translate('actions.save.error')));
			console.error(err);
			return {};
		});
};

export const setSelectedTab = (tabId, groupId, contentId) => dispatch => {
	dispatch({
		type: actionTypes.SET_PORTAL_EDITOR_SELECTED_TAB,
		selectedTab: tabId,
		openGroup: groupId,
		openContent: contentId
	});
};

export const setOpenGroup = groupId => dispatch => {
	dispatch({ type: actionTypes.SET_PORTAL_EDITOR_OPEN_GROUP, openGroup: groupId });
};

export const setOpenContent = contentId => dispatch => {
	dispatch({ type: actionTypes.SET_PORTAL_EDITOR_OPEN_CONTENT, openContent: contentId });
};
