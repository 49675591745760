if (typeof require === 'undefined') {
  Components.utils.import('resource://requireloc/dotsRequireModule.js');
  var module = {
    exports: Object.create(null)
  };
  Object.defineProperty(this, 'exports', {
    get: function get() {
      return module.exports;
    }
  });
  var EXPORTED_SYMBOLS = ['module'];
}

module.exports = {
  DEFAULT: 'DEFAULT',
  BIG_PREVIEW: 'BIG_PREVIEW',
  IMPORT: 'IMPORT',
  JOB_EDITOR: 'JOB_EDITOR',
  CONFIGURATION: 'CONFIGURATION'
};