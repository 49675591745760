if (typeof require === 'undefined') {
  Components.utils.import('resource://requireloc/dotsRequireModule.js');
  var module = {
    exports: Object.create(null)
  };
  Object.defineProperty(this, 'exports', {
    get: function get() {
      return module.exports;
    }
  });
  var EXPORTED_SYMBOLS = ['module'];
}

"use strict";

exports.DeliveryDateErrorType = {
  INVALID_DAY: 'INVALID_DAY',
  INVALID_DATE: 'INVALID_DATE'
};
exports.DocumentErrorType = {
  UPLOAD_ERROR: 'UPLOAD_ERROR',
  UNSUPPORTED_FORMAT: 'UNSUPPORTED_FORMAT',
  CONVERT_TO_PDF_ERROR: 'CONVERT_TO_PDF_ERROR',
  READ_PDF_ERROR: 'READ_PDF_ERROR',
  PRINT_NOT_ALLOWED: 'PRINT_NOT_ALLOWED'
};
exports.InsertType = {
  INSERT_SLIP_SHEETS: 'insertSlipSheets',
  INSERT_TAB_SHEETS: 'insertTabSheets',
  INSERT_EMPTY_PAGES: 'insertEmptyPages',
  INSERT_DOCUMENTS: 'insertDocuments'
};
exports.ConfirmationTypes = {
  SHEET_CONFLICTS: 'SHEET_CONFLICTS',
  SIGNATURE_CONFLICTS: 'SIGNATURE_CONFLICTS',
  IMPOSE_MODE_CONFLICTS: 'IMPOSE_MODE_CONFLICTS',
  GENERAL_SETTINGS_CONFLICTS: 'GENERAL_SETTINGS_CONFLICTS',
  PRODUCT_CONFLICTS: 'PRODUCT_CONFLICTS',
  SAVE_BEFORE_PRINT: 'SAVE_BEFORE_PRINT',
  SAVE_BEFORE_DOWNLOADING_FILES: 'SAVE_BEFORE_DOWNLOADING_FILES',
  SAVE_BEFORE_ACTION: 'SAVE_BEFORE_ACTION',
  MERGE_CONFLICTS: 'MERGE_CONFLICTS',
  DEFAULT_PREFLIGHT_CONFLICTS: 'DEFAULT_PREFLIGHT_CONFLICTS',
  CONFIRM_PRINT: 'CONFIRM_PRINT',
  CONFIRM_PRINT_MAX_COPIES_EXCEEDED: 'CONFIRM_PRINT_MAX_COPIES_EXCEEDED'
};
exports.EMPTY_PAGE_SOURCE_ID = 'EMPTY';
exports.TEMPLATE_SLOT_PAGE_SOURCE_ID = 'TEMPLATE_SLOT';