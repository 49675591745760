import add from './system/add.svg';
import angle_down from './system/angle_down.svg';
import angle_left from './system/angle_left.svg';
import angle_right from './system/angle_right.svg';
import angle_up from './system/angle_up.svg';
import angle_upDown from './system/angle_up_down.svg';
import archive from './system/archive.svg';
import attachment from './system/attachment.svg';
import back from './system/back.svg';
import ban from './system/ban.svg';
import barcode from './system/barcode.svg';
import bleedtab from './system/bleedtab.svg';
import bullet from './system/bullet.svg';
import calendar from './system/calendar.svg';
import category from './system/category.svg';
import chapter from './system/chapter.svg';
import chapters from './system/chapters.svg';
import checkbox_mixed from './system/checkbox_mixed.svg';
import checkbox_off from './system/checkbox_off.svg';
import checkbox_on from './system/checkbox_on.svg';
import clock from './system/clock.svg';
import clock_alarm from './system/alarmclock.svg';
import close from './system/close.svg';
import cluster from './system/printercluster.svg';
import cluster_large from './../placeholder/printercluster.svg';
import color_picker from './system/colorpicker.svg';
import colormode_bw from './colormode_bw.svg';
import colormode_cmyk from './colormode_cmyk.svg';
import colormode_color from './colormode_color.svg';
import colormode_rgb from './colormode_rgb.svg';
import colorsLayers from './system/colorsLayers.svg';
import column_add from './system/column_add.svg';
import column_alignTo from './system/column_alignTo.svg';
import column_pin from './system/column_pin.svg';
import convertToBarcode from './system/convertToBarcode.svg';
import convertToPage from './system/convertToPage.svg';
import convertToTab from './system/convertToTab.svg';
import convertToTextfield from './system/convertToTextfield.svg';
import copy from './system/copy.svg';
import crop from './system/crop.svg';
import cropMoveRotate from './system/cropMoveRotate.svg';
import csv from './system/csv.svg';
import csv_add from './system/csv_add.svg';
import cutline from './system/cutline.svg';
import dashboard_job from './dashboard/job.svg';
import dashboard_newJobs from './dashboard/newJobs.svg';
import displayOrder from './system/displayOrder.svg';
import download from './system/download.svg';
import drag from './system/drag.svg';
import duplicate from './system/duplicate.svg';
import edit from './system/edit.svg';
import edit_bitmap from './system/edit_bitmap.svg';
import edit_job from './system/edit_job.svg';
import ellipses from './system/ellipses.svg';
import email from './system/email.svg';
import email_automatic from './system/email_automatic.svg';
import emptypage from './system/emptypage.svg';
import error from './system/error.svg';
import exporticon from './system/export.svg';
import fail from './printstatus/PrintStatus_failed.svg';
import file_bitmap from './system/file_bitmap.svg';
import filter from './system/filter.svg';
import filterfolder from './system/filterfolder.svg';
import fixup from './system/fixup_solid.svg'; // import fixup_solid from './system/fixup_solid.svg';

import file_generic from './system/file_generic.svg';
import file_pdf from './system/file_pdf.svg';
import file_svg from './system/file_svg.svg';
import file_text from './system/file_text.svg';
import finishing from './system/finishing.svg';
import finishing_effects from './system/sparkle.svg';
import flux from './system/flux.svg';
import folder from './system/folder.svg';
import forward from './system/forward.svg';
import groups from './system/groups.svg';
import hand from './system/hand.svg';
import help from './system/help.svg';
import hidden from './system/hidden.svg';
import hotfolder from './system/hotfolder.svg';
import image from './system/image.svg';
import importicon from './system/import.svg';
import inbox from './system/inbox.svg';
import info from './system/info.svg';
import item_blankpage from './jobeditor/item_blankpage.svg';
import item_bleedtab from './jobeditor/item_bleedtab.svg';
import item_chapter from './jobeditor/item_chapter.svg';
import item_chapters from './jobeditor/item_chapters.svg';
import item_emptypage from './jobeditor/item_emptypage.svg';
import item_job from './jobeditor/item_job.svg';
import item_page from './jobeditor/item_page.svg';
import item_pdf from './jobeditor/item_pdf.svg';
import item_slipsheet from './jobeditor/item_slipsheet.svg';
import item_tabsheet from './jobeditor/item_tabsheet.svg';
import job from './system/job.svg';
import jobhistory from './system/jobhistory.svg';
import jobinfo from './system/jobInfo.svg';
import jobticket from './system/jobticket.svg';
import lang from './system/language.svg';
import layout from './system/layout.svg';
import leave from './system/signout.svg';
import link from './system/link.svg';
import loading from './loading.svg';
import lock_closed from './system/lock_closed.svg';
import lock_open from './system/lock_open.svg';
import magic from './system/magic.svg';
import menu from './system/menu.svg';
import menu_edit from './toolbar/menu_edit.svg';
import menu_select from './toolbar/menu_select.svg';
import minus from './system/remove.svg';
import move from './system/move.svg';
import move_down from './system/move_down.svg';
import move_horizontal from './system/moveHorizontal.svg';
import move_left from './system/move_left.svg';
import move_right from './system/move_right.svg';
import move_up from './system/move_up.svg';
import move_vertical from './system/moveVertical.svg';
import no_background from './system/no_background.svg';
import note from './system/note.svg';
import notifications from './system/notifications.svg';
import offsetHorizontal from './system/offsetHorizontal.svg';
import offsetVertical from './system/offsetVertical.svg';
import ok from './system/ok.svg';
import ordertype from './ordertype/ordertype.svg';
import ordertype_bitmap from './ordertype/ordertype_bitmap.svg';
import ordertype_copy from './ordertype/ordertype_copy.svg';
import ordertype_print from './ordertype/ordertype_print.svg';
import ordertype_print_missingFile from './ordertype/ordertype_print_missingFile.svg';
import ordertype_roll from './ordertype/ordertype_roll.svg';
import ordertype_stock from './ordertype/ordertype_stock.svg';
import ordertype_storage from './ordertype/ordertype_storage.svg';
import ordertype_transaction from './ordertype/ordertype_transaction.svg';
import overflow from './system/overflow.svg';
import page from './system/page.svg';
import page_black_and_white from './system/page_black_and_white.svg';
import page_color from './system/page_color.svg';
import page_vacant from './system/page_vacant.svg';
import pages from './system/pages.svg';
import paperCatalog_paper from './papertype/paperType.svg';
import paperCatalog_slipsheet from './papertype/paperType_slipsheet.svg';
import paperCatalog_tabsheet from './papertype/paperType_tabsheet.svg';
import pause from './system/pause.svg';
import pause_solid from './system/pause_solid.svg';
import pin from './system/pin.svg';
import pdf from './system/file_pdf.svg';
import placeholder_field from './system/placeholder_field.svg';
import placeholder_text from './system/placeholder_text.svg';
import play from './system/play.svg';
import play_solid from './system/play_solid.svg';
import preferences from './system/preferences.svg';
import preflight from './system/preflight.svg';
import preflight_approved from './system/preflight_approved.svg';
import print from './system/print.svg';
import printer from './system/print.svg';
import printer_bw from './system/printer_bw.svg';
import printer_color from './system/printer_color.svg';
import range from './system/range.svg';
import redo from './system/redo.svg';
import registermark from './system/registermark.svg';
import registermark_angle from './registermark/registermark_angle.svg';
import registermark_circle from './registermark/registermark_circle.svg';
import registermark_cross from './registermark/registermark_cross.svg';
import registermark_rectangle from './registermark/registermark_rectangle.svg';
import refresh from './system/refresh.svg';
import remove from './system/trash.svg';
import required from './system/required.svg';
import reset from './system/reset.svg';
import rollJob from './system/rolljob.svg';
import rotate from './system/rotate.svg';
import row_add from './system/row_add.svg';
import save from './system/save.svg';
import search from './system/search.svg';
import selectedPages from './system/selectedPages.svg';
import settings from './system/settings.svg';
import signout from './system/signout.svg';
import slipsheet from './system/slipsheet.svg';
import sparkle from './system/sparkle.svg';
import star from './system/star.svg';
import starFilled from './system/star_filled.svg';
import status_done from './jobstatus/done.svg';
import status_error from './jobstatus/error.svg';
import status_external from './jobstatus/external.svg';
import status_notrequired from './jobstatus/notrequired.svg';
import status_progress from './jobstatus/progress.svg';
import success from './system/success.svg';
import tableHeaderSort from './tableheader_sort--ascending.svg';
import tableHeaderSort_asc from './tableheader_sort--ascending.svg';
import tableHeaderSort_desc from './tableheader_sort--descending.svg';
import tabsBleedTabs from './system/tabsBleedTabs.svg';
import tabs from './system/tabs.svg';
import tabsheet from './system/tabsheet.svg';
import template from './system/template.svg';
import text from './system/text.svg';
import textfield from './system/textfield.svg';
import textfields from './system/textfields.svg';
import tick from './system/tick.svg';
import undo from './system/undo.svg';
import upload from './system/upload.svg';
import user from './system/user.svg';
import view from './system/show.svg';
import warning from './system/warning.svg';
import warning_circle from './system/warning_circle.svg';
import web from './system/web.svg';
import workflow from './system/workflow.svg';
import nav_about from './navigation/about.svg';
import nav_addon from './navigation/addon.svg';
import nav_administration from './navigation/administration.svg';
import nav_appearance from './navigation/appearance.svg';
import nav_dashboard from './navigation/dashboard.svg';
import nav_download from './navigation/download.svg';
import nav_help from './navigation/help.svg';
import nav_hotfolder from './navigation/hotfolder.svg';
import nav_import from './navigation/import.svg';
import nav_job from './navigation/job.svg';
import nav_joblist from './navigation/joblist.svg';
import nav_media from './navigation/media.svg';
import nav_overview from './navigation/configuration_overview.svg';
import nav_paperCatalog from './navigation/paperCatalog.svg';
import nav_paperFormat from './navigation/paperFormat.svg';
import nav_printer from './navigation/printer.svg';
import nav_printqueue from './navigation/printqueue.svg';
import nav_products from './navigation/products.svg';
import nav_quickimport from './navigation/quick_import.svg';
import nav_report from './navigation/reports.svg';
import nav_signature from './navigation/signature.svg';
import nav_userAccount from './navigation/userAccount.svg';
import nav_web from './navigation/web.svg';
import nav_workflow from './navigation/workflow.svg';
import cutmarktype_roman from './imposition/cutmarktype_roman.svg';
import cutmarktype_japanese from './imposition/cutmarktype_japanese.svg';
import pageFlow_1 from './imposition/pageFlow_1.svg';
import pageFlow_2 from './imposition/pageFlow_2.svg';
import pageFlow_3 from './imposition/pageFlow_3.svg';
import pageFlow_4 from './imposition/pageFlow_4.svg';
import pageFlow_5 from './imposition/pageFlow_5.svg';
import pageFlow_6 from './imposition/pageFlow_6.svg';
import pageFlow_7 from './imposition/pageFlow_7.svg';
import pageFlow_8 from './imposition/pageFlow_8.svg';
import navigate_first from './preview/navigate_first.svg';
import navigate_last from './preview/navigate_last.svg';
import navigate_next from './preview/navigate_next.svg';
import navigate_nextItem from './preview/navigate_nextItem.svg';
import navigate_previous from './preview/navigate_previous.svg';
import navigate_previousItem from './preview/navigate_previousItem.svg';
import preview from './preview/preview.svg';
import view_list from './preview/view_list.svg';
import view_maximize from './preview/view_maximize.svg';
import view_minimize from './preview/view_minimize.svg';
import view_pages from './preview/view_pages.svg';
import view_sheets from './preview/view_sheets.svg';
import view_thumbnails from './preview/view_thumbnails.svg';
import view_wysiwyg from './preview/view_wysiwyg.svg';
import zoom_height from './preview/zoom_height.svg';
import zoom_in from './preview/zoom_in.svg';
import zoom_out from './preview/zoom_out.svg';
import zoom_page from './preview/zoom_page.svg';
import zoom_width from './preview/zoom_width.svg';
import tab_barcodes from './editor/tab_barcodes.svg';
import tab_barcodes_danger from './editor/tab_barcodes_danger.svg';
import tab_barcodes_warning from './editor/tab_barcodes_warning.svg';
import tab_colorsLayers from './editor/tab_colorsLayers.svg';
import tab_colorsLayers_danger from './editor/tab_colorsLayers_danger.svg';
import tab_colorsLayers_warning from './editor/tab_colorsLayers_warning.svg';
import tab_cropMoveRotate from './editor/tab_cropMoveRotate.svg';
import tab_cutmarksBleed from './editor/tab_cutmarksBleed.svg';
import tab_cutmarksBleed_danger from './editor/tab_cutmarksBleed_danger.svg';
import tab_cutmarksBleed_warning from './editor/tab_cutmarksBleed_warning.svg';
import tab_dashboard from './editor/tab_dashboard.svg';
import tab_delivery from './editor/tab_delivery.svg';
import tab_delivery_danger from './editor/tab_delivery_danger.svg';
import tab_delivery_warning from './editor/tab_delivery_warning.svg';
import tab_eyemarks from './editor/tab_eyemarks.svg';
import tab_finishing from './editor/tab_finishing.svg';
import tab_finishing_danger from './editor/tab_finishing_danger.svg';
import tab_finishing_warning from './editor/tab_finishing_warning.svg';
import tab_general from './editor/tab_general.svg';
import tab_footer from './editor/tab_footer.svg';
import tab_groups from './editor/tab_groups.svg';
import tab_jobInfo from './editor/tab_jobInfo.svg';
import tab_jobInfo_danger from './editor/tab_jobInfo_danger.svg';
import tab_jobInfo_warning from './editor/tab_jobInfo_warning.svg';
import tab_layout from './editor/tab_layout.svg';
import tab_layout_danger from './editor/tab_layout_danger.svg';
import tab_layout_warning from './editor/tab_layout_warning.svg';
import tab_loginPage from './editor/tab_loginPage.svg';
import tab_objects from './editor/tab_objects.svg';
import tab_objects_danger from './editor/tab_objects_danger.svg';
import tab_objects_warning from './editor/tab_objects_warning.svg';
import tab_preflight from './editor/tab_preflight.svg';
import tab_preflight_danger from './editor/tab_preflight_danger.svg';
import tab_preflight_warning from './editor/tab_preflight_warning.svg';
import tab_registermarks from './editor/tab_registermarks.svg';
import tab_registermarks_danger from './editor/tab_registermarks_danger.svg';
import tab_registermarks_warning from './editor/tab_registermarks_warning.svg';
import tab_sheetInfo from './editor/tab_sheetInfo.svg';
import tab_tabs from './editor/tab_tabs.svg';
import tab_tabs_danger from './editor/tab_tabs_danger.svg';
import tab_tabs_warning from './editor/tab_tabs_warning.svg';
import tab_tabsBleedTabs from './editor/tab_tabsBleedTabs.svg';
import tab_tabsBleedTabs_danger from './editor/tab_tabsBleedTabs_danger.svg';
import tab_tabsBleedTabs_warning from './editor/tab_tabsBleedTabs_warning.svg';
import tab_textFields from './editor/tab_textfields.svg';
import tab_textfields_danger from './editor/tab_textfields_danger.svg';
import tab_textFields_warning from './editor/tab_textfields_warning.svg';
import tab_theme from './editor/tab_theme.svg';
import printer_busy from './printerstatus/busy.svg';
import printer_error from './printerstatus/error.svg';
import printer_idle from './printerstatus/idle.svg';
import printer_notAvailable from './printerstatus/notAvailable.svg';
import printer_offline from './printerstatus/offline.svg';
import printer_printing from './printerstatus/printing.svg';
import printer_warning from './printerstatus/warning.svg';
import printStatus_cancelled from './printstatus/PrintStatus_cancelled.svg';
import printStatus_corruptedJob from './printstatus/PrintStatus_corruptedJob.svg';
import printStatus_error from './printstatus/PrintStatus_error.svg';
import printStatus_failed from './printstatus/PrintStatus_failed.svg';
import printStatus_hasPrinterProblems from './printstatus/PrintStatus_hasPrinterProblems.svg';
import printStatus_offline from './printstatus/PrintStatus_offline.svg';
import printStatus_paused from './printstatus/PrintStatus_paused.svg';
import printStatus_printerPaused from './printstatus/PrintStatus_PrinterPaused.svg';
import printStatus_pending from './printstatus/PrintStatus_pending.svg';
import printStatus_preparing from './printstatus/PrintStatus_preparing.svg';
import printStatus_printed from './printstatus/PrintStatus_printed.svg';
import printStatus_printing from './printstatus/PrintStatus_printing.svg';
import printStatus_testprint from './printstatus/PrintStatus_testprint.svg';
import printStatus_unknown from './printstatus/PrintStatus_unknown.svg';
import printStatus_waiting from './printstatus/PrintStatus_waiting.svg';
import printStatus_warning from './printstatus/PrintStatus_warning.svg';
import chart_area_stacked from './charts/area_stacked.svg';
import chart_area from './charts/area.svg';
import chart_column_stacked from './charts/column_stacked.svg';
import chart_column from './charts/column.svg';
import chart_line from './charts/line.svg';
import chart_pie from './charts/pie.svg';
import text_alternateLabel from './textformatting/alternateTextLabel.svg';
import text_link from './textformatting/link.svg';
import text_list from './textformatting/list.svg';
import text_listNumbered from './textformatting/listNumbered.svg';
import text_bold from './textformatting/text_bold.svg';
import text_direction_horizontal from './textformatting/text_direction_horizontal.svg';
import text_direction_normal from './textformatting/text_direction_normal.svg';
import text_direction_rotated from './textformatting/text_direction_rotated.svg';
import text_direction_vertical from './textformatting/text_direction_vertical.svg';
import text_italic from './textformatting/text_italic.svg';
import text_underline from './textformatting/text_underline.svg';
import textFlow_horizontal_centered from './textformatting/textFlow_horizontal_centered.svg';
import textFlow_horizontal_left from './textformatting/textFlow_horizontal_left.svg';
import textFlow_horizontal_right from './textformatting/textFlow_horizontal_right.svg';
import textFlow_vertical_bottom from './textformatting/textFlow_vertical_bottom.svg';
import textFlow_vertical_centered from './textformatting/textFlow_vertical_centered.svg';
import textFlow_vertical_top from './textformatting/textFlow_vertical_top.svg';
import toolbar_assign_to_me from './toolbar/assign_to_me.svg';
import toolbar_back from './toolbar/back.svg';
import toolbar_edit_document from './toolbar/edit_document.svg';
import toolbar_edit_images from './toolbar/edit_images.svg';
import toolbar_escape from './toolbar/escape.svg';
import toolbar_export from './toolbar/export.svg';
import toolbar_exportJobs from './toolbar/exportJobs.svg';
import toolbar_forward from './toolbar/forward.svg';
import toolbar_import from './toolbar/import.svg';
import toolbar_importJobs from './toolbar/importJobs.svg';
import toolbar_importLabelJob from './toolbar/importLabelImposeJob.svg';
import toolbar_insert_emptypage from './toolbar/insert_emptypage.svg';
import toolbar_insert_item from './toolbar/insert.svg';
import toolbar_insert_pdf from './toolbar/insert_pdf.svg';
import toolbar_insert_slipsheet from './toolbar/insert_slipsheet.svg';
import toolbar_insert_tabsheet from './toolbar/insert_tabsheet.svg';
import toolbar_jobhistory from './toolbar/jobhistory.svg';
import toolbar_jobticket from './toolbar/jobticket.svg';
import toolbar_new_job from './toolbar/new_job.svg';
import toolbar_new_rolljob from './toolbar/new_rolljob.svg';
import toolbar_preview from './toolbar/preview.svg';
import toolbar_preview_page from './toolbar/preview_page.svg';
import toolbar_preview_sheet from './toolbar/preview_sheet.svg';
import toolbar_print from './toolbar/print.svg';
import toolbar_printercluster from './toolbar/printercluster.svg';
import toolbar_quick_import from './toolbar/quick_import.svg';
import toolbar_redo from './toolbar/redo.svg';
import toolbar_save from './toolbar/save.svg';
import toolbar_scan from './toolbar/scan.svg';
import toolbar_search from './toolbar/search.svg';
import toolbar_testprint from './toolbar/testprint.svg';
import toolbar_undo from './toolbar/undo.svg';
import product_rollJob from '../data/products/pic_mini/rollJob.svg';
import product_transactionPrint from '../data/products/pic_mini/native_data_stream.svg';
export default {
  add,
  angle_down,
  angle_left,
  angle_right,
  angle_up,
  angle_upDown,
  archive,
  attachment,
  back,
  ban,
  barcode,
  bleedtab,
  bullet,
  calendar,
  category,
  chapter,
  chapters,
  chart_area,
  chart_area_stacked,
  chart_column,
  chart_column_stacked,
  chart_line,
  chart_pie,
  checkbox_mixed,
  checkbox_off,
  checkbox_on,
  clock,
  clock_alarm,
  close,
  cluster,
  cluster_large,
  color_picker,
  colormode_bw,
  colormode_cmyk,
  colormode_color,
  colormode_rgb,
  colorsLayers,
  column_add,
  column_alignTo,
  column_pin,
  convertToBarcode,
  convertToPage,
  convertToTab,
  convertToTextfield,
  copy,
  crop,
  cropMoveRotate,
  csv,
  csv_add,
  cutline,
  cutmarktype_japanese,
  cutmarktype_roman,
  dashboard_job,
  dashboard_newJobs,
  displayOrder,
  download,
  drag,
  duplicate,
  edit,
  edit_bitmap,
  edit_job,
  ellipses,
  email,
  email_automatic,
  emptypage,
  error,
  exporticon,
  fail,
  file_bitmap,
  file_generic,
  file_pdf,
  file_svg,
  file_text,
  filter,
  filterfolder,
  finishing,
  finishing_effects,
  fixup,
  flux,
  folder,
  forward,
  groups,
  hand,
  help,
  hidden,
  hotfolder,
  image,
  importicon,
  inbox,
  info,
  item_blankpage,
  item_bleedtab,
  item_chapter,
  item_chapters,
  item_emptypage,
  item_job,
  item_page,
  item_pdf,
  item_slipsheet,
  item_tabsheet,
  job,
  jobhistory,
  jobinfo,
  jobticket,
  lang,
  layout,
  leave,
  link,
  loading,
  lock_closed,
  lock_open,
  magic,
  menu,
  menu_edit,
  menu_select,
  minus,
  move,
  move_down,
  move_horizontal,
  move_left,
  move_right,
  move_up,
  move_vertical,
  nav_about,
  nav_addon,
  nav_administration,
  nav_appearance,
  nav_dashboard,
  nav_download,
  nav_help,
  nav_hotfolder,
  nav_import,
  nav_job,
  nav_joblist,
  nav_media,
  nav_overview,
  nav_paperCatalog,
  nav_paperFormat,
  nav_printer,
  nav_printqueue,
  nav_products,
  nav_quickimport,
  nav_report,
  nav_signature,
  nav_userAccount,
  nav_web,
  nav_workflow,
  navigate_first,
  navigate_last,
  navigate_next,
  navigate_nextItem,
  navigate_previous,
  navigate_previousItem,
  no_background,
  note,
  notifications,
  offsetHorizontal,
  offsetVertical,
  ok,
  ordertype,
  ordertype_bitmap,
  ordertype_copy,
  ordertype_print,
  ordertype_print_missingFile,
  ordertype_roll,
  ordertype_stock,
  ordertype_storage,
  ordertype_transaction,
  overflow,
  pageFlow_1,
  pageFlow_2,
  pageFlow_3,
  pageFlow_4,
  pageFlow_5,
  pageFlow_6,
  pageFlow_7,
  pageFlow_8,
  page,
  page_black_and_white,
  page_color,
  page_vacant,
  pages,
  paperCatalog_paper,
  paperCatalog_slipsheet,
  paperCatalog_tabsheet,
  pause,
  pause_solid,
  pin,
  pdf,
  placeholder_field,
  placeholder_text,
  play,
  play_solid,
  preferences,
  preflight,
  preflight_approved,
  preview,
  print,
  printer,
  printer_busy,
  printer_bw,
  printer_color,
  printer_error,
  printer_idle,
  printer_notAvailable,
  printer_offline,
  printer_printing,
  printer_warning,
  printStatus_cancelled,
  printStatus_corruptedJob,
  printStatus_error,
  printStatus_failed,
  printStatus_hasPrinterProblems,
  printStatus_offline,
  printStatus_paused,
  printStatus_pending,
  printStatus_preparing,
  printStatus_printed,
  printStatus_printing,
  printStatus_printerPaused,
  printStatus_testprint,
  printStatus_unknown,
  printStatus_waiting,
  printStatus_warning,
  product_rollJob,
  product_transactionPrint,
  range,
  redo,
  refresh,
  registermark,
  registermark_angle,
  registermark_circle,
  registermark_cross,
  registermark_rectangle,
  remove,
  reset,
  required,
  rollJob,
  rotate,
  row_add,
  save,
  search,
  selectedPages,
  settings,
  signout,
  slipsheet,
  sparkle,
  star,
  starFilled,
  status_done,
  status_error,
  status_external,
  status_notrequired,
  status_progress,
  success,
  tab_barcodes,
  tab_barcodes_danger,
  tab_barcodes_warning,
  tab_colorsLayers,
  tab_colorsLayers_danger,
  tab_colorsLayers_warning,
  tab_cutmarksBleed,
  tab_cutmarksBleed_danger,
  tab_cutmarksBleed_warning,
  tab_cropMoveRotate,
  tab_dashboard,
  tab_delivery,
  tab_delivery_danger,
  tab_delivery_warning,
  tab_eyemarks,
  tab_finishing,
  tab_finishing_danger,
  tab_finishing_warning,
  tab_footer,
  tab_general,
  tab_groups,
  tab_jobInfo,
  tab_jobInfo_danger,
  tab_jobInfo_warning,
  tab_layout,
  tab_layout_danger,
  tab_layout_warning,
  tab_loginPage,
  tab_objects,
  tab_objects_danger,
  tab_objects_warning,
  tab_preflight,
  tab_preflight_danger,
  tab_preflight_warning,
  tab_registermarks,
  tab_registermarks_danger,
  tab_registermarks_warning,
  tab_sheetInfo,
  tab_tabs,
  tab_tabs_danger,
  tab_tabs_warning,
  tab_tabsBleedTabs,
  tab_tabsBleedTabs_danger,
  tab_tabsBleedTabs_warning,
  tab_textFields,
  tab_textfields_danger,
  tab_textFields_warning,
  tab_theme,
  tableHeaderSort,
  tableHeaderSort_asc,
  tableHeaderSort_desc,
  tabsBleedTabs,
  tabs,
  tabsheet,
  template,
  text_link,
  text_list,
  text_listNumbered,
  text,
  text_alternateLabel,
  text_bold,
  text_direction_horizontal,
  text_direction_normal,
  text_direction_rotated,
  text_direction_vertical,
  text_italic,
  text_underline,
  textfield,
  textfields,
  textFlow_horizontal_centered,
  textFlow_horizontal_left,
  textFlow_horizontal_right,
  textFlow_vertical_bottom,
  textFlow_vertical_centered,
  textFlow_vertical_top,
  tick,
  toolbar_assign_to_me,
  toolbar_back,
  toolbar_edit_document,
  toolbar_edit_images,
  toolbar_escape,
  toolbar_export,
  toolbar_exportJobs,
  toolbar_forward,
  toolbar_import,
  toolbar_importJobs,
  toolbar_importLabelJob,
  toolbar_insert_emptypage,
  toolbar_insert_item,
  toolbar_insert_pdf,
  toolbar_insert_slipsheet,
  toolbar_insert_tabsheet,
  toolbar_jobhistory,
  toolbar_jobticket,
  toolbar_new_job,
  toolbar_new_rolljob,
  toolbar_preview,
  toolbar_preview_page,
  toolbar_preview_sheet,
  toolbar_print,
  toolbar_printercluster,
  toolbar_quick_import,
  toolbar_redo,
  toolbar_save,
  toolbar_scan,
  toolbar_search,
  toolbar_testprint,
  toolbar_undo,
  undo,
  upload,
  user,
  view,
  view_list,
  view_maximize,
  view_minimize,
  view_pages,
  view_sheets,
  view_thumbnails,
  view_wysiwyg,
  warning,
  warning_circle,
  web,
  workflow,
  zoom_height,
  zoom_in,
  zoom_out,
  zoom_page,
  zoom_width
};