import React from 'react';
import { Button } from '../../button';
import useI18n from '../../../hooks/use-i18n';
import keyboardKeys from '../../../utils/keyboard-keys';

const ButtonForward = ({ onClick, disabled, configId }) => {
	const i18n = useI18n();
	const handleOnKeyDown = e => {
		if (e.key === keyboardKeys.ENTER) {
			e.stopPropagation();
			onClick();
		}
	}
	return (
		<Button
			className="h-32 w-32"
			disabled={disabled}
			icon="toolbar_forward"
			iconSize={32}
			id={`${configId}-next`}
			label={i18n.translate('previewTooltipNext')}
			onClick={onClick}
			onKeyDown={handleOnKeyDown}
			type="icon"
		/>
	);
};

export default ButtonForward;
