import * as actionTypes from '../../constants/action-types';

const reducer = (state = [], action) => {
	switch(action.type) {
		case actionTypes.CREATE_LOADER:
			return state.concat(action.loader);
		case actionTypes.DELETE_LOADER:
			return state.filter(loader => loader.id !== action.id);
		case actionTypes.CLEAR_LOADERS:
		case actionTypes.CLEAR_DATA:
			return [];
		default:
			return state;
	}
};

export default reducer;