export const COLORS = [
	'black',
	'danger',
	'danger-muted',
	'edit',
	'gray',
	'gray-dark',
	'gray-darkest',
	'gray-light',
	'gray-lightest',
	'gray-medium',
	'inherit',
	'orange',
	'primary',
	'primary-80',
	'primary-light',
	'primary-lighter',
	'primary-lightest',
	'primary-subtle',
	'secondary',
	'success',
	'success-muted',
	'transparent',
	'warning',
	'warning-muted',
	'white',
];

export const FONT_SIZES = [
	'2xs',
	'xs',
	's',
	'base',
	'l',
	'xl',
	'2xl',
	'3xl',
	'4xl',
];
