import React from 'react';
import PropTypes from 'prop-types';

import Message from './message';
import { Portal } from '../portal';

const notificationRoot = typeof document !== 'undefined' ? document.getElementById('notification') : null;

const Messages = ({ messages, clearMessage }) => (
	<Portal container={notificationRoot}>
		{messages.map(({ id, title, body, type, duration }, index) => (
			<Message
				key={id}
				id={id}
				title={title}
				body={body}
				type={type}
				duration={duration}
				index={index}
				onClear={() => clearMessage(id)}
			/>
		))}
	</Portal>
);

Messages.propTypes = {
	messages: PropTypes.arrayOf(PropTypes.object),
	clearMessage: PropTypes.func,
};

Messages.defaultProps = {
	messages: [],
	clearMessage: () => {},
};

export default Messages;
