const autoFocus = (ref, tagName = 'input') => {
	if (!ref || !ref.getElementsByTagName(tagName).length) return;

	ref.getElementsByTagName(tagName)[0].focus();

	// Set focus at the end of the input (hacky way!!!!)
	const input = ref.getElementsByTagName(tagName)[0];

	if (input.type !== 'file') {
		const val = input.value;
		ref.getElementsByTagName(tagName)[0].value = '';
		ref.getElementsByTagName(tagName)[0].value = val;
	}
};

export default autoFocus;
