import React from 'react';
import { useSelector } from 'react-redux';
import { deleteMessage as deleteMessageAction } from 'dots-frontend/src/actions/message';
import { messagesSelector } from 'dots-frontend/src/selectors/app';
import useActions from 'dots-react-components/src/hooks/use-actions';
import useI18n from 'dots-react-ui/src/hooks/use-i18n';
import Messages from 'dots-react-ui/src/components/messages/messages';

const MessageContainer = () => {
	const messages = useSelector(messagesSelector);
	const deleteMessage = useActions(deleteMessageAction);
	const i18n = useI18n();

	const translatedMessages = messages.map(message => ({ ...message, title: i18n.translate(message.title), body: i18n.translate(message.body) }));

	return (
		<Messages messages={translatedMessages} clearMessage={id => deleteMessage(id)} />
	);
};

export default MessageContainer;
