import optionIcons from './options';
import commonIcons from './icons';
export { default as avatarPlaceholder } from './avatar_placeholder.png';
export { default as iconFlux } from './logos/icon-flux-32.png';
export { default as logoFluxPrinter } from './logos/FluxPrinter.png';
export { default as logoFlux } from './logos/AccurioPro_Flux.png';
export { default as logoFluxServer } from './logos/FluxServer.png';
export { default as logoKMinverted } from './logos/logo_KM-inverted.png';
export { default as printerPlaceholder } from './placeholder/Machine_GenericPS.svg';
export { default as userOnline } from './user-online.png';
export { default as LogoKonicaMinoltaVertical } from './logos/Konica_Minolta_vertical.svg';
export var icons = Object.assign({}, commonIcons, optionIcons);
export var iconSets = {
  common: commonIcons,
  option: optionIcons
};