if (typeof require === 'undefined') {
  Components.utils.import('resource://requireloc/dotsRequireModule.js');
  var module = {
    exports: Object.create(null)
  };
  Object.defineProperty(this, 'exports', {
    get: function get() {
      return module.exports;
    }
  });
  var EXPORTED_SYMBOLS = ['module'];
}

module.exports = {
  Submitted: 'orderItemSubmitted',
  NoteAdded: 'noteAdded',
  NoteRemoved: 'noteRemoved',
  NoteMessageUpdated: 'noteMessageUpdated',
  StatusUpdated: 'statusUpdated',
  DocumentInfoUpdated: 'documentInfoUpdated',
  FolderUpdated: 'folderIdUpdated',
  WorkStepStateUpdated: 'workStepStateUpdated',
  WorkStepNoteUpdated: 'workStepNoteUpdated',
  OwnerUpdated: 'ownerUpdated',
  OwnerRemoved: 'ownerRemoved'
};