if (typeof require === 'undefined') {
  Components.utils.import('resource://requireloc/dotsRequireModule.js');
  var module = {
    exports: Object.create(null)
  };
  Object.defineProperty(this, 'exports', {
    get: function get() {
      return module.exports;
    }
  });
  var EXPORTED_SYMBOLS = ['module'];
}

module.exports = {
  STEPANDREPEAT: "STEP_AND_REPEAT",
  BOOKLETI: "BOOKLET_I",
  BOOKLETII: "BOOKLET_II",
  STEPANDREPEATII: "STEP_AND_REPEAT_II",
  CUTANDMARRY: "CUT_AND_MARRY",
  CUTANDMARRYDUPLEX: "CUT_AND_MARRY_DUPLEX",
  GANGUP: "GANG_UP",
  CROSSFOLD: "CROSSFOLD_I",
  CROSSFOLDII: "CROSSFOLD_II"
};