import React from 'react';
import { useSelector } from 'react-redux';
import { getContrastColor } from 'dots-react-ui/src/utils/color';
import { portalSelector } from '../../../selectors/app';
import { editingPortalSelector } from '../../../selectors/portal-config';
import FontFamily from 'dots-shared/src/font-family';

const ThemeProvider = ({ children }) => {
	const displayPortal = useSelector(portalSelector);
	const editingPortal = useSelector(editingPortalSelector);

	let theme = displayPortal.theme;
	let backgroundColor = theme && theme.primaryColor;
	let color = backgroundColor && getContrastColor(backgroundColor);

	if (editingPortal) {
		theme = editingPortal.theme;
		if (theme && theme.primaryColor) {
			backgroundColor = theme.primaryColor;
			color = getContrastColor(backgroundColor)
		}
	}

	const themeStyles = {
		"--primary-color": theme.primaryColor ? theme.primaryColor : null,
		"--secondary-color": theme.secondaryColor ? theme.secondaryColor : null,
		"--contrast-font-color": color || '#fff',
		"--font-family": theme.fontFamily ? FontFamily[theme.fontFamily].fontStack : null,
		"--text-color": "rgba(0,30,60,1)",
	};

	return (
		<div id="theme-provider" style={themeStyles}>
			{children}
		</div>
	);
};

export default ThemeProvider;