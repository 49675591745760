import * as actionTypes from '../../constants/action-types';

const reducer = (state = [], action) => {
	switch(action.type) {
	case actionTypes.CREATE_MESSAGE:
		return state.concat(action.message);
	case actionTypes.DELETE_MESSAGE:
		return state.filter(message => message.id !== action.id);
	case actionTypes.CLEAR_MESSAGES:
	case actionTypes.CLEAR_DATA:
		return [];
	default:
		return state;
	}
};

export default reducer;