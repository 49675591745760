import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Breadcrumbs, Breadcrumb } from '../breadcrumb';
import { Subheading, Title } from '../text';
import { NavigationButtonGroup } from './navigation-buttons';
import { isCtrlKeyPressed } from '../../utils/events';
import keyboardKeys from '../../utils/keyboard-keys';

const divClasses = (height) => cx(
	'bg-white border-t-4 border-secondary fixed mx-auto top-0 inset-x-0 u-modal-page-width z-50',
	`h-${height}`, // TODO FIXME this breaks tailwind optimisation, always use full class strings so tailwind can detect they are being used!
);

const ModalHeader = ({
	children,
	configId,
	height = 96,
	goBack,
	goForward,
	headless,
	onCancel,
	title,
	toolbar,
	type,
}) => {

	useEffect(() => {
		const handleNavigationKeys = (e) => {
			if (document.activeElement.tagName === 'INPUT' || document.activeElement.tagName === 'TEXTAREA') return;
			// 37 -> left
			// 39 -> right
			// e.metaKey is for Mac. Needs to test
			if (isCtrlKeyPressed(e) && e.key === keyboardKeys.ARROW_RIGHT && goForward && typeof goForward === 'function') {
				goForward();
			} else if (isCtrlKeyPressed(e) && e.key === keyboardKeys.ARROW_LEFT && goBack && typeof goBack === 'function') {
				goBack();
			}
		};

		if (goBack || goForward) {
			document.addEventListener('keydown', handleNavigationKeys);
		}
		return () => {
			if (goBack || goForward) {
				document.removeEventListener('keydown', handleNavigationKeys);
			}
		};
	}, [goBack, goForward]);


	if (type === 'full') {
		return (
			<div data-test="modal-header-full" className={divClasses(height)}>
				<div className="border-b border-secondary h-full content-end flex justify-between pt-05 px-3">
					<div className="flex-grow mr-3 truncate">
						<Breadcrumbs id="modal-header-breadcrumb">
							<Breadcrumb>{title}</Breadcrumb>
						</Breadcrumbs>
						<Title id="modalTitle">{children}</Title>
					</div>
					<NavigationButtonGroup
						configId={configId}
						type={type}
						onCancel={onCancel}
						goBack={goBack}
						goForward={goForward}
					/>
				</div>
				{toolbar && (
					<div data-test="modal-header-toolbar" className="px-3 -mt-6">
						{toolbar}
					</div>
				)}
			</div>
		);
	}

	if (headless) {
		return (
			<div data-test="modal-header" className="flex h-0 items-center justify-end px-1">
				<NavigationButtonGroup
					configId={configId}
					type={type}
					onCancel={onCancel}
					goBack={goBack}
					goForward={goForward}
					headless={headless}
				/>
			</div>
		);
	}

	return (
		<div data-test="modal-header" className="bg-primary-lightest border-b border-white flex h-48 items-center justify-between px-3 py-1 rounded-t-lg">
			<Subheading id="modalTitle" className="mt-05 truncate" thin>{children}</Subheading>
			<NavigationButtonGroup
				configId={configId}
				type={type}
				onCancel={onCancel}
				goBack={goBack}
				goForward={goForward}
			/>
		</div>
	);
};

ModalHeader.propTypes = {
	children: PropTypes.node,
	headless: PropTypes.bool,
	title: PropTypes.string,
	type: PropTypes.string,
	toolbar: PropTypes.element,
};

ModalHeader.defaultProps = {
	children: null,
	goBack: null,
	goForward: null,
	onCancel: null,
	title: '',
	toolbar: null,
	type: 'dialog',
};

export default ModalHeader;
